import { LoaderFunctionArgs } from "react-router-dom";
import { networkCall } from "../../../../../util/network-call/network-call";

export const AdminLicenseAddLoader = async (args: LoaderFunctionArgs) => {

    const response = await networkCall('/modules');

    return {
        modulesResponse: response,
        companyId: args.params.id
    };

};