import { Card, Checkbox, DatePicker, Divider, Input } from 'antd';
import { useDispatch } from 'react-redux';
import { setOpenKeys, setPageTitle, setSelectedMenuItemKeys } from '../../../../../redux/reducer/antd-layout.reducer';
import { useEffect, useState } from 'react';
import { Form, Button } from 'antd';
import { networkCall } from '../../../../../util/network-call/network-call';
import { showDefaultErrorMessages } from '../../../../../util/show-default-error-message/show-default-error-messages';
import { AlertMessageState, clearAlertMessage, showAlertMessage } from '../../../../../redux/reducer/alert-message.reducer';
import { tranformAPIValidationErrorToAntDValidationMessage } from '../../../../../util/validation/transform-api-validation-errors-to-antd-validation-messsages';
import { Link, useLoaderData, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { ModulePart } from '../../../../../constant/type/module-part.type';
import { ModulePartProperty } from '../../../../../constant/type/module-part-property.type';
import './admin-license-edit.css';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { LicenseTypeEnum } from '../../../../../constant/enum/license-type.enum';
import { Device } from '../../../../../constant/type/device.type';
import { FieldData } from '../../../../../constant/type/field-data.type';

type DisabledModulePartProperties = {
    modulePartid: number;
    modulePartProperties: {
        id: number;
        disabled: boolean;
    }[];
}

export const AdminLicenseEdit = () => {

    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const loaderData: any = useLoaderData();
    const [moduleParts, setModuleParts] = useState<ModulePart[]>([]);
    const [modulePartProperties, setModulePartProperties] = useState<ModulePartProperty[]>([]);
    const [licenseModuleParts, setLicenseModuleParts] = useState([]);
    const [disabledModulePartProperties, setDisabledModulePartProperties] = useState<DisabledModulePartProperties[]>([]);
    const [alreadyFinalized, setAlreadyFinalized] = useState(loaderData.getLicenseResponse?.data?.license?.finalized === true ? true : false);
    const [finalized, setFinalized] = useState<boolean | null>(loaderData.getLicenseResponse?.data?.license?.finalized === true ? true : false);
    let sendAsFinalized = false;
    const [licenseDevices, setLicenseDevices] = useState<Device[]>([]);

    const clearFormErrors = () => {

        const fields: FieldData[] = [];
        fields.push({
            name: 'licenseStartDate',
            errors: []
        },
        {
            name: 'licenseEndDate',
            errors: []
        });

        for (const licenseDevice of licenseDevices) {
            fields.push({
                name: `devices.${licenseDevice.id}.deviceId`,
                errors: []
            });
        }

        form.setFields(fields);
    };

    const isModulePartAvailableInLicense = (modulePartId: number): boolean => {
        return licenseModuleParts.filter((item: any) => item?.modulePart?.id === modulePartId).length !== 0;
    };

    const createLicenseModulePartModulePartPropertyTDs = (modulePartId: number) => {

        // bennt van-e a licenszhez tartozó module partok között a paraméterben megadott ID-jú module part
        const licenseModulePart: any[] = licenseModuleParts.filter((licenseModulePart: any) => licenseModulePart?.modulePart?.id === modulePartId);

        // ha nincs benne, vagyis nem elérhető ebben a licenszben a modul part, akkor checked false-szal rendereljük az összes checkboxot
        if(licenseModulePart.length === 0) {

            return modulePartProperties.map(modulePartProperty => (
                <td key={`${modulePartId}.${modulePartProperty.id}`} className='text-center'>
                    <Form.Item
                        name={`licenseModulePartModulePartProperty.${modulePartId}.${modulePartProperty.id}`}
                        valuePropName="checked"
                        initialValue={false}
                    >
                        <Checkbox disabled={finalized === true || isModulePartPropertyCheckboxDisabled(modulePartId, modulePartProperty.id)} />
                    </Form.Item>
                </td>
            ));

        } else {

            return modulePartProperties.map(modulePartProperty => {
                // a licenszhez rendelt module parthoz rendelt module property-k között van e az adoot modulePartProperty
                const licenseModulePartModulePartProperty = licenseModulePart[0].licenseModulePartModulePartProperties.filter((licenseModulePartModulePartProperty: any) => licenseModulePartModulePartProperty.modulePartProperty.id === modulePartProperty.id);
                
                return (
                    <td key={`${modulePartId}.${modulePartProperty.id}`} className='text-center'>
                        <Form.Item
                            name={`licenseModulePartModulePartProperty.${modulePartId}.${modulePartProperty.id}`}
                            valuePropName="checked"
                            initialValue={licenseModulePartModulePartProperty?.length !== 0}
                        >
                            <Checkbox disabled={finalized === true || isModulePartPropertyCheckboxDisabled(modulePartId, modulePartProperty.id)} />
                        </Form.Item>
                    </td>
                );

            });
        }

    }

    const modulePartAvailabilityChange = (e: CheckboxChangeEvent, modulePartId: number) => {
        
        if(e.target.checked) {

            for(const modulePartProperty of modulePartProperties) {
                setDisabledModulePartPropertyCheckbox(modulePartId, modulePartProperty.id, false);
            }
            
        } else { // hamis esetén false-ra állítjuk az adott module part-hoz tartozó üsszes module part property chechkboxot

            for(const modulePartProperty of modulePartProperties) {
                form.setFieldValue(`licenseModulePartModulePartProperty.${modulePartId}.${modulePartProperty.id}`, false);
                
                setDisabledModulePartPropertyCheckbox(modulePartId, modulePartProperty.id, true);
            }

        }
    };

    const licenseStartDateChange = () => {
        const licenseStartDate = form.getFieldValue('licenseStartDate') as dayjs.Dayjs;
        if(licenseStartDate) form.setFieldValue('licenseEndDate', licenseStartDate.add(1, 'year'));
    };

    const isModulePartPropertyCheckboxDisabled = (modulePartId: number, modulePartPropertyId: number): boolean => {

        for (const disabledModulePartProperty of disabledModulePartProperties) {
            if(disabledModulePartProperty.modulePartid === modulePartId) {
                for (const modulePartProp of disabledModulePartProperty.modulePartProperties) {
                    if(modulePartProp.id === modulePartPropertyId) return modulePartProp.disabled;
                }
                break;
            }
        }

        return false;

    };

    const setDisabledModulePartPropertyCheckbox = (modulePartId: number, modulePartPropertyId: number, disabled = true) => {
        
        setDisabledModulePartProperties(_disabledModulePartProperties => {
            const _disabledModulePartProps: DisabledModulePartProperties[] = JSON.parse(JSON.stringify(_disabledModulePartProperties));
            for (const _disabledModulePartProp of _disabledModulePartProps) {
                if(_disabledModulePartProp.modulePartid === modulePartId) {
                    for (const modulePartProp of _disabledModulePartProp.modulePartProperties) {
                        if(modulePartProp.id === modulePartPropertyId) {
                            modulePartProp.disabled = disabled;
                            break;
                        }
                    }
                    break;
                }
            }

            return _disabledModulePartProps;
        });
    
    }

    const onFinish = async (values: any) => {

        let licenseStartDate: string | undefined = undefined;
        if(values.licenseStartDate) {

            const prevLicenseStartDate = loaderData.getLicenseResponse.data.license.licenseStartDate ? dayjs(new Date(loaderData.getLicenseResponse.data.license.licenseStartDate * 1000)) : null;
            if(prevLicenseStartDate) {

                if(!prevLicenseStartDate.isSame(values.licenseStartDate)) licenseStartDate = `${ values.licenseStartDate.get('year') }-${ values.licenseStartDate.get('month') + 1 }-${ values.licenseStartDate.get('date') }`;
                
            } else licenseStartDate = `${ values.licenseStartDate.get('year') }-${ values.licenseStartDate.get('month') + 1 }-${ values.licenseStartDate.get('date') }`;
        }

        let licenseEndDate: string | undefined = undefined;
        if(values.licenseEndDate) {

            const prevLicenseEndDate = loaderData.getLicenseResponse.data.license.licenseEndDate ? dayjs(new Date(loaderData.getLicenseResponse.data.license.licenseEndDate * 1000)) : null;
            if(prevLicenseEndDate) {
                
                if(!prevLicenseEndDate.isSame(values.licenseEndDate)) licenseEndDate = `${ values.licenseEndDate.get('year') }-${ values.licenseEndDate.get('month') + 1 }-${ values.licenseEndDate.get('date') }`;
                
            } else licenseEndDate = `${ values.licenseEndDate.get('year') }-${ values.licenseEndDate.get('month') + 1 }-${ values.licenseEndDate.get('date') }`;
        
        }

        const moduleParts: any[] = [];
        const devices: any[] = [];
        
        for (const key in values) {
            if(key.startsWith('modulePartAvailable.')) {
                const modulePartAvailable = form.getFieldValue(key);
                if(!modulePartAvailable) continue;

                const modulePartId = parseInt(key.split('.')[1], 10);
                const modulePartPropertyIds: number[] = [];

                for (const modulePartProperty of modulePartProperties) {
                    const checked = form.getFieldValue(`licenseModulePartModulePartProperty.${modulePartId}.${modulePartProperty.id}`);
                    if(checked) modulePartPropertyIds.push(modulePartProperty.id);
                }

                moduleParts.push({
                    id: modulePartId,
                    modulePartPropertyIds
                });
            }
        }

        for (const device of licenseDevices) {
            devices.push({
                id: device.id,
                deviceId: values[`devices.${device.id}.deviceId`]
            });
        }

        dispatch(clearAlertMessage());
        dispatch(showAlertMessage({
            alertMessageType: 'loading',
            alertMessageText: 'Kérés folyamatban...'
        }));

        const response = await networkCall(`/licenses/${ loaderData.id }`, {
            method: 'PUT',
            body: {
                licenseStartDate,
                licenseEndDate,
                examination: values.examination,
                moduleParts,
                finalized: sendAsFinalized,
                devices
            }
        });

        const messageShowed = showDefaultErrorMessages(response);

        if(messageShowed) return;

        dispatch(clearAlertMessage());
        const alertMessageState: AlertMessageState = {
            alertMessageType: 'error',
            alertMessageText: 'Ismeretlen hiba történt. Kérjük próbálja újra!'
        };

        if (response.status === 200 && response.data.license !== undefined) {

            alertMessageState.alertMessageText = 'Mentés sikeres.';
            alertMessageState.alertMessageType = 'success';
            dispatch(showAlertMessage(alertMessageState));
            if(response.data.license.finalized === true) {
                setAlreadyFinalized(true);
                setFinalized(true);
            }
            form.setFieldValue('licenseKey', response.data.license.licenseKey);
            return;

        }else if(response.status === 400){

            switch (response.data.code) {

                case 2000:
                    alertMessageState.alertMessageText = 'Hibásan megadott beviteli mezők.';
                    const validationErrors = tranformAPIValidationErrorToAntDValidationMessage(response.data?.data);
                    form.setFields(validationErrors);
                    break;
                case 7001:
                    alertMessageState.alertMessageText = 'Hibásan megadott beviteli mezők.';
                    for (const key in values) {
                        if(values[key] === response.data?.data?.deviceId) {
                            form.setFields([{
                                name: key,
                                errors: ['Ez az azonosító már létezik.']
                            }]);
                            break;
                        }
                    }

            }

        } else if(response.status === 404){

            switch (response.data.code) {

                case 9000:
                    alertMessageState.alertMessageText = 'A licenc nem található.';
                    break;

            }

        } else {
            alertMessageState.alertMessageText = 'Ismeretlen hiba történt. Kérjük próbálja újra!';
        }

        setFinalized(false);

        dispatch(showAlertMessage(alertMessageState));

    };

    const lessThenToday = (currentDate: dayjs.Dayjs) => {
        return currentDate.endOf('day').isBefore(dayjs().startOf('day'));
    };

    const disabledLicenseEndDates = (currentDate: dayjs.Dayjs) => {
        if(currentDate.endOf('day').isBefore(dayjs().startOf('day'))) return true;

        const licenseStartDate = form.getFieldValue('licenseStartDate');

        if(licenseStartDate && currentDate.endOf('day').isBefore(licenseStartDate.startOf('day'))) return true;

        return false;
    };

    useEffect(() => {

        const messageShowed = showDefaultErrorMessages(loaderData.getLicenseResponse);
        if(messageShowed) {
            navigate('/cms/admin/companies/list');
            return;
        }

        dispatch(clearAlertMessage());
        const alertMessageState: AlertMessageState = {
            alertMessageType: 'error',
            alertMessageText: 'Ismeretlen hiba történt a cég lekérése során. Kérjük próbálja újra!'
        };

        if(loaderData.getLicenseResponse?.status === 200 && loaderData.getLicenseResponse?.data?.license) {

            form.setFieldValue('licenseStartDate', loaderData.getLicenseResponse.data.license.licenseStartDate ? dayjs(loaderData.getLicenseResponse.data.license.licenseStartDate * 1000) : null);
            form.setFieldValue('licenseEndDate', loaderData.getLicenseResponse.data.license.licenseEndDate ? dayjs(loaderData.getLicenseResponse.data.license.licenseEndDate * 1000) : null);
            form.setFieldValue('licenseKey', loaderData.getLicenseResponse.data.license.licenseKey);
            form.setFieldValue('examination', loaderData.getLicenseResponse.data.license.examination === null || loaderData.getLicenseResponse.data.license.examination === undefined ? false : loaderData.getLicenseResponse.data.license.examination);
            
            switch(loaderData.getLicenseResponse.data.license.type) {
                case LicenseTypeEnum.Headset:
                    form.setFieldValue('type', 'Headsetes');
                    break;
                case LicenseTypeEnum.Tablet:
                    form.setFieldValue('type', 'Tabletes');
                    break;
                case LicenseTypeEnum.Combined:
                    form.setFieldValue('type', 'Kombinált (Headset + Tablet)');
            }

            if(loaderData.getLicenseResponse.data?.license?.module?.moduleParts) {
                setModuleParts(loaderData.getLicenseResponse.data.license.module.moduleParts);
            }
            
            if(loaderData.getLicenseResponse.data?.license?.module?.modulePartProperties) {
                setModulePartProperties(loaderData.getLicenseResponse.data.license.module.modulePartProperties);
            }

            if(loaderData.getLicenseResponse.data?.license?.licenseModuleParts) {
                setLicenseModuleParts(loaderData.getLicenseResponse.data.license.licenseModuleParts);
            }

            if(loaderData.getLicenseResponse.data?.license?.module?.moduleParts &&
                loaderData.getLicenseResponse.data?.license?.module?.modulePartProperties &&
                loaderData.getLicenseResponse.data?.license?.licenseModuleParts) {

                    const _disabledModulePartProperties: DisabledModulePartProperties[] = [];
                    for (const modulePart of loaderData.getLicenseResponse.data?.license?.module?.moduleParts as ModulePart[]) {
                        
                        const _modulePartProperties: { id: number, disabled: boolean }[] = [];
                        if(loaderData.getLicenseResponse.data?.license?.licenseModuleParts.filter((item: any) => item?.modulePart?.id === modulePart.id).length === 0) {
                            
                            for (const modulePartProperty of loaderData.getLicenseResponse.data?.license?.module?.modulePartProperties as ModulePartProperty[]) {
                                _modulePartProperties.push({
                                    id: modulePartProperty.id,
                                    disabled: true
                                });
                            }

                        } else {

                            for (const modulePartProperty of loaderData.getLicenseResponse.data?.license?.module?.modulePartProperties as ModulePartProperty[]) {
                                _modulePartProperties.push({
                                    id: modulePartProperty.id,
                                    disabled: false
                                });
                            }

                        }

                        _disabledModulePartProperties.push({
                            modulePartid: modulePart.id,
                            modulePartProperties: _modulePartProperties
                        });
                    }

                    setDisabledModulePartProperties(_disabledModulePartProperties);

            }

            if(loaderData.getLicenseResponse.data?.license?.devices) {
                setLicenseDevices(loaderData.getLicenseResponse.data?.license?.devices);
            }

            return;
        }  else if(loaderData.getLicenseResponse?.status === 404){

            switch (loaderData.getLicenseResponse?.data?.code) {

                case 9000:
                    alertMessageState.alertMessageText = 'A licenc nem található.';
                    break;

            }

        }

        navigate('/cms/admin/active-companies/list');
        dispatch(showAlertMessage(alertMessageState));

    }, [dispatch, navigate, loaderData.getLicenseResponse, form]);

    useEffect(() => {
        dispatch(setSelectedMenuItemKeys(['active-companies']));
        dispatch(setOpenKeys(['companies']));
        dispatch(setPageTitle('Licenc módosítása'));
    }, [dispatch]);

    return (
        <>
            <Card title={`${finalized ? 'Licenc véglegesítve' : ''}`}>
                
                <Form
                    form={form}
                    onFinish={onFinish}
                    layout='vertical'
                    className='admin-license-edit-form'
                >

                    <Form.Item label='Licenc kulcs' name='licenseKey'>
                        <Input disabled />
                    </Form.Item>

                    <Form.Item label='Típus' name='type'>
                        <Input disabled />
                    </Form.Item>

                    <Form.Item
                        name='examination'
                        rules={[{required: true, message: 'Mező kitöltése kötelező!'}]}
                        valuePropName='checked'
                    >
                        <Checkbox disabled={finalized === true}>Vizsgáztatás</Checkbox>
                    </Form.Item>

                    <Form.Item
                        name="licenseStartDate"
                        label="Érvényesség kezdete"
                        rules={[{required: true, message: 'Mező kitöltése kötelező!'}]}
                    >
                        <DatePicker
                            disabledDate={lessThenToday}
                            onChange={licenseStartDateChange}
                            disabled={finalized === true}
                        />
                    </Form.Item>

                    <Form.Item
                        name="licenseEndDate"
                        label="Érvényesség vége"
                        rules={[{required: true, message: 'Mező kitöltése kötelező!'}]}
                    >
                        <DatePicker disabledDate={disabledLicenseEndDates} disabled={finalized === true} />
                    </Form.Item>

                    <Divider></Divider>

                    <div style={{ marginBottom: '20px' }}>
                        <h6>Modulok adatai:</h6>
                    </div>

                    <div className='table-responsive'>
                        <table className='license-table'>
                            <thead>
                                <tr>
                                    {
                                        (modulePartProperties.length && moduleParts.length) ?
                                            (<><td className='table-header'>Megnevezés</td><td className='table-header text-center'>Elérhető</td></>) :
                                            ''
                                    }

                                    {modulePartProperties.map((modulePartProperty, index: number) => (
                                        <td key={index} className='table-header text-center'>
                                            {modulePartProperty.name}
                                        </td>
                                    ))}

                                </tr>
                            </thead>

                            <tbody>
                                {moduleParts.map((modulePart, index: number) => (
                                    <tr key={index}>
                                        <td>
                                            {
                                                modulePart.icon ?
                                                    (
                                                        <>
                                                            <img style={{ maxWidth: "22px", maxHeight: "22px" }} src={modulePart.icon} alt="icon" />
                                                            &nbsp;
                                                            &nbsp;
                                                        </>
                                                    )
                                                    : ''
                                            }
                                            {modulePart.name}
                                        </td>
                                        <td className='text-center'>
                                            <Form.Item
                                                name={`modulePartAvailable.${modulePart.id}`}
                                                valuePropName="checked"
                                                initialValue={isModulePartAvailableInLicense(modulePart.id)}
                                            >
                                                <Checkbox disabled={finalized === true} onChange={(e) => modulePartAvailabilityChange(e, modulePart.id)}/>
                                            </Form.Item>
                                        </td>
                                        {createLicenseModulePartModulePartPropertyTDs(modulePart.id)}
                                    </tr>
                                ))}
                            </tbody>

                        </table>
                    </div>

                    {licenseDevices.map(device => (
                        <div key={device.id}>
                            <Divider>"{device.name}" adatai:</Divider>
                            <Form.Item
                                label='Eszköz azonsítója'
                                name={`devices.${device.id}.deviceId`}
                                initialValue={device.deviceId}
                                rules={[{required: true, message: 'Adjon meg azonosítót!'}]}
                            >
                                <Input disabled={finalized === true} />
                            </Form.Item>
                        </div>
                    ))}

                    <Form.Item>

                        <Link to={loaderData.getLicenseResponse.data?.license?.company ? `/cms/admin/companies/edit/${ loaderData.getLicenseResponse.data?.license.company.id }` : '/cms/admin/active-companies/list'}>
                            <Button type="default" htmlType="button">
                                Vissza
                            </Button>
                        </Link>

                        {
                            // már véglegesített állapotban van a licenc
                            alreadyFinalized === true ?
                            // a frontenden véglegesített státuszban van
                            finalized === true ?
                            (
                                <div style={{float: 'right'}}>

                                    <Button type='primary' htmlType='button' onClick={() => {
                                        setFinalized(false);
                                    }}>
                                        Módosítás
                                    </Button>
                                    
                                </div>
                            ) :
                            (
                                <div style={{float: 'right'}}>

                                    <Button type='primary' htmlType='button' onClick={() => {
                                        setFinalized(true);
                                        // inputok alaphelyzetbe állítása
                                        form.setFieldValue('licenseStartDate', loaderData.getLicenseResponse.data.license.licenseStartDate ? dayjs(loaderData.getLicenseResponse.data.license.licenseStartDate * 1000) : null);
                                        form.setFieldValue('licenseEndDate', loaderData.getLicenseResponse.data.license.licenseEndDate ? dayjs(loaderData.getLicenseResponse.data.license.licenseEndDate * 1000) : null);
                                        form.setFieldValue('examination', loaderData.getLicenseResponse.data.license.examination);
                                        for (const modulePart of moduleParts) {

                                            if(licenseModuleParts.filter((item: any) => item?.modulePart?.id === modulePart.id).length === 0) form.setFieldValue(`modulePartAvailable.${modulePart.id}`, false);
                                            else form.setFieldValue(`modulePartAvailable.${modulePart.id}`, true);

                                            const licenseModulePart: any[] = licenseModuleParts.filter((licenseModulePart: any) => licenseModulePart?.modulePart?.id === modulePart.id);
                                            
                                            if(licenseModulePart.length === 0) {

                                                for (const modulePartProperty of modulePartProperties) {
                                                    form.setFieldValue(`licenseModulePartModulePartProperty.${modulePart.id}.${modulePartProperty.id}`, false);
                                                    setDisabledModulePartPropertyCheckbox(modulePart.id, modulePartProperty.id, true);
                                                }

                                            } else {

                                                for (const modulePartProperty of modulePartProperties) {
                                                    const licenseModulePartModulePartProperty = licenseModulePart[0].licenseModulePartModulePartProperties.filter((licenseModulePartModulePartProperty: any) => licenseModulePartModulePartProperty.modulePartProperty.id === modulePartProperty.id);
                                                    
                                                    if(licenseModulePartModulePartProperty?.length === 0) form.setFieldValue(`licenseModulePartModulePartProperty.${modulePart.id}.${modulePartProperty.id}`, false);
                                                    else form.setFieldValue(`licenseModulePartModulePartProperty.${modulePart.id}.${modulePartProperty.id}`, true);
                                                    
                                                    setDisabledModulePartPropertyCheckbox(modulePart.id, modulePartProperty.id, false);
                                                }

                                            }
                                        }

                                        for (const licenseDevice of licenseDevices) {
                                            form.setFieldValue(`devices.${ licenseDevice.id }.deviceId`, licenseDevice.deviceId);
                                        }

                                        clearFormErrors();

                                    }}>
                                        Módosítás elvetése
                                    </Button>
                                    &nbsp;
                                    <Button type='primary' htmlType='button' onClick={() => {
                                        if(window.confirm('Biztosan véglegesíted a módosításokat? Véglegesítés esetén el lesz adva a licenc és értestést kap a cég.')) {
                                            sendAsFinalized = true;
                                            form.submit();
                                        }
                                    }}>
                                        Módosítás véglegesítése
                                    </Button>
                                    
                                </div>
                            ) :
                            (
                                <div style={{float: 'right'}}>

                                    <Button type='primary' htmlType='button' onClick={() => {
                                        sendAsFinalized = false;
                                        form.submit();
                                    }}>
                                        Mentés
                                    </Button>
                                    &nbsp;
                                    <Button type='primary' htmlType='button' onClick={() => {
                                        if(window.confirm('Biztosan véglegesíted a licenct? Véglegesítés esetén el lesz adva a licenc és értestést kap a cég.')) {
                                            sendAsFinalized = true;
                                            form.submit();
                                        }
                                    }}>
                                        Véglegesítés
                                    </Button>
                                    
                                </div>
                            )
                        }

                    </Form.Item>
                            
                </Form>

            </Card>
            
        </>
    )
}