import { Card } from 'antd';
import { useDispatch } from 'react-redux';
import { setPageTitle, setSelectedMenuItemKeys } from '../../../../../redux/reducer/antd-layout.reducer';
import { useEffect, useRef, useState } from 'react';
import { Form, Input, Button } from 'antd';
import { networkCall } from '../../../../../util/network-call/network-call';
import { showDefaultErrorMessages } from '../../../../../util/show-default-error-message/show-default-error-messages';
import { AlertMessageState, clearAlertMessage, showAlertMessage } from '../../../../../redux/reducer/alert-message.reducer';
import { tranformAPIValidationErrorToAntDValidationMessage } from '../../../../../util/validation/transform-api-validation-errors-to-antd-validation-messsages';
import { Link, useLoaderData, useNavigate } from 'react-router-dom';
import './admin-module-edit.css';
import { CloseOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { ModulePart } from '../../../../../constant/type/module-part.type';
import { ModulePartProperty } from '../../../../../constant/type/module-part-property.type';


export const AdminModuleEdit = () => {

    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const loaderData: any = useLoaderData();
    const [moduleParts, setModuleParts] = useState<ModulePart[]>([]);
    const [modulePartProperties, setModulePartProperties] = useState<ModulePartProperty[]>([]);

    const newModulePartItemIdCounter = useRef(-1);
    const onAddModulePartClick = () => {
        const _moduleParts = JSON.parse(JSON.stringify(moduleParts)) as ModulePart[];
        _moduleParts.push({
            id: newModulePartItemIdCounter.current--,
            name: '',
            uniqueId: ''
        });
        setModuleParts(_moduleParts);
    };

    const onDeleteModulePartClick = (id: number) => {
        const _moduleParts = JSON.parse(JSON.stringify(moduleParts)) as ModulePart[];
        setModuleParts(_moduleParts.filter(modulePart => modulePart.id !== id));
    }

    const newModulePartPropertyItemIdCounter = useRef(-1);
    const onAddModulePartPropertyClick = () => {
        const _modulePartProperties = JSON.parse(JSON.stringify(modulePartProperties)) as ModulePartProperty[];
        _modulePartProperties.push({
            id: newModulePartPropertyItemIdCounter.current--,
            name: '',
            uniqueId: ''
        });
        setModulePartProperties(_modulePartProperties);
    };
    const onDeleteModulePartPropertyClick = (id: number) => {
        const _modulePartProperties = JSON.parse(JSON.stringify(modulePartProperties)) as ModulePartProperty[];
        setModulePartProperties(_modulePartProperties.filter(modulePartProperty => modulePartProperty.id !== id));
    }

    const onFinish = async (values: any) => {
        const _moduleParts = [];
        const _modulePartProperties = [];

        for (const modulePart of moduleParts) {
            _moduleParts.push({
                id: modulePart.id,
                name: form.getFieldValue(`moduleParts.${modulePart.id}.name`),
                uniqueId: form.getFieldValue(`moduleParts.${modulePart.id}.uniqueId`)
            });
        }

        for (const modulePartProperty of modulePartProperties) {
            _modulePartProperties.push({
                id: modulePartProperty.id,
                name: form.getFieldValue(`modulePartProperties.${modulePartProperty.id}.name`),
                uniqueId: form.getFieldValue(`modulePartProperties.${modulePartProperty.id}.uniqueId`)
            });
        }

        dispatch(clearAlertMessage());
        dispatch(showAlertMessage({
            alertMessageType: 'loading',
            alertMessageText: 'Kérés folyamatban...'
        }));

        const response = await networkCall(`/modules/${ loaderData.id }`, {
            method: 'PUT',
            body: {
                name: values.name,
                uniqueId: values.uniqueId,
                moduleParts: _moduleParts,
                modulePartProperties: _modulePartProperties
            }
        });

        const messageShowed = showDefaultErrorMessages(response);

        if(messageShowed) return;

        dispatch(clearAlertMessage());
        const alertMessageState: AlertMessageState = {
            alertMessageType: 'error',
            alertMessageText: 'Ismeretlen hiba történt. Kérjük próbálja újra!'
        };

        if (response.status === 200 && response.data.module !== undefined) {

            alertMessageState.alertMessageText = 'Mentés sikeres.';
            alertMessageState.alertMessageType = 'success';

            // visszatöltjük a visszakapott adatokat, hogy az esetleges negatív ID-kat cseréljük
            if(response.data.module.modulePartProperties) {
                setModulePartProperties(response.data.module.modulePartProperties);
            }

            if(response.data.module.moduleParts) {
                setModuleParts(response.data.module.moduleParts);
            }

        }else if(response.status === 400){

            switch (response.data.code) {

                case 2000:
                    alertMessageState.alertMessageText = 'Hibásan megadott beviteli mezők.';
                    const validationErrors = tranformAPIValidationErrorToAntDValidationMessage(response.data?.data);
                    form.setFields(validationErrors);
                    break;
                case 10001:
                    alertMessageState.alertMessageText = 'Hibásan megadott beviteli mezők.';
                    form.setFields([{
                        name: `moduleParts.${ response.data?.data?.id }.uniqueId`,
                        errors: ['A megadott azonosítóval már létezik elem ebben a modulban.']
                    }]);
                    break;
                case 11001:
                    alertMessageState.alertMessageText = 'Hibásan megadott beviteli mezők.';
                    form.setFields([{
                        name: `modulePartProperties.${ response.data?.data?.id }.uniqueId`,
                        errors: ['A megadott azonosítóval már létezik tulajdonság ebben a modulban.']
                    }]);
                    break;

            }

        }else{
            alertMessageState.alertMessageText = 'Ismeretlen hiba történt. Kérjük próbálja újra!';
        }

        dispatch(showAlertMessage(alertMessageState));

    }

    useEffect(() => {

        const messageShowed = showDefaultErrorMessages(loaderData.response);
        if(messageShowed) return;

        const alertMessageState: AlertMessageState = {
            alertMessageType: 'error',
            alertMessageText: 'Ismeretlen hiba történt a modul lekérése során. Kérjük próbálja újra!'
        };

        if(loaderData.response.status === 200 && loaderData.response.data && loaderData.response.data.module) {
            form.setFieldValue('name', loaderData.response.data.module.name);
            form.setFieldValue('uniqueId', loaderData.response.data.module.uniqueId);

            if(loaderData.response.data.module.modulePartProperties) {
                setModulePartProperties(loaderData.response.data.module.modulePartProperties);
            }

            if(loaderData.response.data.module.moduleParts) {
                setModuleParts(loaderData.response.data.module.moduleParts);
            }
            
            return;
        }
        
        if(loaderData.response.status === 404) {
            switch (loaderData.response.data.code) {
                case 8000:
                    alertMessageState.alertMessageText = 'A modul nem található.';
                    alertMessageState.alertMessageType = 'error';
                    navigate('/cms/admin/modules/list');
                    break;
            }
        }

        dispatch(clearAlertMessage());
        dispatch(showAlertMessage(alertMessageState));

    }, [dispatch, loaderData.response, form, navigate]);

    useEffect(() => {
        dispatch(setSelectedMenuItemKeys(['3']));
        dispatch(setPageTitle('Modul módosítása'));
    }, [dispatch]);

    return (
        <Card>
            
            <Form
                form={form}
                onFinish={onFinish}
                layout='vertical'
            >
    
                <Form.Item
                    label='Modul neve'
                    name='name'
                    rules={[{required: true, message: 'Név megadása kötelező!'}]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label='Modul azonosítója'
                    name='uniqueId'
                    rules={[{required: true, message: 'Azonosító megadása kötelező!'}]}
                >
                    <Input style={{ fontWeight: ['holofire'].includes(loaderData?.response?.data?.module?.uniqueId) ? 'bold' : 'normal'}} disabled={['holofire'].includes(loaderData?.response?.data?.module?.uniqueId)}/>
                </Form.Item>

                <div style={{ marginBottom: '20px' }}>
                    <strong>Konfigurálás</strong> (A táblázat sorai az egyes modul elemeket adják meg, míg az oszlopok ezen elemek tulajdonságait. A két input mező közül a felső a név. Az alsó pedig az egyedi azonosító, ami alapján a HoloFire kliens applikáció azonosítja, hogy melyik tulajdonságról van szó.)
                </div>

                <div className='table-responsive'>
                    <table className='table module-table'>
                        <tr>
                            <td></td>
                            {modulePartProperties.map((modulePartProperty: any, index: number) => (
                                <td key={index}>
                                    <div style={{ display: 'flex' }}>
                                        <div>
                                            <Form.Item
                                                name={`modulePartProperties.${modulePartProperty.id}.name`}
                                                rules={[{required: true, message: 'Adjon meg egy nevet!'}]}
                                                initialValue={modulePartProperty.name}
                                            >
                                                <Input placeholder='név'/>
                                            </Form.Item>
                                            <Form.Item
                                                name={`modulePartProperties.${modulePartProperty.id}.uniqueId`}
                                                rules={[{required: true, message: 'Adjon meg egy azonosítót!'}]}
                                                initialValue={modulePartProperty.uniqueId}
                                            >
                                                <Input placeholder='azonosító' style={{ fontWeight: 'normal' }}/>
                                            </Form.Item>
                                        </div>
                                        <CloseOutlined className='delete-button' onClick={() => onDeleteModulePartPropertyClick(modulePartProperty.id)}/>
                                    </div>
                                </td>
                            ))}
                            <td><span className='addmodulePartPropertySpan' onClick={onAddModulePartPropertyClick}><PlusSquareOutlined /></span></td>
                        </tr>
                            {moduleParts.map((modulePart: any, index: number) => (
                                <tr key={index}>
                                    <td>

                                        <div style={{display: 'flex'}}>
                                            <div>
                                                <Form.Item
                                                    name={`moduleParts.${modulePart.id}.name`}
                                                    rules={[{required: true, message: 'Adjon meg egy nevet!'}]}
                                                    initialValue={modulePart.name}
                                                >
                                                    <Input placeholder='név' />
                                                </Form.Item>
                                                <Form.Item
                                                    name={`moduleParts.${modulePart.id}.uniqueId`}
                                                    rules={[{required: true, message: 'Adjon meg egy azonosítót!'}]}
                                                    initialValue={modulePart.uniqueId}
                                                >
                                                    <Input placeholder='azonosító' style={{ fontWeight: 'normal' }}/>
                                                </Form.Item>
                                            </div>
                                            <CloseOutlined className='delete-button' onClick={() => onDeleteModulePartClick(modulePart.id)}/>
                                        </div>
                                        
                                    </td>
                                </tr>
                            ))}
                        <tr>
                            <td><span className='addmodulePartSpan' onClick={onAddModulePartClick}><PlusSquareOutlined /></span></td>
                        </tr>
                    </table>
                </div>

                <Form.Item>

                    <Link to='/cms/admin/modules/list'>
                        <Button type="default" htmlType="button">
                            Vissza
                        </Button>
                    </Link>
                    <Button type='primary' htmlType='submit' style={{float: 'right'}}>
                        Mentés
                    </Button>
    
                </Form.Item>
                        
            </Form>

        </Card>
    )
}