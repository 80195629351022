import { createSlice } from "@reduxjs/toolkit";
import { RoleEnum } from "../../constant/enum/role.enum";

interface User {
    id: string;
    email: string;
    name: string;
    role: RoleEnum;
    uniqueId?: string;
}

interface SessionState {
    user: User | null;
}

const initialState: SessionState = {
    user: null
}

export const sessionSlice = createSlice({
    name: 'session',
    initialState,
    reducers: {
        setMe: (state, action) => {
            state.user = action.payload.user;
        },
        login: (state, action) => {
            state.user = action.payload.user;
            localStorage.setItem('AccessToken', action.payload.token);
        },
        logout: (state) => {
            state.user = null;
            localStorage.removeItem('AccessToken');
        }
    }
});

export const {
    setMe,
    login,
    logout
} = sessionSlice.actions;