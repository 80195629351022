import React from 'react';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { router } from './router/router';
import { store } from './redux/store';
import { AlertMessage } from './component/alert-message/alert-message';

import 'bootstrap/dist/css/bootstrap.min.css';
import { NoNetworkConnection } from './component/no-network-connection/no-network-connection';

function App() {
  return (
    <React.StrictMode>
      <NoNetworkConnection />
      <Provider store={store}>
        <AlertMessage />
        <RouterProvider router={router} />
      </Provider>
    </React.StrictMode>
  );
}

export default App;
