import { Button, Card, Table } from 'antd';
import './admin-license-list.css';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useRevalidator } from 'react-router-dom';
import { showDefaultErrorMessages } from '../../../../../util/show-default-error-message/show-default-error-messages';
import { AlertMessageState, clearAlertMessage, showAlertMessage } from '../../../../../redux/reducer/alert-message.reducer';
import { networkCall } from '../../../../../util/network-call/network-call';
import { formatTimestampToDate } from '../../../../../util/date/format-timestamp-to-date';
import { License } from '../../../../../constant/type/license.type';
import { RootState } from '../../../../../redux/store';
import { DeleteButton } from '../../../../button/delete-button/delete-button';


type AdminLicenseListProps = {
    companyId: number;
    licenses: License[];
    reValidate: Function;
}

type TableDataType = {
    key: React.Key,
    name: string | undefined | null;
    finalized: boolean | null;
    licenseStartDate: number | null;
    licenseEndDate: number | null;
}

export const AdminLicenseList = ({ companyId, licenses, reValidate }: AdminLicenseListProps) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const revalidator = useRevalidator();
    const { searchTerm } = useSelector((state: RootState) => state.antdLayoutState);

    const handleDeleteClick = async (e: React.MouseEvent, id: React.Key) => {
        e.stopPropagation();

        if(window.confirm('Biztosan törli a céghet tarrtozó licenszt?')) {

            dispatch(clearAlertMessage());
            dispatch(showAlertMessage({
                alertMessageType: 'loading',
                alertMessageText: 'Kérés folyamatban...'
            }));
            
            const response = await networkCall(`/licenses/${ id }`, {
                method: 'DELETE'
            });

            const messageShowed = showDefaultErrorMessages(response);
            if(messageShowed) return;

            if(response.status === 204) {
                revalidator.revalidate();
                dispatch(showAlertMessage({
                    alertMessageType: 'success',
                    alertMessageText: 'Törlés sikeres.'
                }));
                return;
            }

            dispatch(clearAlertMessage());
            const alertMessageState: AlertMessageState = {
                alertMessageType: 'error',
                alertMessageText: 'Ismeretlen hiba történt. Kérjük próbálja újra!'
            };

            if(response.status === 404) {
                alertMessageState.alertMessageText = 'Törlés sikertelen, a törlendő hozzárendelés nem létezik.';
            }

            dispatch(showAlertMessage(alertMessageState));

        }

    }
    
    const columns = [
        {
            title: 'Modul neve',
            dataIndex: 'name'
        },
        {
            title: 'Licenc',
            render: (record: TableDataType) => {
                if(!record.finalized || !record.licenseStartDate || !record.licenseEndDate) return <strong>nem érvényes</strong>; 
                
                const startDate = new Date(record.licenseStartDate*1000);
                const endDate = new Date(record.licenseEndDate*1000);
                const today = new Date();

                startDate.setHours(0, 0, 0, 0);
                endDate.setHours(0, 0, 0, 0);
                today.setHours(0, 0, 0, 0);

                if(startDate.getTime() <= today.getTime() && endDate.getTime() >= today.getTime())
                    return <><strong>érvényes</strong> ({formatTimestampToDate(record.licenseStartDate)} - {formatTimestampToDate(record.licenseEndDate)})</>;
                else return <strong>nem érvényes</strong>;

            }
        },
        {
            title: 'Véglegesítve',
            render: (record: TableDataType) => {
                if(record.finalized === true) return 'igen';
                return 'nem';
            }
        },
        {
            title: 'Törlés',
            render: (record: TableDataType) => {
                return <DeleteButton onClick={(e) => handleDeleteClick(e, record.key)} />;
            }
        }
    ];
    const [data, setData] = useState<TableDataType[]>([]);

    useEffect(() => {
        if(licenses) {
            const tableData: TableDataType[] = [];
            for (const license of licenses) {

                if(!license?.module || license?.module?.name.toLowerCase().includes(searchTerm.toLowerCase())) {
                    tableData.push({
                        key: license.id,
                        name: license?.module?.name,
                        finalized: license.finalized,
                        licenseStartDate: license.licenseStartDate,
                        licenseEndDate: license.licenseEndDate
                    });
                }
    
            }

            setData(tableData);
            return;
        }
    }, [licenses, searchTerm]);

    return (
        <Card
            title='Licencek'
            extra=<Link to={`/cms/admin/companies/${ companyId }/licenses/add`}><Button type='primary' className='fw-bold'>Új licenc hozzáadása</Button></Link>
        >
            <Table
                className='table-responsive data-table'
                columns={columns}
                dataSource={data}
                bordered
                rowClassName={(record, name) => 'license-table-row'}
                onRow={(row, rowIndex) => {
                    return {
                        onClick: (e) => {
                            navigate(`/cms/admin/licenses/edit/${row.key}`);
                        }
                    }
                }}
                pagination={{ position: ['bottomCenter'], pageSize: 10}}
            />
        </Card>
    )
}