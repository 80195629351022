import { Input, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useLoaderData } from 'react-router-dom';
import { showDefaultErrorMessages } from '../../../../../util/show-default-error-message/show-default-error-messages';
import { AlertMessageState, clearAlertMessage, showAlertMessage } from '../../../../../redux/reducer/alert-message.reducer';
import { setPageTitle, setSelectedMenuItemKeys } from '../../../../../redux/reducer/antd-layout.reducer';
import { CourseTypeEnum } from '../../../../../constant/enum/course-type.enum';
import { formatTimestampToDate } from '../../../../../util/date/format-timestamp-to-date';
import { FilterDropdownProps } from 'antd/es/table/interface';
import { SearchOutlined } from '@ant-design/icons';
import { RootState } from '../../../../../redux/store';

type TableDataType = {
    key: React.Key;
    type: CourseTypeEnum;
    name: string;
    completed: number | null;
    deadline: number | null;
    created: number;
}

export const StudentCourseList = () => {

    const loaderData: any = useLoaderData();
    const dispatch = useDispatch();
    const { searchTerm } = useSelector((state: RootState) => state.antdLayoutState);

    const getCourseTypeName = (courseType: CourseTypeEnum) => {
        switch(courseType) {
            case CourseTypeEnum.AnnualMandatory: return 'Kötelező éves';
            case CourseTypeEnum.Practice: return 'Gyakorlás';
            default: return '';
        }
    };
    
    const columns = [
        {
            title: 'Oktatás neve',
            dataIndex: 'name',
            sorter: (a: TableDataType, b: TableDataType) => String(a.name).localeCompare(b.name),
            /*filterDropdown: ({ confirm, setSelectedKeys, selectedKeys }: FilterDropdownProps) => {
                return <Input
                    autoFocus
                    value={selectedKeys[0]}
                    placeholder='Keresés...'
                    onInput={(e: any) => {
                        setSelectedKeys( e.target.value ? [e.target.value] : [] );
                        confirm({ closeDropdown: false });
                    }}
                />;
            },
            filterIcon: () => {
                return <SearchOutlined />;
            },
            onFilter: (value: any, record: TableDataType) => {
                return record.name.toLowerCase().includes(value.toLowerCase());
            }*/
        },
        {
            title: 'Státusz',
            render: (record: TableDataType) => {

                if(record.type === CourseTypeEnum.AnnualMandatory) {
                    const today = new Date();
                    today.setHours(0, 0, 0, 0);
                    const deadline = new Date(record.deadline! * 1000);
                    deadline.setHours(0, 0, 0, 0);

                    if(record.completed) { // már teljesítve van

                        const completed = new Date(record.completed * 1000);
                        completed.setHours(0, 0, 0, 0);
                        completed.setFullYear(completed.getFullYear()  + 1); // lejárati idő: teljesítés + 1 év

                        if(completed.getTime() < today.getTime()) return 'Érvénytelen';
                        else return 'Teljesített';

                    } else { // még nincs teljesítve

                        if(today.getTime() <= deadline.getTime()) return 'Folyamatban';
                        else return 'Lejárt';
                        
                    }
                }

            }
        },
        {
            title: 'Típus',
            sorter: (a: TableDataType, b: TableDataType) => getCourseTypeName(a.type).toLocaleLowerCase().localeCompare(getCourseTypeName(b.type).toLocaleLowerCase()),
            render: (record: TableDataType) => {
                return getCourseTypeName(record.type);
            }
        },
        {
            title: 'Létrehozva',
            sorter: (a: TableDataType, b: TableDataType) => a.created - b.created,
            render: (record: TableDataType) => {
                return formatTimestampToDate(record.created);
            }
        },
        {
            title: 'Határidő',
            sorter: (a: TableDataType, b: TableDataType) => (a.deadline ? a.deadline : 0) - (b.deadline ? b.deadline : 0),
            render: (record: TableDataType) => {
                return record.deadline ? formatTimestampToDate(record.deadline) : '';
            }
        },
        {
            title: 'Érvényesség',
            sorter: (a: TableDataType, b: TableDataType) => (a.completed ? a.completed : 0) - (b.completed ? b.completed : 0),
            render: (record: TableDataType) => {

                if(record.type === CourseTypeEnum.AnnualMandatory) {
                    if(record.completed) { // már teljesítve van

                        const completed = new Date(record.completed * 1000);
                        completed.setHours(0, 0, 0, 0);
                        completed.setFullYear(completed.getFullYear()  + 1); // lejárati idő: teljesítés + 1 év

                        return formatTimestampToDate(completed.getTime() / 1000);
                    }

                }

            }
        },
        /*{
            title: 'Műveletek',
            render: (record: TableDataType) => {
                return (
                    <a href="#" onClick={(e) => {
                        e.preventDefault();
                        alert('Itt lehet majd az igazolást letölteni PDF-ben.');
                    }}>Igazolás letöltése</a>
                );
            }
        }*/
    ];
    const [data, setData] = useState();

    useEffect(() => {
        const messageShowed = showDefaultErrorMessages(loaderData.getCoursesResponse);
        if(messageShowed) return;

        if(loaderData.getCoursesResponse?.status === 200 && loaderData?.getCoursesResponse?.data?.courses) {
            const tableData: any = [];
            for (const course of loaderData.getCoursesResponse.data.courses) {

                if(!course?.education || course.education.name.toLowerCase().includes(searchTerm.toLowerCase())) {
                    tableData.push({
                        key: course.id,
                        type: course.type,
                        name: course?.education?.name,
                        completed: course.completed,
                        deadline: course.deadline,
                        created: course.created
                    });
                }

            }

            setData(tableData);
            return;
        }

        dispatch(clearAlertMessage());
        const alertMessageState: AlertMessageState = {
            alertMessageType: 'error',
            alertMessageText: 'Ismeretlen hiba történt a kurzusok listázása során. Kérjük próbálja újra!'
        };

        dispatch(showAlertMessage(alertMessageState));

    }, [loaderData.getCoursesResponse, dispatch, searchTerm]);

    useEffect(() => {
        dispatch(setSelectedMenuItemKeys(['student-courses']));
        dispatch(setPageTitle('Kurzusok'));
    }, [dispatch]);

    return (
        <Table
            className='table-responsive data-table'
            columns={columns}
            dataSource={data}
            bordered
            rowClassName={(record, name) => 'student-course-table-row'}
            pagination={{ position: ['bottomCenter'], pageSize: 10}}
        />
    )
}