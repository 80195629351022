import { Button, Input, Table } from 'antd';
import './instructor-user-list.css';
import { useDispatch, useSelector } from 'react-redux';
import { setPageTitle, setSelectedMenuItemKeys } from '../../../../../redux/reducer/antd-layout.reducer';
import { useEffect, useState } from 'react';
import { Link, useLoaderData, useRevalidator } from 'react-router-dom';
import { showDefaultErrorMessages } from '../../../../../util/show-default-error-message/show-default-error-messages';
import { AlertMessageState, clearAlertMessage, showAlertMessage } from '../../../../../redux/reducer/alert-message.reducer';
import { SearchOutlined } from '@ant-design/icons';
import { networkCall } from '../../../../../util/network-call/network-call';
import { RoleEnum } from '../../../../../constant/enum/role.enum';
import { formatTimestampToDate } from '../../../../../util/date/format-timestamp-to-date';
import { Course } from '../../../../../constant/type/course.type';
import { CourseTypeEnum } from '../../../../../constant/enum/course-type.enum';
import { FilterDropdownProps } from 'antd/es/table/interface';
import { getIconUrl } from '../../../../../util/image-helper/get-image-url';
import { RootState } from '../../../../../redux/store';

type TableDataType = {
    key: React.Key,
    index: number;
    name: string;
    role: RoleEnum;
    courses?: Course[];
}

export const InstructorUserList = () => {

    const dispatch = useDispatch();
    const loaderData: any = useLoaderData();
    const reValidator = useRevalidator();
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const { searchTerm } = useSelector((state: RootState) => state.antdLayoutState);

    const handleSelectionClick = async (e: React.MouseEvent) => {
        e.preventDefault();

        if(selectedRowKeys.length === 0) {
            alert('Nincs kijelölt elem.');
            return;
        }

        if (window.confirm('Biztosan törlöd a kijelölt elemeket? Az adatok el fognak veszni.')) {
            
            dispatch(clearAlertMessage());
            dispatch(showAlertMessage({
                alertMessageType: 'loading',
                alertMessageText: 'Kérés folyamatban...'
            }));

            const response = await networkCall('/users/bulk-delete', {
                method: 'POST',
                body: {
                    ids: selectedRowKeys
                }
            });
    
            const messageShowed = showDefaultErrorMessages(response);
    
            if(messageShowed) return;
    
            dispatch(clearAlertMessage());
            const alertMessageState: AlertMessageState = {
                alertMessageType: 'error',
                alertMessageText: 'Ismeretlen hiba történt. Kérjük próbálja újra!'
            };
    
            if (response.status === 204) {
    
                alertMessageState.alertMessageText = 'Törlés sikeres.';
                alertMessageState.alertMessageType = 'success';
                reValidator.revalidate();
    
            }else if(response.status === 400){
    
                switch (response.data.code) {
    
                    case 2000:
                        let errorMessages = '';
                        for (const data of response.data.data) {
                            data.errors.forEach((error: string) => {
                                errorMessages += ' ' + error;
                            });
                        }

                        alertMessageState.alertMessageText = 'Hibásan megadott beviteli mezők.' + errorMessages;
    
                }
    
            }else if(response.status === 404){
    
                switch (response.data.code) {
    
                    case 3001:
                        alertMessageState.alertMessageText = 'Törlés sikertelen, valahány törlendő felhasználó nem létezik.';
                        alertMessageState.alertMessageType = 'error';
    
                }
    
            }else{
                alertMessageState.alertMessageText = 'Ismeretlen hiba történt. Kérjük próbálja újra!';
            }
    
            dispatch(showAlertMessage(alertMessageState));
        }
    }

    const columns = [
        {
            title: 'Sorszám',
            dataIndex: 'index',
            sorter: (a: TableDataType, b: TableDataType) => a.index - b.index
        },
        {
            title: 'Név',
            dataIndex: 'name',
            sorter: (a: TableDataType, b: TableDataType) => String(a.name).localeCompare(b.name),
            /*filterDropdown: ({ confirm, setSelectedKeys, selectedKeys }: FilterDropdownProps) => {
                return <Input
                    autoFocus
                    value={selectedKeys[0]}
                    placeholder='Keresés...'
                    onInput={(e: any) => {
                        setSelectedKeys( e.target.value ? [e.target.value] : [] );
                        confirm({ closeDropdown: false });
                    }}
                />;
            },
            filterIcon: () => {
                return <SearchOutlined />;
            },
            onFilter: (value: any, record: TableDataType) => {
                return record.name.toLowerCase().includes(value.toLowerCase());
            }*/
        },
        {
            title: 'Jogosultsági szint',
            render: (record: TableDataType) => {
                switch(record.role) {
                    case RoleEnum.Instructor: return 'oktató';
                    case RoleEnum.Student: return 'tanuló';
                    default: return 'ismeretlen';
                }
            }
        },
        {
            title: 'Státusz',
            render: (record: TableDataType) => {
                if(record.role !== RoleEnum.Student || !record?.courses || record.courses?.length === 0) return '';
  
                let hasValidAnnualMandatoryCourse = false;
                let numberOfAnnualMandatoryCourses = 0;
                for (const course of record.courses as Course[]) {
                    if(course.type === CourseTypeEnum.AnnualMandatory) {
                        numberOfAnnualMandatoryCourses++;

                        if(course.completed) { // ha teljesített
                            const completedDate = new Date(course.completed * 1000);
                            const today = new Date();
                            completedDate.setHours(0, 0, 0, 0);
                            today.setHours(0, 0, 0, 0);

                            completedDate.setFullYear(completedDate.getFullYear() + 1); // érvényesség vége

                            if(today.getTime() <= completedDate.getTime()) { // még nem járt le?
                                hasValidAnnualMandatoryCourse = true;
                                break;
                            }

                        }
                    }
                }

                if(hasValidAnnualMandatoryCourse) return <span className='status valid'></span>;
                // Nincs valid kurzus, de van legalább 1 kötelező éves, ami már lejárt vagy nincs teljesítve
                else if(numberOfAnnualMandatoryCourses > 0) return <span className='status invalid'></span>;
            }
        },
        {
            title: 'Lejárat',
            render: (record: TableDataType) => {
                if(record.role !== RoleEnum.Student || !record?.courses || record.courses?.length === 0) return '';

                let latestCompletedCourse: Course | undefined;
                for (const course of record.courses as Course[]) {
                    if(course.type === CourseTypeEnum.AnnualMandatory) {
                        if(!course.completed) continue;
                        if(!latestCompletedCourse) { latestCompletedCourse = course; continue; }// első eset, amikor a latest-hez még nincs rendelve semmi

                        const currentCourseCompletedDate = new Date(course.completed * 1000);
                        const latestCompletedCourseCompletedDate = new Date(latestCompletedCourse.completed * 1000);

                        if(currentCourseCompletedDate.getTime() > latestCompletedCourseCompletedDate.getTime())
                            latestCompletedCourse = course;
                    }
                }

                if(!latestCompletedCourse) return '';

                const date = new Date(latestCompletedCourse.completed * 1000);
                date.setFullYear(date.getFullYear() + 1);
                date.setHours(0, 0, 0, 0);
    
                const dateMinusTwoMonths = new Date(date.getTime());
                dateMinusTwoMonths.setHours(0, 0, 0, 0);
                dateMinusTwoMonths.setMonth(dateMinusTwoMonths.getMonth() - 2);

                const today = new Date();
                today.setHours(0, 0, 0, 0);

                let expiresInTwoMonths = false;
                // a lejárati dátm két hónapon belüli lejárattal rendelkezik
                if(dateMinusTwoMonths.getTime() <= today.getTime() && today.getTime() <= date.getTime()) expiresInTwoMonths = true

                return <>{`${ formatTimestampToDate(date.getTime() / 1000) }`}{ expiresInTwoMonths && (<strong style={{ color: 'red', fontSize: '18px' }}>!</strong>)}</>;

            },
            sorter: (a: TableDataType, b: TableDataType) => {
                let aLatestCompletedCourseDate = null;
                if(a.role === RoleEnum.Student && a?.courses && a.courses?.length !== 0) {

                    for (const course of a.courses as Course[]) {
                        if(course.type === CourseTypeEnum.AnnualMandatory) {
                            if(!course.completed) continue;
                            if(!aLatestCompletedCourseDate) { aLatestCompletedCourseDate = new Date(course.completed * 1000); continue; }// első eset, amikor a latest-hez még nincs rendelve semmi

                            const currentCourseCompletedDate = new Date(course.completed * 1000);

                            if(currentCourseCompletedDate.getTime() > aLatestCompletedCourseDate.getTime())
                                aLatestCompletedCourseDate = currentCourseCompletedDate;
                        }
                    }

                }

                let bLatestCompletedCourseDate = null;
                if(b.role === RoleEnum.Student && b?.courses && b.courses?.length !== 0) {

                    for (const course of b.courses as Course[]) {
                        if(course.type === CourseTypeEnum.AnnualMandatory) {
                            if(!course.completed) continue;
                            if(!bLatestCompletedCourseDate) { bLatestCompletedCourseDate = new Date(course.completed * 1000); continue; }// első eset, amikor a latest-hez még nincs rendelve semmi

                            const currentCourseCompletedDate = new Date(course.completed * 1000);

                            if(currentCourseCompletedDate.getTime() > bLatestCompletedCourseDate.getTime())
                                bLatestCompletedCourseDate = currentCourseCompletedDate;
                        }
                    }

                }

                if(!aLatestCompletedCourseDate?.getTime() && !bLatestCompletedCourseDate?.getTime()) return 0;
                if(!aLatestCompletedCourseDate?.getTime() && bLatestCompletedCourseDate?.getTime()) return -1;
                if(aLatestCompletedCourseDate?.getTime() && !bLatestCompletedCourseDate?.getTime()) return 1;

                return aLatestCompletedCourseDate!.getTime() - bLatestCompletedCourseDate!.getTime();
            }
        },
        {
            title: 'Adatlap',
            render: (record: TableDataType) => {
                return (
                    <>
                        <Link title='Adatlap' to={`/cms/instructor/users/edit/${ record.key }`}><img src={getIconUrl('user-edit.png')} alt="user edit" /></Link>
                        {/*<DeleleIcon revalidate={reValidator.revalidate} record={record} />*/}
                    </>
                )
            }
        },
        {
            title: 'Kurzusok',
            render: (record: TableDataType) => {
                if(record.role === RoleEnum.Student)
                    return <Link to={`/cms/instructor/users/${ record.key }/courses`}><img src={getIconUrl('courses.png')} alt="courses" /></Link>
            }
        }
    ];
    const [data, setData] = useState<TableDataType[]>([]);

    useEffect(() => {
        const messageShowed = showDefaultErrorMessages(loaderData);
        if(messageShowed) return;

        if(loaderData.status === 200 && loaderData.data && loaderData.data.users) {
            const tableData: TableDataType[] = [];
            for (const [index, user] of loaderData.data.users.entries()) {

                if(user.name.toLowerCase().includes(searchTerm.toLowerCase())) {
                    tableData.push({
                        key: user.id,
                        index: index + 1,
                        name: user.name,
                        role: user.role,
                        courses: user.courses
                    });
                }
    
            }

            setData(tableData);
            return;
        }

        dispatch(clearAlertMessage());
        const alertMessageState: AlertMessageState = {
            alertMessageType: 'error',
            alertMessageText: 'Ismeretlen hiba történt a felhasználók listázása során. Kérjük próbálja újra!'
        };

        dispatch(showAlertMessage(alertMessageState));

    }, [loaderData, dispatch, searchTerm]);

    useEffect(() => {
        dispatch(setSelectedMenuItemKeys(['0']));
        dispatch(setPageTitle('Felhasználók'));
    }, [dispatch]);

    return (
        <>
        
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '5px' }}>

                <a href="" onClick={handleSelectionClick} className='delete-selection-link'>Kijelöltek törlése</a>
                <Link to='/cms/instructor/users/add'><Button type='primary' className='fw-bold'>Új felhasználó Hozzáadás</Button></Link>

            </div>

            <Table
                className='table-responsive instructor-user-list data-table'
                columns={columns}
                dataSource={data}
                bordered
                rowClassName={(record, name) => 'user-table-row'}
                /*onRow={(row, rowIndex) => {
                    return {
                        onClick: (e) => {
                            navigate(`/cms/instructor/users/edit/${row.key}`);
                        }
                    }
                }}*/
                pagination={
                    {
                        position: ['bottomCenter'],
                        pageSize: 10,
                        onChange: () => {
                            setSelectedRowKeys([]);
                        }
                    }
                }
                rowSelection={
                    {
                        type: 'checkbox',
                        preserveSelectedRowKeys: false,
                        onChange: (selectedRowKeys) => {
                            setSelectedRowKeys(selectedRowKeys);
                        },
                        selectedRowKeys
                    }
                }
            />

        </>
    )
}