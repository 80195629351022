import { Input, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { setOpenKeys, setPageTitle, setSelectedMenuItemKeys } from '../../../../../../redux/reducer/antd-layout.reducer';
import { useEffect, useState } from 'react';
import { useLoaderData, useRevalidator } from 'react-router-dom';
import { showDefaultErrorMessages } from '../../../../../../util/show-default-error-message/show-default-error-messages';
import { AlertMessageState, clearAlertMessage, showAlertMessage } from '../../../../../../redux/reducer/alert-message.reducer';
import { SearchOutlined } from '@ant-design/icons';
import { networkCall } from '../../../../../../util/network-call/network-call';
import { FilterDropdownProps } from 'antd/es/table/interface';
import { RootState } from '../../../../../../redux/store';
import { DeleteButton } from '../../../../../button/delete-button/delete-button';
import { RedoButton } from '../../../../../button/redo-button/redo-button';

type TableDataType = {
    key: React.Key;
    name: string;
};

type ActionsColumnProps = {
    id: React.Key;
    revalidate: Function;
};

const ActionsColumn = ({ revalidate, id }: ActionsColumnProps) => {

    const dispatch = useDispatch();

    const handleDeleteCLick = async (e: React.MouseEvent) => {
        e.stopPropagation();

        if(window.confirm('Biztos törölni szeretnéd? Ha törlöd, akkor minden hozzárendelt felhasználó, oktatás, kurzus törlődni fog a rendszerből.')) {

            dispatch(clearAlertMessage());
            dispatch(showAlertMessage({
                alertMessageType: 'loading',
                alertMessageText: 'Kérés folyamatban...'
            }));

            const response = await networkCall(`/companies/${ id }/hard-delete`, {
                method: 'DELETE'
            });;

            const messageShowed = showDefaultErrorMessages(response);
            if(messageShowed) return;

            if(response.status === 204) {
                revalidate();
                dispatch(showAlertMessage({
                    alertMessageType: 'success',
                    alertMessageText: 'Törlés sikeres.'
                }));
                return;
            }

            dispatch(clearAlertMessage());
            const alertMessageState: AlertMessageState = {
                alertMessageType: 'error',
                alertMessageText: 'Ismeretlen hiba történt. Kérjük próbálja újra!'
            };

            if(response.status === 404) {
                alertMessageState.alertMessageText = 'Törlés sikertelen, a törlendő cég nem létezik vagy még aktív(nincs törölve).';
            }

            dispatch(showAlertMessage(alertMessageState));

        }

    }

    const handleRedoclick = async (e: React.MouseEvent) => {
        e.stopPropagation();

        if(window.confirm('Biztosan visszaállítod a céget?')) {

            dispatch(clearAlertMessage());
            dispatch(showAlertMessage({
                alertMessageType: 'loading',
                alertMessageText: 'Kérés folyamatban...'
            }));

            const response = await networkCall(`/companies/${ id }/restore`, {
                method: 'POST'
            });;

            const messageShowed = showDefaultErrorMessages(response);
            if(messageShowed) return;

            if(response.status === 200) {
                revalidate();
                dispatch(showAlertMessage({
                    alertMessageType: 'success',
                    alertMessageText: 'Visszaállítás sikeres.'
                }));
                return;
            }

            dispatch(clearAlertMessage());
            const alertMessageState: AlertMessageState = {
                alertMessageType: 'error',
                alertMessageText: 'Ismeretlen hiba történt. Kérjük próbálja újra!'
            };

            if(response.status === 404) {
                alertMessageState.alertMessageText = 'Visszaállítás sikertelen, a cég nem létezik vagy már vissza van állítva.';
            }

            dispatch(showAlertMessage(alertMessageState));
        }
    };

    return (
        <>
            <DeleteButton onClick={handleDeleteCLick} />
            &nbsp;&nbsp;
            <RedoButton onClick={handleRedoclick} />
        </>
    );
}

export const AdminDeletedCompanyList = () => {

    const dispatch = useDispatch();
    const loaderData: any = useLoaderData();
    const reValidator = useRevalidator();
    const { searchTerm, siderCollapsed } = useSelector((state: RootState) => state.antdLayoutState);
    
    const columns = [
        {
            title: 'Név',
            dataIndex: 'name',
            sorter: (a: TableDataType, b: TableDataType) => String(a.name).localeCompare(b.name),
            /*filterDropdown: ({ confirm, setSelectedKeys, selectedKeys }: FilterDropdownProps) => {
                return <Input
                    autoFocus
                    value={selectedKeys[0]}
                    placeholder='Keresés...'
                    onInput={(e: any) => {
                        setSelectedKeys( e.target.value ? [e.target.value] : [] );
                        confirm({ closeDropdown: false });
                    }}
                />;
            },
            filterIcon: () => {
                return <SearchOutlined />;
            },
            onFilter: (value: any, record: TableDataType) => {
                return record.name.toLowerCase().includes(value.toLowerCase());
            }*/
        },
        {
            title: 'Műveletek',
            render: (record: TableDataType) => {
                return <ActionsColumn revalidate={reValidator.revalidate} id={record.key} />;
            }
        }
    ];
    const [data, setData] = useState<TableDataType[]>([]);

    useEffect(() => {
        const messageShowed = showDefaultErrorMessages(loaderData);
        if(messageShowed) return;

        if(loaderData.getCompaniesResponse?.status === 200 && loaderData.getCompaniesResponse?.data?.companies) {
            const tableData: TableDataType[] = [];
            for (const company of loaderData.getCompaniesResponse.data.companies) {

                if(company.name.toLowerCase().includes(searchTerm.toLowerCase())) {
                    tableData.push({
                        key: company.id,
                        name: company.name
                    });
                }
    
            }

            setData(tableData);
            return;
        }

        dispatch(clearAlertMessage());
        const alertMessageState: AlertMessageState = {
            alertMessageType: 'error',
            alertMessageText: 'Ismeretlen hiba történt a cégek listázása során. Kérjük próbálja újra!'
        };

        dispatch(showAlertMessage(alertMessageState));

    }, [loaderData, searchTerm, dispatch]);

    useEffect(() => {
        dispatch(setSelectedMenuItemKeys(['deleted-companies']));
        if(!siderCollapsed) dispatch(setOpenKeys(['companies']));
        dispatch(setPageTitle('Törölt cégek'));
    }, [dispatch, siderCollapsed]);

    return (
        <Table
            className='table-responsive data-table'
            columns={columns}
            dataSource={data}
            bordered
            pagination={{ position: ['bottomCenter'], pageSize: 10}}
            rowClassName={(record, name) => 'admin-deleted-company-table-row'}
        />
    )
}