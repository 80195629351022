import { useLoaderData, useNavigate } from 'react-router-dom';
import { Form, Input, Button } from 'antd';
import { networkCall } from '../../../util/network-call/network-call';
import { useDispatch } from 'react-redux';
import { RoleEnum } from '../../../constant/enum/role.enum';
import { AlertMessageState, clearAlertMessage, showAlertMessage } from '../../../redux/reducer/alert-message.reducer';
import { tranformAPIValidationErrorToAntDValidationMessage } from '../../../util/validation/transform-api-validation-errors-to-antd-validation-messsages';
import { login } from '../../../redux/reducer/session.reducer';
import { showDefaultErrorMessages } from '../../../util/show-default-error-message/show-default-error-messages';

export const FirstLoginPasswordChange = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const loaderData: any = useLoaderData();
    const [form] = Form.useForm();

    const onFinish = async (values: any) => {

        if(values.password !== values.passwordAgain){

            form.setFields([{
                    name: 'password',
                    errors: ['A jelszavak nem egyeznek meg.']
                },
                {
                    name: 'passwordAgain',
                    errors: ['A jelszavak nem egyeznek meg.']
                }
            ])

            return;
        }

        dispatch(clearAlertMessage());
        dispatch(showAlertMessage({
            alertMessageType: 'loading',
            alertMessageText: 'Kérés folyamatban...'
        }));

        const response = await networkCall('/users/first-login-password-change', {
            method: 'PUT',
            body: {
                password: values.password,
                firstLoginToken: loaderData.token
            }
        });

        const messageShowed = showDefaultErrorMessages(response);

        if(messageShowed) return;

        dispatch(clearAlertMessage());
        const alertMessageState: AlertMessageState = {
            alertMessageType: 'error',
            alertMessageText: 'Ismeretlen hiba történt. Kérjük próbálja újra!'
        };

        if (response.status === 200 && response?.data?.user) {

            if(response.data.user?.role){

                dispatch(login({
                    user: response.data.user,
                    token: response.data.accessToken
                }));

                switch((response.data.user.role as string).toLowerCase()) {
                    case RoleEnum.Admin:
                        navigate('/cms/admin');
                        break;
                    case RoleEnum.CompanyAdmin:
                        navigate('/cms/company-admin');
                        break;
                    case RoleEnum.Instructor:
                        navigate('/cms/instructor');
                        break;
                    case RoleEnum.Student:
                        navigate('/cms/student');
                        break;
                    default:
                        alertMessageState.alertMessageText = 'Hibás email cím vagy jelszó.';

                }

                return;
            }

        }else if(response.status === 400){

            switch (response.data.code) {

                case 2000:
                    alertMessageState.alertMessageText = 'Hibásan megadott beviteli mezők.';
                    const validationErrors = tranformAPIValidationErrorToAntDValidationMessage(response.data?.data);
                    form.setFields(validationErrors);
                    break;

            }

        }else if(response.status === 404){

            switch (response.data.code) {

                case 3001:
                    alertMessageState.alertMessageText = 'Már megtörtént az első bejelentkezés, jelszavát ezen a módon nem változtathatja meg.';
                    navigate('/cms/login');
            }


        }else{
            alertMessageState.alertMessageText = 'Ismeretlen hiba történt. Kérjük próbálja újra!';
        }

        dispatch(showAlertMessage(alertMessageState));

    }

    return (
        <div className='box'>

            <div className='text-center title'>Első bejelentkezés - jelszó változtatás</div>

            <div className='body'>
                
                <Form
                    form={form}
                    onFinish={onFinish}
                >
                        
                        <Form.Item
                        label='Új jelszó'
                        name='password'
                        rules={[
                            {required: true, message: 'A mező megadása kötelező.'}
                        ]}
                        
                    >
                        <Input.Password placeholder='jelszó'/>
                    </Form.Item>

                    <Form.Item
                        label='Új jelszó újra'
                        name='passwordAgain'
                        rules={[
                            {required: true, message: 'A mező megadása kötelező.'}
                        ]}
                        
                    >
                        <Input.Password placeholder='jelszó újra'/>
                    </Form.Item>

                    <Form.Item className='text-center'>
                        <Button type="primary" htmlType="submit">
                            Mentés
                        </Button>
                    </Form.Item>
                        
                </Form>

            </div>

        </div>
    )
}