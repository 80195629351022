import { Button, Table } from 'antd';
import './admin-module-list.css';
import { useDispatch, useSelector } from 'react-redux';
import { setPageTitle, setSelectedMenuItemKeys } from '../../../../../redux/reducer/antd-layout.reducer';
import { useEffect, useState } from 'react';
import { Link, useLoaderData, useNavigate, useRevalidator } from 'react-router-dom';
import { showDefaultErrorMessages } from '../../../../../util/show-default-error-message/show-default-error-messages';
import { AlertMessageState, clearAlertMessage, showAlertMessage } from '../../../../../redux/reducer/alert-message.reducer';
import { networkCall } from '../../../../../util/network-call/network-call';
import { RootState } from '../../../../../redux/store';
import { DeleteButton } from '../../../../button/delete-button/delete-button';

type TableDataType = {
    key: React.Key,
    name: string;
    uniqueId: string;
}

export const AdminModuleList = () => {

    const dispatch = useDispatch();
    const loaderData: any = useLoaderData();
    const navigate = useNavigate();
    const revalidator = useRevalidator();
    const { searchTerm } = useSelector((state: RootState) => state.antdLayoutState);

    const handleDeleteClick = async (e: React.MouseEvent, id: React.Key) => {
        e.stopPropagation();

        if(window.confirm('Biztosan törlöd a modult?')) {

            const response = await networkCall(`/modules/${ id }`, {
                method: 'DELETE'
            });;

            const messageShowed = showDefaultErrorMessages(response);
            if(messageShowed) return;

            if(response.status === 204) {
                revalidator.revalidate();
                dispatch(showAlertMessage({
                    alertMessageType: 'success',
                    alertMessageText: 'Törlés sikeres.'
                }));
                return;
            }

            dispatch(clearAlertMessage());
            const alertMessageState: AlertMessageState = {
                alertMessageType: 'error',
                alertMessageText: 'Ismeretlen hiba történt. Kérjük próbálja újra!'
            };

            if(response.status === 404) {
                alertMessageState.alertMessageText = 'Törlés sikertelen, a törlendő module nem létezik.';
            }

            dispatch(showAlertMessage(alertMessageState));

        }

    }
    
    const columns = [
        {
            title: 'Név',
            dataIndex: 'name',
            sorter: (a: TableDataType, b: TableDataType) => String(a.name).localeCompare(b.name)
        },
        {
            title: 'Egyedi azonosító',
            dataIndex: 'uniqueId'
        },
        {
            title: 'Törlés',
            render: (record: TableDataType) => {
                // alapértelmezett modul nem törölhető
                if(['holofire'].includes(record.uniqueId)) return '';
                return <DeleteButton onClick={(e) => handleDeleteClick(e, record.key)} />;
            }
        }
    ];
    const [data, setData] = useState<TableDataType[]>([]);

    useEffect(() => {
        const messageShowed = showDefaultErrorMessages(loaderData);
        if(messageShowed) return;

        if(loaderData.status === 200 && loaderData.data && loaderData.data.modules) {
            const tableData: TableDataType[] = [];
            for (const module of loaderData.data.modules) {

                if(module.name.toLowerCase().includes(searchTerm.toLowerCase())) {
                    tableData.push({
                        key: module.id,
                        name: module.name,
                        uniqueId: module.uniqueId
                    });
                }
            }

            setData(tableData);
            return;
        }

        dispatch(clearAlertMessage());
        const alertMessageState: AlertMessageState = {
            alertMessageType: 'error',
            alertMessageText: 'Ismeretlen hiba történt a modulok listázása során. Kérjük próbálja újra!'
        };

        dispatch(showAlertMessage(alertMessageState));

    }, [loaderData, dispatch, searchTerm]);

    useEffect(() => {
        dispatch(setSelectedMenuItemKeys(['3']));
        dispatch(setPageTitle('Modulok'));
    }, [dispatch]);

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'flex-end', paddingBottom: '5px' }}>

                <Link to='/cms/admin/modules/add'><Button type='primary' className='fw-bold'>Új modul hozzáadása</Button></Link>

            </div>

            <Table
                className='table-responsive data-table'
                columns={columns}
                dataSource={data}
                bordered
                rowClassName={(record, name) => 'module-table-row'}
                onRow={(row, rowIndex) => {
                    return {
                        onClick: (e) => {
                            navigate(`/cms/admin/modules/edit/${row.key}`);
                        }
                    }
                }}
                pagination={{ position: ['bottomCenter'], pageSize: 10}}
            />
        
        </>
    )
}