import { Card } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { setOpenKeys, setPageTitle, setSelectedMenuItemKeys } from '../../../../../redux/reducer/antd-layout.reducer';
import { useEffect } from 'react';
import { Form, Input, Button } from 'antd';
import { networkCall } from '../../../../../util/network-call/network-call';
import { showDefaultErrorMessages } from '../../../../../util/show-default-error-message/show-default-error-messages';
import { AlertMessageState, clearAlertMessage, showAlertMessage } from '../../../../../redux/reducer/alert-message.reducer';
import { tranformAPIValidationErrorToAntDValidationMessage } from '../../../../../util/validation/transform-api-validation-errors-to-antd-validation-messsages';
import { Link, useLoaderData, useNavigate, useRevalidator } from 'react-router-dom';
import './admin-company-edit.css';
import { AdminLicenseList } from '../../license/list/admin-license-list';
import { AdminEducationList } from '../../education/list/admin-education-list';
import { RootState } from '../../../../../redux/store';


export const AdminCompanyEdit = () => {

    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const reValidator = useRevalidator();
    const loaderData: any = useLoaderData();
    const { siderCollapsed } = useSelector((state: RootState) => state.antdLayoutState);

    const onFinish = async (values: any) => {

        dispatch(clearAlertMessage());
        dispatch(showAlertMessage({
            alertMessageType: 'loading',
            alertMessageText: 'Kérés folyamatban...'
        }));

        const response = await networkCall(`/companies/${ loaderData.id }`, {
            method: 'PUT',
            body: {
                name: values.name
            }
        });

        const messageShowed = showDefaultErrorMessages(response);

        if(messageShowed) return;

        dispatch(clearAlertMessage());
        const alertMessageState: AlertMessageState = {
            alertMessageType: 'error',
            alertMessageText: 'Ismeretlen hiba történt. Kérjük próbálja újra!'
        };

        if (response.status === 200 && response.data.company !== undefined) {

            alertMessageState.alertMessageText = 'Mentés sikeres.';
            alertMessageState.alertMessageType = 'success';

        }else if(response.status === 400){

            switch (response.data.code) {

                case 2000:
                    alertMessageState.alertMessageText = 'Hibásan megadott beviteli mezők.';
                    const validationErrors = tranformAPIValidationErrorToAntDValidationMessage(response.data?.data);
                    form.setFields(validationErrors);
                    break;

            }

        } else if(response?.status === 404) {

            switch (response?.data?.code) {

                case 5000:
                    alertMessageState.alertMessageText = 'A cég nem található.';
                    navigate('/cms/admin/companies/list');
                    break;

            }

        }

        dispatch(showAlertMessage(alertMessageState));

    }

    useEffect(() => {

        const messageShowed = showDefaultErrorMessages(loaderData.getCompanyResponse);
        if(messageShowed) {
            navigate('/cms/admin/companies/list');
            return;
        }

        const alertMessageState: AlertMessageState = {
            alertMessageType: 'error',
            alertMessageText: 'Ismeretlen hiba történt a cég lekérése során. Kérjük próbálja újra!'
        };

        if(loaderData.getCompanyResponse?.status === 200 && loaderData.getCompanyResponse?.data?.company) {
            form.setFieldValue('name', loaderData.getCompanyResponse.data.company.name);

            return;
        } else if(loaderData.getCompanyResponse?.status === 404) {

            switch (loaderData.getCompanyResponse?.data?.code) {

                case 5000:
                    alertMessageState.alertMessageText = 'A cég nem található.';
                    navigate('/cms/admin/companies/list');
                    break;

            }

        }

        dispatch(clearAlertMessage());
        dispatch(showAlertMessage(alertMessageState));

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dispatch(setSelectedMenuItemKeys(['active-companies']));
        if(!siderCollapsed) dispatch(setOpenKeys(['companies']));
        dispatch(setPageTitle('Cég módosítása'));
    }, [dispatch, siderCollapsed]);

    return (
        <>
            <Card title='Adatok'>
                
                <Form
                    form={form}
                    onFinish={onFinish}
                    layout='vertical'
                >
        
                    <Form.Item
                        label='Cég neve'
                        name='name'
                        rules={[{required: true, message: 'Név megadása kötelező!'}]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item>

                        <Link to='/cms/admin/active-companies/list'>
                            <Button type="default" htmlType="button">
                                Vissza
                            </Button>
                        </Link>
                        <Button type='primary' htmlType='submit' style={{float: 'right'}}>
                            Mentés
                        </Button>
        
                    </Form.Item>
                            
                </Form>

            </Card>

            <div style={{ marginBottom: '10px' }}></div>

            <AdminLicenseList companyId={loaderData.id} licenses={loaderData.getLicensesResponse?.data?.licenses} reValidate={reValidator.revalidate}/>

            <div style={{ marginBottom: '10px' }}></div>

            <AdminEducationList companyId={loaderData.id} educations={loaderData.getEducationsResponse?.data?.educations} reValidate={reValidator.revalidate}/>

        </>
    )
}