import { Link } from 'react-router-dom';


export const Error = () => {
    return (
        <div className="text-center">
            <h1>Hoppá!</h1>
            <p>Váratlan hiba történt!</p>
            <Link to='/'>Vissza</Link>
        </div>
    )
}