import { LoaderFunctionArgs } from "react-router-dom";
import { networkCall } from "../../../../../util/network-call/network-call";

export const AdminCompanyEditLoader = async (args: LoaderFunctionArgs) => {

    const getCompanyResponse = await networkCall(`/companies/${ args.params.id }`);
    const getLicensesResponse = await networkCall(`/companies/${ args.params.id }/licenses`);
    const getEducationsResponse = await networkCall(`/companies/${ args.params.id }/educations`);
  
    return {
        getCompanyResponse,
        getLicensesResponse,
        getEducationsResponse,
        id: args.params.id
    };

};