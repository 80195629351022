import { createSlice } from "@reduxjs/toolkit";


interface AntdLayoutState {
    siderCollapsed: boolean;
    selectedMenuItemKeys: string[];
    openKeys: string[];
    pageTitle: string;
    searchTerm: string;
}

const initialState: AntdLayoutState = {
    siderCollapsed: false,
    selectedMenuItemKeys: [],
    openKeys: [],
    pageTitle: '',
    searchTerm: ''
}

export const antdLayoutSlice = createSlice({
    name: 'antdLayout',
    initialState,
    reducers: {
        toggleSiderCollapse: (state) => {
            state.siderCollapsed = !state.siderCollapsed;
        },
        setSelectedMenuItemKeys: (state, action) => {
            state.selectedMenuItemKeys = action.payload;
        },
        setOpenKeys: (state, action) => {
            state.openKeys = action.payload;
        },
        setPageTitle: (state, action) => {
            state.pageTitle = action.payload;
        },
        setSearchTerm: (state, action) => {
            state.searchTerm = action.payload;
        }
    }
});

export const {
    toggleSiderCollapse,
    setSelectedMenuItemKeys,
    setOpenKeys,
    setPageTitle,
    setSearchTerm
} = antdLayoutSlice.actions;