import { RedoOutlined } from "@ant-design/icons";
import './redo-button.css';

type RedoButtonProps  = {
    onClick: React.MouseEventHandler<HTMLButtonElement>;
};

export const RedoButton = ({ onClick }: RedoButtonProps) => {
    return (
        <button onClick={onClick} className="redo-btn" title="Visszaállítás">
            <RedoOutlined className='icon' title='Visszaállítás'/>
        </button>
    );
}