import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface AlertMessageState {
    alertMessageText: string | null;
    alertMessageType?: 'success' | 'error' | 'loading';
}

const initialState: AlertMessageState = {
    alertMessageText: null,
    alertMessageType: 'success'
}

export const alertMessageSlice = createSlice({
    name: 'alertMessage',
    initialState,
    reducers: {
        clearAlertMessage: (state) => {
            state.alertMessageText = null;
        },
        showAlertMessage: (state, action: PayloadAction<AlertMessageState>) => {
            state.alertMessageText = action.payload.alertMessageText;
            state.alertMessageType = action.payload.alertMessageType ? action.payload.alertMessageType : 'success';
        }
    }
});

export const {
    clearAlertMessage,
    showAlertMessage
} = alertMessageSlice.actions;