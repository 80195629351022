import { Card, Select } from 'antd';
import { useDispatch } from 'react-redux';
import { setPageTitle, setSelectedMenuItemKeys } from '../../../../../redux/reducer/antd-layout.reducer';
import { useEffect, useState } from 'react';
import { Form, Input, Button } from 'antd';
import { networkCall } from '../../../../../util/network-call/network-call';
import { showDefaultErrorMessages } from '../../../../../util/show-default-error-message/show-default-error-messages';
import { AlertMessageState, clearAlertMessage, showAlertMessage } from '../../../../../redux/reducer/alert-message.reducer';
import { tranformAPIValidationErrorToAntDValidationMessage } from '../../../../../util/validation/transform-api-validation-errors-to-antd-validation-messsages';
import { Link, useLoaderData, useNavigate, useRevalidator } from 'react-router-dom';
import { RoleEnum } from '../../../../../constant/enum/role.enum';


export const AdminUserEdit = () => {

    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const loaderData: any = useLoaderData();
    const navigate = useNavigate();
    const revalidator = useRevalidator();
    const roleOptions = [
        {
            label: 'Céges admin',
            value: RoleEnum.CompanyAdmin
        },
        {
            label: 'Oktató',
            value: RoleEnum.Instructor
        },
        {
            label: 'Tanuló',
            value: RoleEnum.Student
        }
    ];
    const [companies, setCompanies] = useState([]);

    const onFinish = async (values: any) => {

        dispatch(clearAlertMessage());
        dispatch(showAlertMessage({
            alertMessageType: 'loading',
            alertMessageText: 'Kérés folyamatban...'
        }));
        
        const response = await networkCall(`/users/${ loaderData.id }`, {
            method: 'PUT',
            body: {
                name: values.name,
                email: values.email,
                role: values.role,
                companyId: values.companyId
            }
        });

        const messageShowed = showDefaultErrorMessages(response);

        if(messageShowed) return;

        dispatch(clearAlertMessage());
        const alertMessageState: AlertMessageState = {
            alertMessageType: 'error',
            alertMessageText: 'Ismeretlen hiba történt. Kérjük próbálja újra!'
        };

        if (response.status === 200 && response.data.user !== undefined) {

            alertMessageState.alertMessageText = 'Mentés sikeres.';
            alertMessageState.alertMessageType = 'success';

            // Ha változott a role, akkor újrarenderelés a uniqueId miatt
            if(values.role !== loaderData.getUserResponse.data.user.role) revalidator.revalidate();

        }else if(response.status === 400){

            switch (response.data.code) {

                case 2000:
                    alertMessageState.alertMessageText = 'Hibásan megadott beviteli mezők.';
                    const validationErrors = tranformAPIValidationErrorToAntDValidationMessage(response.data?.data);
                    form.setFields(validationErrors);
                    break;
                case 3004:
                    alertMessageState.alertMessageText = 'Nem hozható létre több egyedi azonosító a tanulóknak.';
                    break;

            }

        }else if(response.status === 404){

            switch (response.data.code) {

                case 3001:
                    alertMessageState.alertMessageText = 'A felhasználó nem található.';
                    alertMessageState.alertMessageType = 'error';
                    navigate('/cms/admin/users/list');
                    break;

                case 5000:
                    form.setFields([{
                        name: 'companyId',
                        errors: ['A megadott cég nem létezik.']
                    }]);
                    break;

            }

        }else{
            alertMessageState.alertMessageText = 'Ismeretlen hiba történt. Kérjük próbálja újra!';
        }

        dispatch(showAlertMessage(alertMessageState));

    }

    useEffect(() => {

        if(!loaderData.getCompaniesResponse) return;

        if(loaderData.getCompaniesResponse.status === 200 && loaderData.getCompaniesResponse.data && loaderData.getCompaniesResponse.data.companies) {
            const companyData: any = [];
            for (const company of loaderData.getCompaniesResponse.data.companies) {
                companyData.push({
                    label: company.name,
                    value: company.id
                });
            }

            setCompanies(companyData);
            return;
        }

    }, [loaderData.getCompaniesResponse]);

    useEffect(() => {

        const messageShowed = showDefaultErrorMessages(loaderData.getUserResponse);
        if(messageShowed) return;

        const alertMessageState: AlertMessageState = {
            alertMessageType: 'error',
            alertMessageText: 'Ismeretlen hiba történt a felhasználó lekérése során. Kérjük próbálja újra!'
        };

        if(loaderData.getUserResponse.status === 200 && loaderData.getUserResponse.data && loaderData.getUserResponse.data.user) {
            form.setFieldValue('name', loaderData.getUserResponse.data.user.name);
            form.setFieldValue('email', loaderData.getUserResponse.data.user.email);
            form.setFieldValue('role', loaderData.getUserResponse.data.user.role);
            form.setFieldValue('companyId', loaderData.getUserResponse.data.user.company?.id);
            if(loaderData.getUserResponse.data.user.role === RoleEnum.Student)
                form.setFieldValue('uniqueId', loaderData.getUserResponse.data.user.uniqueId);
            return;
        } else if(loaderData.getUserResponse?.status === 404){

            switch (loaderData.getUserResponse?.data?.code) {

                case 3001:
                    alertMessageState.alertMessageText = 'A felhasználó nem található.';
                    alertMessageState.alertMessageType = 'error';
                    navigate('/cms/admin/users/list');
                    break;
            }

        }

        dispatch(clearAlertMessage());
        dispatch(showAlertMessage(alertMessageState));

    }, [loaderData.getUserResponse, dispatch, form, navigate]);

    useEffect(() => {
        dispatch(setSelectedMenuItemKeys(['0']));
        dispatch(setPageTitle('Felhasználó módosítása'));
    }, [dispatch]);

    return (
        <Card>
            
            <Form
                form={form}
                onFinish={onFinish}
                layout='vertical'
            >
    
                <Form.Item
                    label='Név'
                    name='name'
                    rules={[{required: true, message: 'Név megadása kötelező!'}]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label='Email cím'
                    name='email'
                    rules={[{required: true, message: 'Email cím megadása kötelező!'}, {type: 'email', message: 'Helytelen email formátum!'}]}
                >
                    <Input />
                </Form.Item>

                {
                    loaderData.getUserResponse?.data?.user?.role === RoleEnum.Student && (
                        <Form.Item
                            label='Egyedi azonosító'
                            name='uniqueId'
                        >
                            <Input disabled style={{fontWeight: 'bold'}}/>
                        </Form.Item>
                    )
                }
                

                <Form.Item
                    label='Cég'
                    name='companyId'
                    rules={[{required: true, message: 'Cég megadása kötelező!'}]}
                >
                    <Select
                        showSearch
                        placeholder="Válasszon céget!"
                        options={companies}
                    />
                </Form.Item>

                <Form.Item
                    label='Jogosultsági szint'
                    name='role'
                    rules={[{required: true, message: 'Jogosultsági szint megadása kötelező!'}]}
                >
                    <Select
                        showSearch
                        placeholder="Válasszon jogosultsági szintet!"
                        options={roleOptions}
                    />
                </Form.Item>

                <Form.Item>
                    <Link to='/cms/admin/users/list'>
                        <Button type="default" htmlType="button">
                            Vissza
                        </Button>
                    </Link>
                    <Button type='primary' htmlType='submit' style={{float: 'right'}}>
                        Mentés
                    </Button>
                </Form.Item>
                        
            </Form>

        </Card>
    )
}