import {
  configureStore
} from "@reduxjs/toolkit";
import { alertMessageSlice } from "./reducer/alert-message.reducer";
import { sessionSlice } from "./reducer/session.reducer";
import { antdLayoutSlice } from "./reducer/antd-layout.reducer";

export const store = configureStore({
  reducer: {
    alertMessage: alertMessageSlice.reducer,
    session: sessionSlice.reducer,
    antdLayoutState: antdLayoutSlice.reducer
  }
});

export type RootState = ReturnType<typeof store.getState>;