import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { RootState } from "../../../redux/store";
import { RoleEnum } from "../../../constant/enum/role.enum";
import { showAlertMessage } from "../../../redux/reducer/alert-message.reducer";
import { PropsWithChildren } from "react";

type RequireAuthProps = PropsWithChildren & {
  role: RoleEnum[];
}

export const RequireAuth = ({ children, role }: RequireAuthProps) => {
  const session = useSelector(
    (state: RootState) => state.session
  );
  const dispatch = useDispatch();

  if (session?.user?.role && role.includes(session.user.role)) return <>{children}</>;

  dispatch(showAlertMessage({
    alertMessageText: 'A funkció eléréséhez be kell jelentkeznie.',
    alertMessageType: 'error'
  }));

  return <Navigate to={'/cms/login'} />;
};
