import { Card } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { setOpenKeys, setPageTitle, setSelectedMenuItemKeys } from '../../../../../redux/reducer/antd-layout.reducer';
import { useEffect } from 'react';
import { Form, Input, Button } from 'antd';
import { networkCall } from '../../../../../util/network-call/network-call';
import { showDefaultErrorMessages } from '../../../../../util/show-default-error-message/show-default-error-messages';
import { AlertMessageState, clearAlertMessage, showAlertMessage } from '../../../../../redux/reducer/alert-message.reducer';
import { tranformAPIValidationErrorToAntDValidationMessage } from '../../../../../util/validation/transform-api-validation-errors-to-antd-validation-messsages';
import { Link, useNavigate } from 'react-router-dom';
import { RootState } from '../../../../../redux/store';


export const AdminCompanyAdd = () => {

    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const { siderCollapsed } = useSelector((state: RootState) => state.antdLayoutState);

    const onFinish = async (values: any) => {

        dispatch(clearAlertMessage());
        dispatch(showAlertMessage({
            alertMessageType: 'loading',
            alertMessageText: 'Kérés folyamatban...'
        }));

        const response = await networkCall('/companies', {
            method: 'POST',
            body: {
                name: values.name
            }
        });

        const messageShowed = showDefaultErrorMessages(response);

        if(messageShowed) return;

        dispatch(clearAlertMessage());
        const alertMessageState: AlertMessageState = {
            alertMessageType: 'error',
            alertMessageText: 'Ismeretlen hiba történt. Kérjük próbálja újra!'
        };

        if (response.status === 201 && response.data.company !== undefined) {

            alertMessageState.alertMessageText = 'Mentés sikeres.';
            alertMessageState.alertMessageType = 'success';
            navigate(`/cms/admin/companies/edit/${ response.data.company.id }`);

        }else if(response.status === 400){

            switch (response.data.code) {

                case 2000:
                    alertMessageState.alertMessageText = 'Hibásan megadott beviteli mezők.';
                    const validationErrors = tranformAPIValidationErrorToAntDValidationMessage(response.data?.data);
                    form.setFields(validationErrors);
                    break;

            }

        }else{
            alertMessageState.alertMessageText = 'Ismeretlen hiba történt. Kérjük próbálja újra!';
        }

        dispatch(showAlertMessage(alertMessageState));

    }

    useEffect(() => {
        dispatch(setSelectedMenuItemKeys(['active-companies']));
        if(!siderCollapsed) dispatch(setOpenKeys(['companies']));
        dispatch(setPageTitle('Új cég hozzáadása'));
    }, [dispatch, siderCollapsed]);

    return (
        <Card>
            
            <Form
                form={form}
                onFinish={onFinish}
                layout='vertical'
            >
    
                <Form.Item
                    label='Cég neve'
                    name='name'
                    rules={[{required: true, message: 'Név megadása kötelező!'}]}
                >
                    <Input />
                </Form.Item>

                <Form.Item>
                    <Link to='/cms/admin/active-companies/list'>
                        <Button type="default" htmlType="button">
                            Vissza
                        </Button>
                    </Link>
                    <Button type='primary' htmlType='submit' style={{float: 'right'}}>
                        Mentés
                    </Button>
                </Form.Item>
                        
            </Form>

        </Card>
    )
}