import { Card } from 'antd';
import { useDispatch } from 'react-redux';
import { setPageTitle, setSelectedMenuItemKeys } from '../../../../../redux/reducer/antd-layout.reducer';
import { useEffect } from 'react';
import { Form, Input, Button } from 'antd';
import { networkCall } from '../../../../../util/network-call/network-call';
import { showDefaultErrorMessages } from '../../../../../util/show-default-error-message/show-default-error-messages';
import { AlertMessageState, clearAlertMessage, showAlertMessage } from '../../../../../redux/reducer/alert-message.reducer';
import { tranformAPIValidationErrorToAntDValidationMessage } from '../../../../../util/validation/transform-api-validation-errors-to-antd-validation-messsages';
import { Link, useNavigate } from 'react-router-dom';
import { RoleEnum } from '../../../../../constant/enum/role.enum';


export const InstructorUserAdd = () => {

    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const navigate = useNavigate();

    const onFinish = async (values: any) => {

        dispatch(clearAlertMessage());
        dispatch(showAlertMessage({
            alertMessageType: 'loading',
            alertMessageText: 'Kérés folyamatban...'
        }));

        const response = await networkCall('/users', {
            method: 'POST',
            body: {
                name: values.name,
                email: values.email,
                role: RoleEnum.Student
            }
        });

        const messageShowed = showDefaultErrorMessages(response);

        if(messageShowed) return;

        dispatch(clearAlertMessage());
        const alertMessageState: AlertMessageState = {
            alertMessageType: 'error',
            alertMessageText: 'Ismeretlen hiba történt. Kérjük próbálja újra!'
        };

        if (response.status === 201 && response.data.user !== undefined) {

            alertMessageState.alertMessageText = 'Mentés sikeres.';
            alertMessageState.alertMessageType = 'success';
            navigate('/cms/instructor/users/list');

        }else if(response.status === 400){

            switch (response.data.code) {

                case 2000:
                    alertMessageState.alertMessageText = 'Hibásan megadott beviteli mezők.';
                    const validationErrors = tranformAPIValidationErrorToAntDValidationMessage(response.data?.data);
                    form.setFields(validationErrors);
                    break;
                case 3004:
                    alertMessageState.alertMessageText = 'Nem hozható létre több egyedi azonosító a tanulóknak.';
                    break;

            }

        }else{
            alertMessageState.alertMessageText = 'Ismeretlen hiba történt. Kérjük próbálja újra!';
        }

        dispatch(showAlertMessage(alertMessageState));

    }

    useEffect(() => {
        dispatch(setSelectedMenuItemKeys(['0']));
        dispatch(setPageTitle('Új felhasználó hozzáadása'));
    }, [dispatch]);

    return (
        <Card>
            
            <Form
                form={form}
                onFinish={onFinish}
                layout='vertical'
            >
    
                <Form.Item
                    label='Név'
                    name='name'
                    rules={[{required: true, message: 'Név megadása kötelező!'}]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label='Email cím'
                    name='email'
                    rules={[{required: true, message: 'Email cím megadása kötelező!'}, {type: 'email', message: 'Helytelen email formátum!'}]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label='Jogosultsági szint'
                    name='role'
                    initialValue='Tanuló'
                >
                    <Input disabled style={{ fontWeight: 'bolder' }}/>
                </Form.Item>

                <Form.Item>
                    <Link to='/cms/instructor/users/list'>
                        <Button type="default" htmlType="button">
                            Vissza
                        </Button>
                    </Link>
                    <Button type='primary' htmlType='submit' style={{float: 'right'}}>
                        Mentés
                    </Button>
                </Form.Item>
                        
            </Form>

        </Card>
    )
}