import { Button, Input, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Link, useLoaderData, useRevalidator } from 'react-router-dom';
import { showDefaultErrorMessages } from '../../../../../util/show-default-error-message/show-default-error-messages';
import { AlertMessageState, clearAlertMessage, showAlertMessage } from '../../../../../redux/reducer/alert-message.reducer';
import { SearchOutlined } from '@ant-design/icons';
import { networkCall } from '../../../../../util/network-call/network-call';
import { setPageTitle, setSelectedMenuItemKeys } from '../../../../../redux/reducer/antd-layout.reducer';
import { formatTimestampToDatetime } from '../../../../../util/date/format-timestamp-to-datetime';
import { FilterDropdownProps } from 'antd/es/table/interface';
import { RootState } from '../../../../../redux/store';

type TableDataType = {
    key: React.Key;
    name: string;
    created: number;
    index: number;
}

export const CompanyAdminEducationList = () => {
    const reValidator = useRevalidator();
    const loaderData: any = useLoaderData();
    const dispatch = useDispatch();
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const { searchTerm } = useSelector((state: RootState) => state.antdLayoutState);

    const handleSDeleteSelectionLinkClick = async (e: React.MouseEvent) => {
        e.preventDefault();

        if(selectedRowKeys.length === 0) {
            alert('Nincs kijelölt elem.');
            return;
        }

        if (window.confirm('Biztosan törlöd a kijelölt elemeket? Az adatok el fognak veszni.')) {

            dispatch(clearAlertMessage());
            dispatch(showAlertMessage({
                alertMessageType: 'loading',
                alertMessageText: 'Kérés folyamatban...'
            }));
            
            const response = await networkCall('/educations/bulk-delete', {
                method: 'POST',
                body: {
                    ids: selectedRowKeys
                }
            });
    
            const messageShowed = showDefaultErrorMessages(response);
    
            if(messageShowed) return;
    
            dispatch(clearAlertMessage());
            const alertMessageState: AlertMessageState = {
                alertMessageType: 'error',
                alertMessageText: 'Ismeretlen hiba történt. Kérjük próbálja újra!'
            };
    
            if (response.status === 204) {
    
                alertMessageState.alertMessageText = 'Törlés sikeres.';
                alertMessageState.alertMessageType = 'success';
                reValidator.revalidate();
    
            }else if(response.status === 400){
    
                switch (response.data.code) {
    
                    case 2000:
                        let errorMessages = '';
                        for (const data of response.data.data) {
                            data.errors.forEach((error: string) => {
                                errorMessages += ' ' + error;
                            });
                        }

                        alertMessageState.alertMessageText = 'Hibásan megadott beviteli mezők.' + errorMessages;
    
                }
    
            }else if(response.status === 404){
    
                switch (response.data.code) {
    
                    case 3001:
                        alertMessageState.alertMessageText = 'Törlés sikertelen, valahány törlendő oktatás nem létezik.';
                        alertMessageState.alertMessageType = 'error';
    
                }
    
            }else{
                alertMessageState.alertMessageText = 'Ismeretlen hiba történt. Kérjük próbálja újra!';
            }
    
            dispatch(showAlertMessage(alertMessageState));
        }
    }
    
    const columns = [
        {
            title: 'Sorszám',
            dataIndex: 'index',
            sorter: (a: TableDataType, b: TableDataType) => a.index - b.index
        },
        {
            title: 'Oktatás neve',
            dataIndex: 'name',
            sorter: (a: TableDataType, b: TableDataType) => String(a.name).localeCompare(b.name),
            /*filterDropdown: ({ confirm, setSelectedKeys, selectedKeys }: FilterDropdownProps) => {
                return <Input
                    autoFocus
                    value={selectedKeys[0]}
                    placeholder='Keresés...'
                    onInput={(e: any) => {
                        setSelectedKeys( e.target.value ? [e.target.value] : [] );
                        confirm({ closeDropdown: false });
                    }}
                />;
            },
            filterIcon: () => {
                return <SearchOutlined />;
            },
            onFilter: (value: any, record: TableDataType) => {
                return record.name.toLowerCase().includes(value.toLowerCase());
            }*/
        },
        {
            title: 'Létrehozva',
            sorter: (a: TableDataType, b: TableDataType) => a.created - b.created,
            render: (record: TableDataType) => {
                return formatTimestampToDatetime(record.created);
            }
        },
        {
            title: 'Szerkesztés',
            render: (record: TableDataType) => {
                return <Link to={`/cms/company-admin/educations/edit/${record.key}`}><Button type='primary'>Szerkesztés</Button></Link>;
            }
        }
    ];
    const [data, setData] = useState<TableDataType[]>([]);

    useEffect(() => {
        const messageShowed = showDefaultErrorMessages(loaderData.getEducationsResponse);
        if(messageShowed) return;

        if(loaderData.getEducationsResponse.status === 200 && loaderData.getEducationsResponse.data && loaderData.getEducationsResponse.data.educations) {
            const tableData: TableDataType[] = [];
            for (const [index, education] of loaderData.getEducationsResponse.data.educations.entries()) {
                
                if(education.name.toLowerCase().includes(searchTerm.toLowerCase())) {
                    tableData.push({
                        key: education.id,
                        name: education.name,
                        created: education.created,
                        index: index + 1
                    });
                }

            }

            setData(tableData);
            return;
        }

        dispatch(clearAlertMessage());
        const alertMessageState: AlertMessageState = {
            alertMessageType: 'error',
            alertMessageText: 'Ismeretlen hiba történt az oktatások listázása során. Kérjük próbálja újra!'
        };

        dispatch(showAlertMessage(alertMessageState));

    }, [loaderData.getEducationsResponse, dispatch, searchTerm]);

    useEffect(() => {
        dispatch(setSelectedMenuItemKeys(['4']));
        dispatch(setPageTitle('Oktatások'));
    }, [dispatch]);

    return (
        <>

            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '5px' }}>

                <a href="" onClick={handleSDeleteSelectionLinkClick} className='delete-selection-link'>Kijelöltek törlése</a>
                <Link to={'/cms/company-admin/educations/add'}><Button type='primary' className='fw-bold'>Új oktatás hozzáadása</Button></Link>

            </div>

            <Table
                className='table-responsive data-table'
                columns={columns}
                dataSource={data}
                bordered
                pagination={
                    {
                        position: ['bottomCenter'],
                        pageSize: 10,
                        onChange: () => {
                            setSelectedRowKeys([]);
                        }
                    }
                }
                rowSelection={
                    {
                        type: 'checkbox',
                        preserveSelectedRowKeys: false,
                        onChange: (selectedRowKeys) => {
                            setSelectedRowKeys(selectedRowKeys);
                        },
                        selectedRowKeys
                    }
                }
            />

        </>
    )
}