import { DeleteOutlined } from "@ant-design/icons";
import './delete-button.css';

type DeleteButtonProps  = {
    onClick: React.MouseEventHandler<HTMLButtonElement>;
};

export const DeleteButton = ({ onClick }: DeleteButtonProps) => {
    return (
        <button onClick={onClick} className="delete-btn" title="Törlés">
            <DeleteOutlined className='icon'/>
        </button>
    );
}