/**
 * 
 * @param timestamp UNIX timestamp (seconds)
 * @returns YYYY-MM-DD
 */
export const formatTimestampToDate = (timestamp: number): string => {
    const d = new Date(timestamp* 1000);

    if(!d) return '';

    const fullYear = d.getFullYear().toString();
    const month = (d.getMonth() + 1).toString().padStart(2, '0');
    const date = (d.getDate()).toString().padStart(2, '0');

    return `${ fullYear }.${ month }.${ date }.`;
}