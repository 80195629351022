import { FieldData } from "../../constant/type/field-data.type";

export const tranformAPIValidationErrorToAntDValidationMessage = (validationErrors: any) => {

    const antDValidationMessages: FieldData[] = [];

    if (Array.isArray(validationErrors)) {

        validationErrors.forEach(element => {
            if (element.field && element.errors !== undefined) {
                const errors: string[] = [];

                if (Array.isArray(element.errors)) {

                    element.errors.forEach( (error: string) => {
                        errors.push(error);
                    });

                    antDValidationMessages.push({
                        name: element.field,
                        errors
                    });

                }else if(typeof element.errors !== 'object'){

                    antDValidationMessages.push({
                        name: element.field,
                        errors: element.errors
                    });

                } else {

                    antDValidationMessages.push({
                        name: element.field,
                        errors: [JSON.stringify(element.errors)]
                    });

                }

            }
        });

    }

    return antDValidationMessages;

}