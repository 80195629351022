import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { Form, Input, Button } from 'antd';
import { useLoaderData } from 'react-router-dom';
import { networkCall } from '../../../util/network-call/network-call';
import { showDefaultErrorMessages } from '../../../util/show-default-error-message/show-default-error-messages';
import { AlertMessageState, clearAlertMessage, showAlertMessage } from '../../../redux/reducer/alert-message.reducer';
import { RoleEnum } from '../../../constant/enum/role.enum';
import { tranformAPIValidationErrorToAntDValidationMessage } from '../../../util/validation/transform-api-validation-errors-to-antd-validation-messsages';
import { setOpenKeys, setPageTitle, setSelectedMenuItemKeys } from '../../../redux/reducer/antd-layout.reducer';
import './profile.css';


export const Profile = () => {

    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const loaderData: any = useLoaderData();
    const [showChangePasswordFields, setShowChangePasswordFields] = useState(false);

    const onFinish = async (values: any) => {

        let oldPassword: string | undefined = undefined;
        let newPassword: string | undefined = undefined;

        if(showChangePasswordFields) {
            if(values.newPassword !== values.newPasswordAgain){

                form.setFields([{
                        name: 'newPassword',
                        errors: ['A jelszavak nem egyeznek meg.']
                    },
                    {
                        name: 'newPasswordAgain',
                        errors: ['A jelszavak nem egyeznek meg.']
                    }
                ]);
    
                return;
            }

            oldPassword = values.oldPassword;
            newPassword = values.newPassword;
        }

        dispatch(clearAlertMessage());
        dispatch(showAlertMessage({
            alertMessageType: 'loading',
            alertMessageText: 'Kérés folyamatban...'
        }));

        const response = await networkCall(`/users/me`, {
            method: 'PUT',
            body: {
                name: values.name,
                email: values.email,
                oldPassword,
                newPassword
            }
        });

        const messageShowed = showDefaultErrorMessages(response);

        if(messageShowed) return;

        dispatch(clearAlertMessage());
        const alertMessageState: AlertMessageState = {
            alertMessageType: 'error',
            alertMessageText: 'Ismeretlen hiba történt. Kérjük próbálja újra!'
        };

        if (response.status === 200 && response.data.user !== undefined) {

            alertMessageState.alertMessageText = 'Mentés sikeres.';
            alertMessageState.alertMessageType = 'success';
            form.setFields([
                {
                    name: 'oldPassword',
                    value: ''
                },
                {
                    name: 'newPassword',
                    value: ''
                },
                {
                    name: 'newPasswordAgain',
                    value: ''
                }
            ]);
            setShowChangePasswordFields(false);

        }else if(response.status === 400){

            switch (response.data.code) {

                case 2000:
                    alertMessageState.alertMessageText = 'Hibásan megadott beviteli mezők.';
                    const validationErrors = tranformAPIValidationErrorToAntDValidationMessage(response.data?.data);
                    form.setFields(validationErrors);
                    break;

            }

        }else{
            alertMessageState.alertMessageText = 'Ismeretlen hiba történt. Kérjük próbálja újra!';
        }

        dispatch(showAlertMessage(alertMessageState));

    }

    useEffect(() => {
        const messageShowed = showDefaultErrorMessages(loaderData.response);
        if(messageShowed) return;

        if(loaderData.response.status === 200 && loaderData.response.data && loaderData.response.data.user) {
            form.setFieldValue('name', loaderData.response.data.user.name);
            form.setFieldValue('email', loaderData.response.data.user.email);
            if(loaderData.response.data.user.role === RoleEnum.Student)
                form.setFieldValue('uniqueId', loaderData.response.data.user.uniqueId);

            switch(loaderData.response.data.user.role) {
                case RoleEnum.Admin:
                    form.setFieldValue('role', 'Admin');
                    break;
                case RoleEnum.CompanyAdmin:
                    form.setFieldValue('role', 'Céges admin');
                    break;
                case RoleEnum.Instructor:
                    form.setFieldValue('role', 'Oktató');
                    break;
                case RoleEnum.Student:
                    form.setFieldValue('role', 'Tanuló');
            }
            
            return;
        }

        dispatch(clearAlertMessage());
        const alertMessageState: AlertMessageState = {
            alertMessageType: 'error',
            alertMessageText: 'Ismeretlen hiba történt a cégek listázása során. Kérjük próbálja újra!'
        };

        dispatch(showAlertMessage(alertMessageState));

    }, [loaderData.response, dispatch, form]);

    useEffect(() => {
        dispatch(setPageTitle('Profil módosítása'));
        dispatch(setSelectedMenuItemKeys([]));
        dispatch(setOpenKeys([]));
    }, [dispatch]);

    return (
        <div className="profile-container">
            <Form
                form={form}
                onFinish={onFinish}
                layout='vertical'
            >

                <Form.Item
                    label='Név'
                    name='name'
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    label='Email cím'
                    name='email'
                    rules={[
                        {type: 'email', message: 'Helytelen email formátum!'}
                    ]}
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    label='Jogosultsági szint'
                    name='role'
                >
                    <Input disabled style={{ fontWeight: 'bolder' }}/>
                </Form.Item>

                {
                    loaderData.response?.data?.user?.role === RoleEnum.Student && (
                        <Form.Item
                            label='Egyedi azonosító'
                            name='uniqueId'
                        >
                            <Input disabled style={{fontWeight: 'bold'}}/>
                        </Form.Item>
                    )
                }

                <Button type='default' onClick={() => setShowChangePasswordFields(!showChangePasswordFields)} style={{ marginBottom: '15px' }}>
                    { showChangePasswordFields ? 'Jelszó módosításának kihagyása' : 'Jelszó módosítása' }
                </Button>

                {
                    showChangePasswordFields && (
                        <>
                            <Form.Item
                                label='Régi jelszó'
                                name='oldPassword'
                                rules={[{required: true, message: 'Jelszó megadása kötelező!'}]}
                            >
                                <Input.Password/>
                            </Form.Item>

                            <Form.Item
                                label='Új jelszó'
                                name='newPassword'
                                rules={[{required: true, message: 'Jelszó megadása kötelező!'}]}
                            >
                                <Input.Password/>
                            </Form.Item>

                            <Form.Item
                                label='Új jelszó újra'
                                name='newPasswordAgain'
                                rules={[{required: true, message: 'Jelszó megadása kötelező!'}]}
                            >
                                <Input.Password/>
                            </Form.Item>
                        </>
                    )
                }
                

                <Form.Item style={{ textAlign: 'right' }}>
                    <Button type='primary' htmlType='submit'>
                        Mentés
                    </Button>
                </Form.Item>
                        
            </Form>
        </div>
    )
}