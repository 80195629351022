import { Card, DatePicker, Select } from 'antd';
import { useDispatch } from 'react-redux';
import { setPageTitle, setSelectedMenuItemKeys } from '../../../../../redux/reducer/antd-layout.reducer';
import { useCallback, useEffect, useState } from 'react';
import { Form, Button } from 'antd';
import { networkCall } from '../../../../../util/network-call/network-call';
import { showDefaultErrorMessages } from '../../../../../util/show-default-error-message/show-default-error-messages';
import { AlertMessageState, clearAlertMessage, showAlertMessage } from '../../../../../redux/reducer/alert-message.reducer';
import { tranformAPIValidationErrorToAntDValidationMessage } from '../../../../../util/validation/transform-api-validation-errors-to-antd-validation-messsages';
import { Link, useLoaderData, useNavigate } from 'react-router-dom';
import { SelectOption } from '../../../../../constant/type/select-option.type';
import { CourseTypeEnum } from '../../../../../constant/enum/course-type.enum';
import { Education } from '../../../../../constant/type/education.type';
import dayjs from 'dayjs';


export const InstructorCourseEdit = () => {

    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const loaderData: any = useLoaderData();
    const navigate = useNavigate();
    const [educationOptions, setEducationOptions] = useState<SelectOption[]>([]);
    const [requireDeadline, setRequireDeadline] = useState(false);
    const courseTypeOptions: SelectOption[] = [
        {
            label: 'Kötelező éves',
            value: CourseTypeEnum.AnnualMandatory
        },
        {
            label: 'Gyakorlás',
            value: CourseTypeEnum.Practice
        }
    ];

    const handleTypeChange = useCallback(() => {
        const type = form.getFieldValue('type');

        if(type === CourseTypeEnum.Practice) {
            setRequireDeadline(false);
        } else {
            setRequireDeadline(true);
        }
    }, [form]);

    const todayPlusOneWeek = dayjs().add(7, 'day').startOf('day');

    const disabledDate = (currentDate: dayjs.Dayjs) => {
        return currentDate.startOf('day').isBefore(todayPlusOneWeek);
    };

    const onFinish = async (values: any) => {
        let deadline = undefined;
        // ha már van határidő, akkor megnézzük, hogy változott-e: ha nem változott akkor nem küldünk semmit a szervernek
        if(loaderData.getCourseResponse.data.course.deadline) {
            const prevDeadline = dayjs(new Date(loaderData.getCourseResponse.data.course.deadline * 1000));
            if(values.deadline && !prevDeadline.isSame(values.deadline)) {
                deadline = `${ values.deadline.get('year') }-${ values.deadline.get('month') + 1 }-${ values.deadline.get('date') }`;
            }

        } else {
            if(requireDeadline || (!requireDeadline && values.deadline))
                deadline = `${ values.deadline.get('year') }-${ values.deadline.get('month') + 1 }-${ values.deadline.get('date') }`;
        }

        dispatch(clearAlertMessage());
        dispatch(showAlertMessage({
            alertMessageType: 'loading',
            alertMessageText: 'Kérés folyamatban...'
        }));
        
        const response = await networkCall(`/courses/${ loaderData.courseId }`, {
            method: 'PUT',
            body: {
                type: values.type,
                deadline,
                educationId: values.educationId
            }
        });

        const messageShowed = showDefaultErrorMessages(response);
        if(messageShowed) return;

        dispatch(clearAlertMessage());
        const alertMessageState: AlertMessageState = {
            alertMessageType: 'error',
            alertMessageText: 'Ismeretlen hiba történt. Kérjük próbálja újra!'
        };

        if (response.status === 200 && response?.data?.course) {

            alertMessageState.alertMessageText = 'Mentés sikeres.';
            alertMessageState.alertMessageType = 'success';

        }else if(response.status === 400){

            switch (response.data.code) {

                case 2000:
                    alertMessageState.alertMessageText = 'Hibásan megadott beviteli mezők.';
                    const validationErrors = tranformAPIValidationErrorToAntDValidationMessage(response.data?.data);
                    form.setFields(validationErrors);
                    break;

            }

        }else if(response.status === 404){

            switch (response.data.code) {

                case 13000:
                    alertMessageState.alertMessageText = 'A kurzus nem létezik vagy nincs jogosultásga módosítani.';
                    break;

            }

        }else{
            alertMessageState.alertMessageText = 'Ismeretlen hiba történt. Kérjük próbálja újra!';
        }

        dispatch(showAlertMessage(alertMessageState));

    }

    useEffect(() => {

        const messageShowed = showDefaultErrorMessages(loaderData.getCourseResponse);
        if(messageShowed) return;

        const alertMessageState: AlertMessageState = {
            alertMessageType: 'error',
            alertMessageText: 'Ismeretlen hiba történt a kurzus lekérdezése során. Kérjük próbálja újra!'
        };

        if(loaderData.getCourseResponse?.status === 200 && loaderData.getCourseResponse?.data?.course) {
            form.setFieldValue('deadline', loaderData.getCourseResponse.data.course.deadline ? dayjs(new Date(loaderData.getCourseResponse.data.course.deadline * 1000)) : undefined);
            form.setFieldValue('type', loaderData.getCourseResponse.data.course.type);
            form.setFieldValue('educationId', loaderData.getCourseResponse.data.course.education.id);
            handleTypeChange();
            return;
        } else if(loaderData.getCourseResponse?.status === 404) {

            switch (loaderData.getCourseResponse?.data.code) {

                case 13000:
                    alertMessageState.alertMessageText = 'A kurzus nem létezik vagy nincs jogosultásga módosítani.';

            }
        }

        navigate('/cms/instructor/users/list');
        dispatch(clearAlertMessage());
        dispatch(showAlertMessage(alertMessageState));

    }, [loaderData.getCourseResponse, navigate, dispatch, form, handleTypeChange]);

    useEffect(() => {

        const messageShowed = showDefaultErrorMessages(loaderData.getEducationsResponse);
        if(messageShowed) return;

        if(loaderData.getEducationsResponse?.status === 200 && loaderData.getEducationsResponse?.data?.educations) {
            const _educationOptions: SelectOption[] = [];
            for (const education of (loaderData.getEducationsResponse.data.educations as Education[])) {
                _educationOptions.push({
                    label: education.name,
                    value: education.id
                })
            }

            setEducationOptions(_educationOptions);

            return;
        }

        dispatch(clearAlertMessage());
        const alertMessageState: AlertMessageState = {
            alertMessageType: 'error',
            alertMessageText: 'Ismeretlen hiba történt a licenszek listázása során. Kérjük próbálja újra!'
        };

        dispatch(showAlertMessage(alertMessageState));

    }, [loaderData.getEducationsResponse, dispatch]);

    useEffect(() => {
        dispatch(setSelectedMenuItemKeys(['0']));
        dispatch(setPageTitle('Kurzus módosítása'));
    }, [dispatch]);

    return (
        <Card>
            
            <Form
                form={form}
                onFinish={onFinish}
                layout='vertical'
            >

                <Form.Item
                    label='Oktatás'
                    name='educationId'
                    rules={[{required: true, message: 'Válasszon oktatást!'}]}
                >
                    <Select
                        showSearch
                        placeholder="Válasszon oktatást!"
                        options={educationOptions}
                    />
                </Form.Item>

                <Form.Item
                    label='Típus'
                    name='type'
                    rules={[{required: true, message: 'Válasszon típust!'}]}
                >
                    <Select
                        showSearch
                        placeholder="Válasszon típust!"
                        onChange={handleTypeChange}
                        options={courseTypeOptions}
                    />
                </Form.Item>

                <Form.Item
                    name="deadline"
                    label="Határidő"
                    rules={[{required: requireDeadline, message: 'Válasszon határidőt!'}]}
                >
                    <DatePicker disabledDate={disabledDate} />
                </Form.Item>

                <Form.Item>
                    <Link to={loaderData.getCourseResponse?.data?.course?.user ? `/cms/instructor/users/${ loaderData.getCourseResponse?.data?.course?.user?.id }/courses` : '/cms/instructor/users/list'}>
                        <Button type="default" htmlType="button">
                            Vissza
                        </Button>
                    </Link>
                    <Button type='primary' htmlType='submit' style={{float: 'right'}}>
                        Mentés
                    </Button>
                </Form.Item>
                        
            </Form>

        </Card>
    )
}