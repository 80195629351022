import { Card, Input, Select } from 'antd';
import { useDispatch } from 'react-redux';
import { setPageTitle, setSelectedMenuItemKeys } from '../../../../../redux/reducer/antd-layout.reducer';
import { useEffect, useState } from 'react';
import { Form, Button } from 'antd';
import { networkCall } from '../../../../../util/network-call/network-call';
import { showDefaultErrorMessages } from '../../../../../util/show-default-error-message/show-default-error-messages';
import { AlertMessageState, clearAlertMessage, showAlertMessage } from '../../../../../redux/reducer/alert-message.reducer';
import { tranformAPIValidationErrorToAntDValidationMessage } from '../../../../../util/validation/transform-api-validation-errors-to-antd-validation-messsages';
import { Link, useLoaderData, useNavigate } from 'react-router-dom';
import { SelectOption } from '../../../../../constant/type/select-option.type';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Module } from '../../../../../constant/type/module.type';


export const InstructorEducationAdd = () => {

    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const loaderData: any = useLoaderData();
    const [moduleOptions, setModuleOptions] = useState<SelectOption[]>([]);

    const onFinish = async (values: any) => {
 
        dispatch(clearAlertMessage());
        dispatch(showAlertMessage({
            alertMessageType: 'loading',
            alertMessageText: 'Kérés folyamatban...'
        }));

        const response = await networkCall('/educations', {
            method: 'POST',
            body: {
                name: values.name,
                moduleId: values.moduleId
            }
        });

        const messageShowed = showDefaultErrorMessages(response);
        if(messageShowed) return;

        dispatch(clearAlertMessage());
        const alertMessageState: AlertMessageState = {
            alertMessageType: 'error',
            alertMessageText: 'Ismeretlen hiba történt. Kérjük próbálja újra!'
        };

        if (response.status === 201 && response?.data?.education) {

            alertMessageState.alertMessageText = 'Mentés sikeres.';
            alertMessageState.alertMessageType = 'success';
            navigate(`/cms/instructor/educations/edit/${ response.data.education?.id }`);

        }else if(response.status === 400){

            switch (response.data.code) {

                case 2000:
                    alertMessageState.alertMessageText = 'Hibásan megadott beviteli mezők.';
                    const validationErrors = tranformAPIValidationErrorToAntDValidationMessage(response.data?.data);
                    form.setFields(validationErrors);
                    break;

            }

        } else {
            alertMessageState.alertMessageText = 'Ismeretlen hiba történt. Kérjük próbálja újra!';
        }

        dispatch(showAlertMessage(alertMessageState));

    }

    useEffect(() => {

        const messageShowed = showDefaultErrorMessages(loaderData.getModulesResponse);
        if(messageShowed) return;

        if(loaderData.getModulesResponse?.status === 200 && loaderData.getModulesResponse?.data?.modules) {
            const _moduleOptions: SelectOption[] = [];
            for (const module of (loaderData.getModulesResponse.data.modules as Module[])) {
                _moduleOptions.push({
                    label: module.name,
                    value: module.id
                });
            }

            setModuleOptions(_moduleOptions);

            return;
        }

        dispatch(clearAlertMessage());
        const alertMessageState: AlertMessageState = {
            alertMessageType: 'error',
            alertMessageText: 'Ismeretlen hiba történt a licenszek listázása során. Kérjük próbálja újra!'
        };

        dispatch(showAlertMessage(alertMessageState));

    }, [loaderData.getModulesResponse, dispatch]);

    useEffect(() => {
        dispatch(setSelectedMenuItemKeys(['4']));
        dispatch(setPageTitle('Új oktatás hozzáadása'));
    }, [dispatch]);

    return (
        <Card>

                <div style={{ marginBottom: '15px' }}>
                    <InfoCircleOutlined style={{ fontSize: '20px', verticalAlign: 'bottom' }} />&nbsp;
                    Az oktatás konfigurálását az oktatás szerkesztésének felületén teheti meg.
                </div>
            
            <Form
                form={form}
                onFinish={onFinish}
                layout='vertical'
            >

                <Form.Item
                    label='Oktatás neve'
                    name='name'
                    rules={[{required: true, message: 'Név megadása kötelező!'}]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label='Modul'
                    name='moduleId'
                    rules={[{required: true, message: 'Válasszon modult!'}]}
                >
                    <Select
                        showSearch
                        placeholder="Válasszon modult!"
                        options={moduleOptions}
                    />
                </Form.Item>

                <Form.Item>
                    <Link to='/cms/instructor/educations/list'>
                        <Button type="default" htmlType="button">
                            Vissza
                        </Button>
                    </Link>
                    <Button type='primary' htmlType='submit' style={{float: 'right'}}>
                        Mentés
                    </Button>
                </Form.Item>
                        
            </Form>

        </Card>
    )
}