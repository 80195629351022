import { Form, Input, Button } from 'antd';
import { networkCall } from '../../../util/network-call/network-call';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { AlertMessageState, clearAlertMessage, showAlertMessage } from '../../../redux/reducer/alert-message.reducer';
import { tranformAPIValidationErrorToAntDValidationMessage } from '../../../util/validation/transform-api-validation-errors-to-antd-validation-messsages';
import { showDefaultErrorMessages } from '../../../util/show-default-error-message/show-default-error-messages';


export const ForgottenPassword = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const onFinish = async (values: any) => {

        dispatch(clearAlertMessage());
        dispatch(showAlertMessage({
            alertMessageType: 'loading',
            alertMessageText: 'Kérés folyamatban...'
        }));

        const response = await networkCall('/users/request-password-reset', {
            method: 'POST',
            body: {
                email: values.email
            }
        });

        const messageShowed = showDefaultErrorMessages(response);

        if(messageShowed) return;

        dispatch(clearAlertMessage());
        const alertMessageState: AlertMessageState = {
            alertMessageType: 'error',
            alertMessageText: 'Ismeretlen hiba történt. Kérjük próbálja újra!'
        };

        if (response.status === 201) {

            navigate('/cms/login');
            alertMessageState.alertMessageType = 'success';
            alertMessageState.alertMessageText = 'Jelszavának megváltozatásához szükséges információt a megadott email címre elküldtük.';

        }else if(response.status === 400){

            switch (response.data.code) {

                case 2000:
                    alertMessageState.alertMessageText = 'Hibásan megadott beviteli mezők.';
                    const validationErrors = tranformAPIValidationErrorToAntDValidationMessage(response.data?.data);
                    form.setFields(validationErrors);
                    break;

            }

        }else if(response.status === 404){
            alertMessageState.alertMessageText = 'Hibás email cím.';
        }else{
            alertMessageState.alertMessageText = 'Ismeretlen hiba történt. Kérjük próbálja újra!';
        }

        dispatch(showAlertMessage(alertMessageState));
        
    }

    return (
        <div className="box">

            <div className='body'>
                
                <Form
                    form={form}
                    onFinish={onFinish}
                >
                        
                    <Form.Item
                        label='Email'
                        name='email'
                        rules={[
                            {required: true, message: 'Email megadása kötelező!'},
                            {type: 'email', message: 'Helytelen email formátum!'}
                        ]}
                        
                    >
                        <Input placeholder='email cím'/>
                    </Form.Item>

                    <Form.Item className='text-center'>
                        <Button type="primary" htmlType="submit">
                            Email küldése
                        </Button>
                    </Form.Item>
                        
                </Form>

                <div className='text-center'>
                    <Link to="/cms/login">Vissza</Link>
                </div>

            </div>
            
        </div>
    )
}