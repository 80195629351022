import { useSelector } from 'react-redux';
import { message } from 'antd';
import { RootState } from './../../redux/store';
import { useEffect } from 'react';

export const AlertMessage = () => {

    const [messageApi, contextHolder] = message.useMessage();
    const { alertMessageType, alertMessageText } = useSelector((state: RootState) => state.alertMessage);

    useEffect(() => {
        if(alertMessageText !== null) {
  
            messageApi.open({
                type: alertMessageType,
                content: alertMessageText,
                duration: alertMessageType === 'loading' ? 0 : 5
            });

        }

        return () => {
            messageApi.destroy();
        };
    });
    
    return <>{contextHolder}</>;
}