import { Card } from 'antd';
import { useDispatch } from 'react-redux';
import { setPageTitle, setSelectedMenuItemKeys } from '../../../../../redux/reducer/antd-layout.reducer';
import { useEffect } from 'react';
import { Form, Input, Button } from 'antd';
import { networkCall } from '../../../../../util/network-call/network-call';
import { showDefaultErrorMessages } from '../../../../../util/show-default-error-message/show-default-error-messages';
import { AlertMessageState, clearAlertMessage, showAlertMessage } from '../../../../../redux/reducer/alert-message.reducer';
import { tranformAPIValidationErrorToAntDValidationMessage } from '../../../../../util/validation/transform-api-validation-errors-to-antd-validation-messsages';
import { Link, useNavigate } from 'react-router-dom';
import { InfoCircleOutlined } from '@ant-design/icons';


export const AdminModuleAdd = () => {

    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const navigate = useNavigate();

    const onFinish = async (values: any) => {

        dispatch(clearAlertMessage());
        dispatch(showAlertMessage({
            alertMessageType: 'loading',
            alertMessageText: 'Kérés folyamatban...'
        }));

        const response = await networkCall('/modules', {
            method: 'POST',
            body: {
                name: values.name,
                uniqueId: values.uniqueId
            }
        });

        const messageShowed = showDefaultErrorMessages(response);

        if(messageShowed) return;

        dispatch(clearAlertMessage());
        const alertMessageState: AlertMessageState = {
            alertMessageType: 'error',
            alertMessageText: 'Ismeretlen hiba történt. Kérjük próbálja újra!'
        };

        if (response.status === 201 && response.data.module !== undefined) {

            alertMessageState.alertMessageText = 'Mentés sikeres.';
            alertMessageState.alertMessageType = 'success';
            navigate(`/cms/admin/modules/edit/${ response.data.module.id }`);

        }else if(response.status === 400){

            switch (response.data.code) {

                case 2000:
                    alertMessageState.alertMessageText = 'Hibásan megadott beviteli mezők.';
                    const validationErrors = tranformAPIValidationErrorToAntDValidationMessage(response.data?.data);
                    form.setFields(validationErrors);
                    break;

            }

        }else{
            alertMessageState.alertMessageText = 'Ismeretlen hiba történt. Kérjük próbálja újra!';
        }

        dispatch(showAlertMessage(alertMessageState));

    }

    useEffect(() => {
        dispatch(setSelectedMenuItemKeys(['3']));
        dispatch(setPageTitle('Új modul hozzáadása'));
    }, [dispatch]);

    return (
        <Card>
            
            <Form
                form={form}
                onFinish={onFinish}
                layout='vertical'
            >

                <div style={{ marginBottom: '15px' }}>
                    <InfoCircleOutlined style={{ fontSize: '20px', verticalAlign: 'bottom' }} />&nbsp;
                    A modul konfigurálását a modul szerkesztési felületén teheti meg.
                </div>
    
                <Form.Item
                    label='Modul neve'
                    name='name'
                    rules={[{required: true, message: 'Név megadása kötelező!'}]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label='Modul azonosítója'
                    name='uniqueId'
                    rules={[{required: true, message: 'Azonosító megadása kötelező!'}]}
                >
                    <Input />
                </Form.Item>

                <Form.Item>
                    <Link to='/cms/admin/modules/list'>
                        <Button type="default" htmlType="button">
                            Vissza
                        </Button>
                    </Link>
                    <Button type='primary' htmlType='submit' style={{float: 'right'}}>
                        Mentés
                    </Button>
                </Form.Item>
                        
            </Form>

        </Card>
    )
}