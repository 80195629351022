import Sider from "antd/es/layout/Sider";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from './../../../redux/store';
import { Button, Menu, MenuProps } from "antd";
import { MediumOutlined, SlidersOutlined } from "@ant-design/icons";
import './cms-sider.css';
import { NavLink, useNavigate } from "react-router-dom";
import { RoleEnum } from "../../../constant/enum/role.enum";
import { setOpenKeys } from "../../../redux/reducer/antd-layout.reducer";
import { logout } from "../../../redux/reducer/session.reducer";
import { getIconUrl, getImageUrl } from "../../../util/image-helper/get-image-url";


export const CmsSider = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const { siderCollapsed, selectedMenuItemKeys, openKeys } = useSelector((state: RootState) => state.antdLayoutState);
    const user = useSelector((state: RootState) => state.session.user);

    const onOpenChange = (item: string[]) => {
        dispatch(setOpenKeys(item));
    };

    const logoutOnClick = () => {

        if(window.confirm('Biztosan kijelentkezel?')){
            dispatch(logout());
            navigate('/cms/login');
        }

    }

    type MenuItem = Required<MenuProps>['items'][number];

    let items: MenuItem[] = [];

    // role szerinti menu elem kiosztás
    switch(user?.role) {
        case RoleEnum.Admin:
            items = [
                {
                    key: '0',
                    label: <NavLink className='nav-link' to='/cms/admin/users'>Felhasználók</NavLink>,
                    icon: selectedMenuItemKeys.includes('0') ? <img style={ { width: '24px' } } src={getIconUrl('active-user-menu-item.png')} alt="active user menu item" /> : <img style={ { width: '24px' } } src={getIconUrl('user-menu-item.png')} alt="user menu item" />,
                    title: 'Felhasználók kezelése'
                },
                /*{
                    key: '1',
                    label: <NavLink className='nav-link' to='/cms/admin/positions'>Beosztások</NavLink>,
                    icon: <BoldOutlined />,
                    title: 'Beosztások kezelése'
                },*/
                {
                    key: 'companies',
                    label: 'Cégek',
                    icon: <SlidersOutlined style={{ fontSize: '24px' }} />,
                    title: 'Cégek kezelése',
                    children: [
                        {
                            key: 'active-companies',
                            label: <NavLink className='nav-link' to='/cms/admin/active-companies/list'>Aktív cégek</NavLink>,
                            //icon: <SlidersOutlined style={{ fontSize: '24px' }} />,
                            title: 'Aktív cégek kezelése'
                        },
                        {
                            key: 'deleted-companies',
                            label: <NavLink className='nav-link' to='/cms/admin/deleted-companies/list'>Törölt cégek</NavLink>,
                            //icon: <ScissorOutlined style={{ fontSize: '24px' }} />,
                            title: 'Törölt cégek kezelése'
                        }
                    ]
                },
                {
                    key: '3',
                    label: <NavLink className='nav-link' to='/cms/admin/modules'>Modulok</NavLink>,
                    icon: <MediumOutlined style={{ fontSize: '24px' }} />,
                    title: 'Modulok kezelése'
                }
            ];
            break;
        case RoleEnum.CompanyAdmin:
            items = [
                {
                    key: '0',
                    label: <NavLink className='nav-link' to='/cms/company-admin/users'>Felhasználók</NavLink>,
                    icon: selectedMenuItemKeys.includes('0') ? <img style={ { width: '24px' } } src={getIconUrl('active-user-menu-item.png')} alt="active user menu item" /> : <img style={ { width: '24px' } } src={getIconUrl('user-menu-item.png')} alt="user menu item" />,
                    title: 'Felhasználók kezelése'
                },
                {
                    key: '4',
                    label: <NavLink className='nav-link' to='/cms/company-admin/educations'>Oktatások</NavLink>,
                    icon: selectedMenuItemKeys.includes('4') ? <img style={ { width: '24px' } } src={getIconUrl('active-education-menu-item.png')} alt="active education menu item" /> : <img style={ { width: '24px' } } src={getIconUrl('education-menu-item.png')} alt="education menu item" />,
                    title: 'Oktatások kezelése'
                }
            ];
            break;
        case RoleEnum.Instructor:
            items = [
                {
                    key: '0',
                    label: <NavLink className='nav-link' to='/cms/instructor/users'>Felhasználók</NavLink>,
                    icon: selectedMenuItemKeys.includes('0') ? <img style={ { width: '24px' } } src={getIconUrl('active-user-menu-item.png')} alt="active user menu item" /> : <img style={ { width: '24px' } } src={getIconUrl('user-menu-item.png')} alt="user menu item" />,
                    title: 'Felhasználók kezelése'
                },
                {
                    key: '4',
                    label: <NavLink className='nav-link' to='/cms/instructor/educations'>Oktatások</NavLink>,
                    icon: selectedMenuItemKeys.includes('4') ? <img style={ { width: '24px' } } src={getIconUrl('active-education-menu-item.png')} alt="active education menu item" /> : <img style={ { width: '24px' } } src={getIconUrl('education-menu-item.png')} alt="education menu item" />,
                    title: 'Oktatások kezelése'
                }
            ];
            break;
        case RoleEnum.Student:
            items = [
                {
                    key: 'student-courses',
                    label: <NavLink className='nav-link' to='/cms/student/courses/list'>Kurzusaim</NavLink>,
                    icon: selectedMenuItemKeys.includes('student-courses') ? <img style={ { width: '24px' } } src={getIconUrl('active-course-menu-item.png')} alt="active course menu item" /> : <img style={ { width: '24px' } } src={getIconUrl('course-menu-item.png')} alt="course menu item" />,
                    title: 'Kurzusaim kezelése'
                }
            ];
            break;
    }

    return (
        <Sider collapsed={siderCollapsed} className="sider-menu">
            
                <Menu
                    selectedKeys={selectedMenuItemKeys}
                    openKeys={openKeys}
                    onOpenChange={onOpenChange}
                    mode="inline"
                    theme="dark"
                    items={items}
                />

                <div className="text-center">

                    <Button
                        type="text"
                        icon={<img src={getIconUrl('user-settings.png')} alt="user settings" />}
                        className='button'
                        title='Profil módosítása'
                        onClick={() => navigate('/cms/users/profile')}
                    />

                    <br />

                    <Button
                        type="text"
                        icon={<img src={getIconUrl('logout.png')} alt="logout" />}
                        className='button'
                        title="Kijelentkezés"
                        onClick={logoutOnClick}
                    />

                    <div className='holofire-text text-center'>
                        {siderCollapsed ? <img src={getIconUrl('holofire.png')} alt="holofire" /> : <img style={{ width: '65%' }} src={getImageUrl('holofire.png')} alt="holofire" />}
                    </div>

                </div>

        </Sider>
    )
}