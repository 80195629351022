import { Button, Input, Table } from 'antd';
import './admin-course-list.css';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Link, useLoaderData, useRevalidator } from 'react-router-dom';
import { showDefaultErrorMessages } from '../../../../../util/show-default-error-message/show-default-error-messages';
import { AlertMessageState, clearAlertMessage, showAlertMessage } from '../../../../../redux/reducer/alert-message.reducer';
import { EditOutlined, SearchOutlined } from '@ant-design/icons';
import { networkCall } from '../../../../../util/network-call/network-call';
import { setPageTitle, setSelectedMenuItemKeys } from '../../../../../redux/reducer/antd-layout.reducer';
import { CourseTypeEnum } from '../../../../../constant/enum/course-type.enum';
import { formatTimestampToDate } from '../../../../../util/date/format-timestamp-to-date';
import { FilterDropdownProps } from 'antd/es/table/interface';
import { RootState } from '../../../../../redux/store';
import { DeleteButton } from '../../../../button/delete-button/delete-button';

type TableDataType = {
    key: React.Key;
    type: CourseTypeEnum;
    name: string;
    completed: number | null;
    deadline: number | null;
    created: number;
};

export const AdminCourseList = () => {

    const reValidator = useRevalidator();
    const loaderData: any = useLoaderData();
    const dispatch = useDispatch();
    const { searchTerm } = useSelector((state: RootState) => state.antdLayoutState);

    const getCourseTypeName = (courseType: CourseTypeEnum) => {
        switch(courseType) {
            case CourseTypeEnum.AnnualMandatory: return 'Kötelező éves';
            case CourseTypeEnum.Practice: return 'Gyakorlás';
            default: return '';
        }
    };

    const handleDeleteClick = async (e: React.MouseEvent, id: React.Key) => {
        e.stopPropagation();

        if(window.confirm('Biztosan törli az kurzust?')) {
            
            dispatch(clearAlertMessage());
            dispatch(showAlertMessage({
                alertMessageType: 'loading',
                alertMessageText: 'Kérés folyamatban...'
            }));

            const response = await networkCall(`/courses/${ id }`, {
                method: 'DELETE'
            });

            const messageShowed = showDefaultErrorMessages(response);
            if(messageShowed) return;

            if(response.status === 204) {
                reValidator.revalidate();
                dispatch(showAlertMessage({
                    alertMessageType: 'success',
                    alertMessageText: 'Törlés sikeres.'
                }));
                return;
            }

            dispatch(clearAlertMessage());
            const alertMessageState: AlertMessageState = {
                alertMessageType: 'error',
                alertMessageText: 'Ismeretlen hiba történt. Kérjük próbálja újra!'
            };

            if(response.status === 404) {
                alertMessageState.alertMessageText = 'Törlés sikertelen, a törlendő kurzus nem létezik.';
            }

            dispatch(showAlertMessage(alertMessageState));

        }

    }
    
    const columns = [
        {
            title: 'Oktatás neve',
            dataIndex: 'name',
            sorter: (a: TableDataType, b: TableDataType) => String(a.name).localeCompare(b.name),
            /*filterDropdown: ({ confirm, setSelectedKeys, selectedKeys }: FilterDropdownProps) => {
                return <Input
                    autoFocus
                    value={selectedKeys[0]}
                    placeholder='Keresés...'
                    onInput={(e: any) => {
                        setSelectedKeys( e.target.value ? [e.target.value] : [] );
                        confirm({ closeDropdown: false });
                    }}
                />;
            },
            filterIcon: () => {
                return <SearchOutlined />;
            },
            onFilter: (value: any, record: TableDataType) => {
                return record.name.toLowerCase().includes(value.toLowerCase());
            }*/
        },
        {
            title: 'Státusz',
            render: (record: TableDataType) => {

                if(record.type === CourseTypeEnum.AnnualMandatory) {
                    const today = new Date();
                    today.setHours(0, 0, 0, 0);
                    const deadline = new Date(record.deadline! * 1000);
                    deadline.setHours(0, 0, 0, 0);

                    if(record.completed) { // már teljesítve van

                        const completed = new Date(record.completed * 1000);
                        completed.setHours(0, 0, 0, 0);
                        completed.setFullYear(completed.getFullYear()  + 1); // lejárati idő: teljesítés + 1 év

                        if(completed.getTime() < today.getTime()) return 'Érvénytelen';
                        else return 'Teljesített';

                    } else { // még nincs teljesítve

                        if(today.getTime() <= deadline.getTime()) return 'Folyamatban';
                        else return 'Lejárt';
                        
                    }
                }

            }
        },
        {
            title: 'Típus',
            sorter: (a: TableDataType, b: TableDataType) => getCourseTypeName(a.type).toLocaleLowerCase().localeCompare(getCourseTypeName(b.type).toLocaleLowerCase()),
            render: (record: TableDataType) => {
                return getCourseTypeName(record.type);
            }
        },
        {
            title: 'Létrehozva',
            sorter: (a: TableDataType, b: TableDataType) => a.created - b.created,
            render: (record: TableDataType) => {
                return formatTimestampToDate(record.created);
            }
        },
        {
            title: 'Határidő',
            sorter: (a: TableDataType, b: TableDataType) => (a.deadline ? a.deadline : 0) - (b.deadline ? b.deadline : 0),
            render: (record: TableDataType) => {
                return record.deadline ? formatTimestampToDate(record.deadline) : '';
            }
        },
        {
            title: 'Érvényesség',
            sorter: (a: TableDataType, b: TableDataType) => (a.completed ? a.completed : 0) - (b.completed ? b.completed : 0),
            render: (record: TableDataType) => {
                if(record.type === CourseTypeEnum.AnnualMandatory) {
                    if(record.completed) { // már teljesítve van
        
                        const completed = new Date(record.completed * 1000);
                        completed.setHours(0, 0, 0, 0);
                        completed.setFullYear(completed.getFullYear()  + 1); // lejárati idő: teljesítés + 1 év
        
                        return formatTimestampToDate(completed.getTime() / 1000);
                    }
                }
            }
        },
        {
            title: 'Műveletek',
            render: (record: TableDataType) => {
                return (
                    <>
                        <Link title='Szerkesztés' to={`/cms/admin/courses/edit/${ record.key }`} className='edit-icon'><EditOutlined /></Link>
                        &nbsp;&nbsp;
                        <DeleteButton onClick={(e) => handleDeleteClick(e, record.key)} />
                    </>
                )
            }
        }
    ];
    const [data, setData] = useState<TableDataType[]>([]);

    useEffect(() => {
        const messageShowed = showDefaultErrorMessages(loaderData.getCoursesResponse);
        if(messageShowed) return;

        if(loaderData.getCoursesResponse?.status === 200 && loaderData?.getCoursesResponse?.data?.courses) {
            const tableData: TableDataType[] = [];
            for (const course of loaderData.getCoursesResponse.data.courses) {

                if(!course?.education || course.education.name.toLowerCase().includes(searchTerm.toLowerCase())) {
                    tableData.push({
                        key: course.id,
                        type: course.type,
                        name: course?.education?.name,
                        completed: course.completed,
                        deadline: course.deadline,
                        created: course.created
                    });
                }

            }

            setData(tableData);
            return;
        }

        dispatch(clearAlertMessage());
        const alertMessageState: AlertMessageState = {
            alertMessageType: 'error',
            alertMessageText: 'Ismeretlen hiba történt az oktatások listázása során. Kérjük próbálja újra!'
        };

        dispatch(showAlertMessage(alertMessageState));

    }, [loaderData, dispatch, searchTerm]);

    useEffect(() => {
        dispatch(setSelectedMenuItemKeys(['0']));
        dispatch(setPageTitle('Kurzusok'));
    }, [dispatch]);

    return (
        <>

            <div style={{ display: 'flex', justifyContent: 'flex-end', paddingBottom: '5px' }}>

                <Link to={`/cms/admin/users/${ loaderData.id }/courses/add`}><Button type='primary' className='fw-bold'>Új kurzus hozzáadása</Button></Link>
                
            </div>

            <Table
                className='table-responsive data-table'
                columns={columns}
                dataSource={data}
                bordered
                rowClassName={(record, name) => 'admin-course-table-row'}
                pagination={{ position: ['bottomCenter'], pageSize: 10}}
            />

        </>
    )
}