import { Card, Checkbox, Select } from 'antd';
import { useDispatch } from 'react-redux';
import { setOpenKeys, setPageTitle, setSelectedMenuItemKeys } from '../../../../../redux/reducer/antd-layout.reducer';
import { useEffect, useState } from 'react';
import { Form, Button } from 'antd';
import { networkCall } from '../../../../../util/network-call/network-call';
import { showDefaultErrorMessages } from '../../../../../util/show-default-error-message/show-default-error-messages';
import { AlertMessageState, clearAlertMessage, showAlertMessage } from '../../../../../redux/reducer/alert-message.reducer';
import { tranformAPIValidationErrorToAntDValidationMessage } from '../../../../../util/validation/transform-api-validation-errors-to-antd-validation-messsages';
import { Link, useLoaderData, useNavigate } from 'react-router-dom';
import { SelectOption } from '../../../../../constant/type/select-option.type';
import { InfoCircleOutlined } from '@ant-design/icons';
import { LicenseTypeEnum } from '../../../../../constant/enum/license-type.enum';


export const AdminLicenseAdd = () => {

    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const loaderData: any = useLoaderData();
    const [modules, setModules] = useState<SelectOption[]>([]);

    const typeOptions: SelectOption[] = [
        {
            label: 'Headsetes',
            value: LicenseTypeEnum.Headset
        },
        {
            label: 'Tabletes',
            value: LicenseTypeEnum.Tablet
        },
        {
            label: 'Kombinált (Headset + Tablet)',
            value: LicenseTypeEnum.Combined
        }
    ];

    const onFinish = async (values: any) => {
 
        dispatch(clearAlertMessage());
        dispatch(showAlertMessage({
            alertMessageType: 'loading',
            alertMessageText: 'Kérés folyamatban...'
        }));
        
        const response = await networkCall('/licenses', {
            method: 'POST',
            body: {
                companyId: loaderData.companyId,
                moduleId: values.moduleId,
                type: values.type,
                examination: values.examination
            }
        });

        const messageShowed = showDefaultErrorMessages(response);
        if(messageShowed) return;

        dispatch(clearAlertMessage());
        const alertMessageState: AlertMessageState = {
            alertMessageType: 'error',
            alertMessageText: 'Ismeretlen hiba történt. Kérjük próbálja újra!'
        };

        if (response.status === 201 && response.data.license !== undefined) {

            alertMessageState.alertMessageText = 'Mentés sikeres.';
            alertMessageState.alertMessageType = 'success';
            navigate(`/cms/admin/licenses/edit/${ response.data.license.id }`);

        }else if(response.status === 400){

            switch (response.data.code) {

                case 2000:
                    alertMessageState.alertMessageText = 'Hibásan megadott beviteli mezők.';
                    const validationErrors = tranformAPIValidationErrorToAntDValidationMessage(response.data?.data);
                    form.setFields(validationErrors);
                    break;

            }

        }else if(response.status === 404){

            switch (response.data.code) {

                case 5000:
                    alertMessageState.alertMessageText = 'A megadott cég nem található.';
                    break;
                case 8000:
                    alertMessageState.alertMessageText = 'A megadott modul nem található.';

            }

        }else{
            alertMessageState.alertMessageText = 'Ismeretlen hiba történt. Kérjük próbálja újra!';
        }

        dispatch(showAlertMessage(alertMessageState));

    }

    useEffect(() => {

        const messageShowed = showDefaultErrorMessages(loaderData.modulesResponse);
        if(messageShowed) return;

        if(loaderData.modulesResponse.status === 200 && loaderData.modulesResponse.data && loaderData.modulesResponse.data.modules) {
            const _modules: SelectOption[] = [];
            for (const module of loaderData.modulesResponse.data.modules) {
                _modules.push({
                    label: module.name,
                    value: module.id
                });
            }

            setModules(_modules);

            return;
        }

        dispatch(clearAlertMessage());
        const alertMessageState: AlertMessageState = {
            alertMessageType: 'error',
            alertMessageText: 'Ismeretlen hiba történt a modulok listázása során. Kérjük próbálja újra!'
        };

        dispatch(showAlertMessage(alertMessageState));

    }, [loaderData.modulesResponse, dispatch]);

    useEffect(() => {
        dispatch(setSelectedMenuItemKeys(['active-companies']));
        dispatch(setOpenKeys(['companies']));
        dispatch(setPageTitle('Új licenc hozzáadása'));
    }, [dispatch]);

    return (
        <Card>

            <div style={{ marginBottom: '15px' }}>
                <InfoCircleOutlined style={{ fontSize: '20px', verticalAlign: 'bottom' }} />&nbsp;
                A modul céghez tartozó adatait a licenc módosítási felületén konfigurálhatja.
            </div>
            
            <Form
                form={form}
                onFinish={onFinish}
                layout='vertical'
            >

                <Form.Item
                    label='Modul'
                    name='moduleId'
                    rules={[{required: true, message: 'Válasszon modult!'}]}
                >
                    <Select
                        showSearch
                        placeholder="Válasszon modult!"
                        options={modules}
                    />
                </Form.Item>

                <Form.Item
                    label='Típus'
                    name='type'
                    rules={[{required: true, message: 'Típus megadása kötelező!'}]}
                >
                    <Select
                        placeholder="Válasszon típust!"
                        options={typeOptions}
                    />
                </Form.Item>

                <Form.Item
                    initialValue={false}
                    name='examination'
                    rules={[{required: true, message: 'Mező kitöltése kötelező!'}]}
                    valuePropName='checked'
                >
                    <Checkbox>Vizsgáztatás</Checkbox>
                </Form.Item>

                <Form.Item>
                    <Link to={loaderData.companyId ? `/cms/admin/companies/edit/${ loaderData.companyId }` : '/cms/admin/active-companies/list'}>
                        <Button type="default" htmlType="button">
                            Vissza
                        </Button>
                    </Link>
                    <Button type='primary' htmlType='submit' style={{float: 'right'}}>
                        Mentés
                    </Button>
                </Form.Item>
                        
            </Form>

        </Card>
    )
}