import { Navigate, createBrowserRouter } from 'react-router-dom';
import { NotFound } from '../component/not-found/not-found';
import { Error } from '../component/error/error';
import { LoginLayout } from '../layout/login/login.layout';
import { Login } from '../component/cms/login/login';
import { ForgottenPassword } from '../component/cms/forgotten-password/forgotten-password';
import { ChangePassword } from '../component/cms/change-password/change-password';
import { ChangePasswordLoader } from './loader/cms/change-password/change-password.loader';
import { FirstLoginPasswordChange } from '../component/cms/first-login-password-change/forst-login-password-change';
import { FirstLoginPasswordChangeLoader } from './loader/cms/first-login-password-change/first-login-password-change.loader';
import { CmsLayout } from '../layout/cms/default/cms.layout';
import { CmsLayoutLoader } from './loader/cms/cms-layout.loader';
import { RequireAuth } from '../component/cms/require-auth/require-auth';
import { RoleEnum } from '../constant/enum/role.enum';
import { Profile } from '../component/cms/profile/profile';
import { ProfileLoader } from './loader/cms/profile/profile.loader';
import { AdminLayout } from '../layout/cms/admin/admin.layout';
import { AdminUserList } from '../component/cms/admin/user/list/admin-user-list';
import { AdminUserListLoader } from './loader/cms/admin/user/admin-user-list.loader';
import { AdminUserAdd } from '../component/cms/admin/user/add/admin-user-add';
import { AdminUserAddLoader } from './loader/cms/admin/user/admin-user-add.loader';
import { AdminUserEdit } from '../component/cms/admin/user/edit/admin-user-edit';
import { AdminUserEditLoader } from './loader/cms/admin/user/admin-user-edit.loader';
import { AdminPositionList } from '../component/cms/admin/position/list/admin-position-list';
import { AdminPositionListLoader } from './loader/cms/admin/position/admin-position-list.loader';
import { AdminPositionAdd } from '../component/cms/admin/position/add/admin-position-add';
import { AdminPositionEdit } from '../component/cms/admin/position/edit/admin-position-edit';
import { AdminPositionEditLoader } from './loader/cms/admin/position/admin-position-edit.loader';
import { AdminActiveCompanyList } from '../component/cms/admin/company/active/list/admin-active-company-list';
import { AdminActiveCompanyListLoader } from './loader/cms/admin/company/active/admin-active-company-list.loader';
import { AdminDeletedCompanyList } from '../component/cms/admin/company/deleted/list/admin-deleted-company-list';
import { AdminDeletedCompanyListLoader } from './loader/cms/admin/company/deleted/admin-deleted-company-list.loader';
import { AdminCompanyAdd } from '../component/cms/admin/company/add/admin-company-add';
import { AdminCompanyEdit } from '../component/cms/admin/company/edit/admin-company-edit';
import { AdminCompanyEditLoader } from './loader/cms/admin/company/admin-company-edit.loader';
import { AdminDeviceAdd } from '../component/cms/admin/device/add/admin-device-add';
import { AdminDeviceAddtLoader } from './loader/cms/admin/device/admin-device-add.loader';
import { AdminLicenseAdd } from '../component/cms/admin/license/add/admin-license-add';
import { AdminLicenseAddLoader } from './loader/cms/admin/license/admin-license-add.loader';
import { AdminLicenseEdit } from '../component/cms/admin/license/edit/admin-license-edit';
import { AdminLicenseEditLoader } from './loader/cms/admin/license/admin-license-edit.loader';
import { AdminDeviceEdit } from '../component/cms/admin/device/edit/admin-device-edit';
import { AdminDeviceEditLoader } from './loader/cms/admin/device/admin-device-edit.loader';
import { AdminModuleList } from '../component/cms/admin/module/list/admin-module-list';
import { AdminModuleListLoader } from './loader/cms/admin/module/admin-module-list.loader';
import { AdminModuleAdd } from '../component/cms/admin/module/add/admin-module-add';
import { AdminModuleEdit } from '../component/cms/admin/module/edit/admin-module-edit';
import { AdminModuleEditLoader } from './loader/cms/admin/module/admin-module-edit.loader';
import { CompanyAdminLayout } from '../layout/cms/company-admin/company-admin.layout';
import { CompanyAdminUserList } from '../component/cms/company-admin/user/list/company-admin-user-list';
import { CompanyAdminUserListLoader } from './loader/cms/company-admin/user/company-admin-user-list.loader';
import { CompanyAdminUserAdd } from '../component/cms/company-admin/user/add/company-admin-user-add';
import { CompanyAdminUserEdit } from '../component/cms/company-admin/user/edit/company-admin-user-edit';
import { CompanyAdminUserEditLoader } from './loader/cms/company-admin/user/company-admin-user-edit.loader';
import { CompanyAdminEducationList } from '../component/cms/company-admin/education/list/company-admin-education-list';
import { CompanyAdminEducationListLoader } from './loader/cms/company-admin/education/company-admin-education-list.loader';
import { CompanyAdminEducationAdd } from '../component/cms/company-admin/education/add/company-admin-education-add';
import { CompanyAdminEducationAddLoader } from './loader/cms/company-admin/education/company-admin-education-add.loader';
import { CompanyAdminEducationEdit } from '../component/cms/company-admin/education/edit/company-admin-education-edit';
import { CompanyAdminEducationEditLoader } from './loader/cms/company-admin/education/company-admin-education-edit.loader';
import { CompanyAdminCourseList } from '../component/cms/company-admin/courses/list/company-admin-course-list';
import { CompanyAdminCourseListLoader } from './loader/cms/company-admin/course/company-admin-course-list.loader';
import { CompanyAdminCourseAdd } from '../component/cms/company-admin/courses/add/company-admin-course-add';
import { CompanyAdminCourseAddLoader } from './loader/cms/company-admin/course/company-admin-course-add.loader';
import { CompanyAdminCourseEdit } from '../component/cms/company-admin/courses/edit/company-admin-course-edit';
import { CompanyAdminCourseEditLoader } from './loader/cms/company-admin/course/company-admin-course-edit.loader';
import { InstructorLayout } from '../layout/cms/instructor/instructor.layout';
import { InstructorUserList } from '../component/cms/instructor/user/list/instructor-user-list';
import { InstructorUserListLoader } from './loader/cms/instructor/user/instructor-user-list.loader';
import { InstructorUserAdd } from '../component/cms/instructor/user/add/instructor-user-add';
import { InstructorUserEdit } from '../component/cms/instructor/user/edit/instructor-user-edit';
import { InstructorUserEditLoader } from './loader/cms/instructor/user/instructor-user-edit.loader';
import { InstructorEducationList } from '../component/cms/instructor/education/list/instuctor-education-list';
import { InstructorEducationListLoader } from './loader/cms/instructor/education/intstructor-education-list.loader';
import { InstructorEducationAdd } from '../component/cms/instructor/education/add/instructor-education-add';
import { InstructorEducationAddLoader } from './loader/cms/instructor/education/instructor-education-add.loader';
import { InstructorEducationEdit } from '../component/cms/instructor/education/edit/instructor-education-edit';
import { InstructorEducationEditLoader } from './loader/cms/instructor/education/instructor-education-edit.loader';
import { InstructorCourseList } from '../component/cms/instructor/courses/list/instructor-course-list';
import { InstructorCourseListLoader } from './loader/cms/instructor/course/instructor-course-list.loader';
import { InstructorCourseAdd } from '../component/cms/instructor/courses/add/instructor-course-add';
import { InstructorCourseAddLoader } from './loader/cms/instructor/course/instructor-course-add.loader';
import { InstructorCourseEdit } from '../component/cms/instructor/courses/edit/instructor-course-edit';
import { InstructorCourseEditLoader } from './loader/cms/instructor/course/instructor-course-edit.loader';
import { StudentLayout } from '../layout/cms/student/student.layout';
import { StudentCourseList } from '../component/cms/student/courses/list/student-course-list';
import { StudentCourseListLoader } from './loader/cms/student/course/student-course-list.loader';
import { AdminEducationAdd } from '../component/cms/admin/education/add/admin-education-add';
import { AdminEducationAddLoader } from './loader/cms/admin/education/admin-education-add.loader';
import { AdminEducationEdit } from '../component/cms/admin/education/edit/admin-education-edit';
import { AdminEducationEditLoader } from './loader/cms/admin/education/admin-education-edit.loader';
import { AdminCourseList } from '../component/cms/admin/courses/list/admin-course-list';
import { AdminCourseListLoader } from './loader/cms/admin/course/admin-course-list.loader';
import { AdminCourseAdd } from '../component/cms/admin/courses/add/admin-course-add';
import { AdminCourseAddLoader } from './loader/cms/admin/course/admin-course-add.loader';
import { AdminCourseEdit } from '../component/cms/admin/courses/edit/admin-course-edit';
import { AdminCourseEditLoader } from './loader/cms/admin/course/admin-course-edit.loader';

export const router = createBrowserRouter([
    {
        path: '',
        errorElement: <Error />,
        children: [
            {
                path: '',
                element: <Navigate to='cms/login' />,
            },
            {
                path: 'cms',
                element: <Navigate to='login' />,
            },
            {
                path: '',
                element: <LoginLayout />,
                children: [
                    {
                        path: 'cms/login',
                        element: <Login />
                    },
                    {
                        path: 'cms/forgotten-password',
                        element: <ForgottenPassword />
                    },
                    {
                        path: 'cms/change-password/:token',
                        element: <ChangePassword />,
                        loader: ChangePasswordLoader
                    },
                    {
                        path: 'cms/first-login-password-change/:token',
                        element: <FirstLoginPasswordChange />,
                        loader: FirstLoginPasswordChangeLoader
                    }
                ]
            },
            {
                path: '',
                element: <CmsLayout />,
                loader: CmsLayoutLoader,
                shouldRevalidate: () => false, // route váltáskor nem futtatja le újra a loadert, csak az első betöltéskor, illetve teljes újra töltéskor
                children: [
                    {
                        path: 'cms/users/profile',
                        element: (
                            <RequireAuth role={[RoleEnum.Admin, RoleEnum.CompanyAdmin, RoleEnum.Instructor, RoleEnum.Student]}>
                                <Profile />
                            </RequireAuth>
                        ),
                        loader: ProfileLoader
                    },
                    // CMS - ADMIN ROUTES
                    {
                        path: 'cms/admin',
                        element: (
                            <RequireAuth role={[RoleEnum.Admin]}>
                                <AdminLayout/>
                            </RequireAuth>
                        ),
                        children: [
                            {
                                path: '',
                                element: <Navigate to='/cms/admin/users/list' />
                            },
                            {
                                path: 'users',
                                element: <Navigate to='/cms/admin/users/list' />
                            },
                            {
                                path: 'users/list',
                                element: <AdminUserList />,
                                loader: AdminUserListLoader
                            },
                            {
                                path: 'users/add',
                                element: <AdminUserAdd />,
                                loader: AdminUserAddLoader
                            },
                            {
                                path: 'users/edit/:id',
                                element: <AdminUserEdit />,
                                loader: AdminUserEditLoader
                            },
                            {
                                path: 'users/:id/courses',
                                element: <AdminCourseList />,
                                loader: AdminCourseListLoader
                            },
                            {
                                path: 'users/:id/courses/add',
                                element: <AdminCourseAdd />,
                                loader: AdminCourseAddLoader
                            },
                            {
                                path: 'courses/edit/:id',
                                element: <AdminCourseEdit />,
                                loader: AdminCourseEditLoader
                            },
                            /*{
                                path: 'positions',
                                element: <Navigate to='/cms/admin/positions/list' />
                            },
                            {
                                path: 'positions/list',
                                element: <AdminPositionList />,
                                loader: AdminPositionListLoader
                            },
                            {
                                path: 'positions/add',
                                element: <AdminPositionAdd />
                            },
                            {
                                path: 'positions/edit/:id',
                                element: <AdminPositionEdit />,
                                loader: AdminPositionEditLoader
                            },*/
                            {
                                path: 'companies',
                                element: <Navigate to='/cms/admin/active-companies/list' />
                            },
                            {
                                path: 'active-companies/list',
                                element: <AdminActiveCompanyList />,
                                loader: AdminActiveCompanyListLoader
                            },
                            {
                                path: 'deleted-companies/list',
                                element: <AdminDeletedCompanyList />,
                                loader: AdminDeletedCompanyListLoader
                            },
                            {
                                path: 'companies/add',
                                element: <AdminCompanyAdd />
                            },
                            {
                                path: 'companies/edit/:id',
                                element: <AdminCompanyEdit />,
                                loader: AdminCompanyEditLoader
                            },
                            {
                                path: 'companies/:id/licenses/add',
                                element: <AdminLicenseAdd />,
                                loader: AdminLicenseAddLoader
                            },
                            {
                                path: 'licenses/edit/:id',
                                element: <AdminLicenseEdit />,
                                loader: AdminLicenseEditLoader
                            },
                            /*{
                                path: 'licenses/:id/devices/add',
                                element: <AdminDeviceAdd />,
                                loader: AdminDeviceAddtLoader
                            },*/
                            {
                                path: 'devices/edit/:id',
                                element: <AdminDeviceEdit />,
                                loader: AdminDeviceEditLoader
                            },
                            {
                                path: 'companies/:id/educations/add',
                                element: <AdminEducationAdd />,
                                loader: AdminEducationAddLoader
                            },
                            {
                                path: 'educations/edit/:id',
                                element: <AdminEducationEdit />,
                                loader: AdminEducationEditLoader
                            },
                            {
                                path: 'modules',
                                element: <Navigate to='/cms/admin/modules/list' />
                            },
                            {
                                path: 'modules/list',
                                element: <AdminModuleList />,
                                loader: AdminModuleListLoader
                            },
                            {
                                path: 'modules/add',
                                element: <AdminModuleAdd />
                            },
                            {
                                path: 'modules/edit/:id',
                                element: <AdminModuleEdit />,
                                loader: AdminModuleEditLoader
                            }
                        ]
                    },
                    // CMS - COMPANY ADMIN ROUTES
                    {
                        path: 'cms/company-admin',
                        element: (
                            <RequireAuth role={[RoleEnum.CompanyAdmin]}>
                                <CompanyAdminLayout />
                            </RequireAuth>
                        ),
                        children: [
                            {
                                path: '',
                                element: <Navigate to='/cms/company-admin/users/list' />
                            },
                            {
                                path: 'users',
                                element: <Navigate to='/cms/company-admin/users/list' />
                            },
                            {
                                path: 'users/list',
                                element: <CompanyAdminUserList />,
                                loader: CompanyAdminUserListLoader
                            },
                            {
                                path: 'users/add',
                                element: <CompanyAdminUserAdd />
                            },
                            {
                                path: 'users/edit/:id',
                                element: <CompanyAdminUserEdit />,
                                loader: CompanyAdminUserEditLoader
                            },
                            {
                                path: 'educations',
                                element: <Navigate to='/cms/company-admin/educations/list' />
                            },
                            {
                                path: 'educations/list',
                                element: <CompanyAdminEducationList />,
                                loader: CompanyAdminEducationListLoader
                            },
                            {
                                path: 'educations/add',
                                element: <CompanyAdminEducationAdd />,
                                loader: CompanyAdminEducationAddLoader
                            },
                            {
                                path: 'educations/edit/:id',
                                element: <CompanyAdminEducationEdit />,
                                loader: CompanyAdminEducationEditLoader
                            },
                            {
                                path: 'users/:id/courses',
                                element: <CompanyAdminCourseList />,
                                loader: CompanyAdminCourseListLoader
                            },
                            {
                                path: 'users/:id/courses/add',
                                element: <CompanyAdminCourseAdd />,
                                loader: CompanyAdminCourseAddLoader
                            },
                            {
                                path: 'courses/edit/:id',
                                element: <CompanyAdminCourseEdit />,
                                loader: CompanyAdminCourseEditLoader
                            }
                        ]
                    },
                    // CMS - INSTRUCTOR ROUTES
                    {
                        path: 'cms/instructor',
                        element: (
                            <RequireAuth role={[RoleEnum.Instructor]}>
                                <InstructorLayout />
                            </RequireAuth>
                        ),
                        children: [
                            {
                                path: '',
                                element: <Navigate to='/cms/instructor/users/list' />
                            },
                            {
                                path: 'users',
                                element: <Navigate to='/cms/instructor/users/list' />
                            },
                            {
                                path: 'users/list',
                                element: <InstructorUserList />,
                                loader: InstructorUserListLoader
                            },
                            {
                                path: 'users/add',
                                element: <InstructorUserAdd />
                            },
                            {
                                path: 'users/edit/:id',
                                element: <InstructorUserEdit />,
                                loader: InstructorUserEditLoader
                            },
                            {
                                path: 'educations',
                                element: <Navigate to='/cms/instructor/educations/list' />
                            },
                            {
                                path: 'educations/list',
                                element: <InstructorEducationList />,
                                loader: InstructorEducationListLoader
                            },
                            {
                                path: 'educations/add',
                                element: <InstructorEducationAdd />,
                                loader: InstructorEducationAddLoader
                            },
                            {
                                path: 'educations/edit/:id',
                                element: <InstructorEducationEdit />,
                                loader: InstructorEducationEditLoader
                            },
                            {
                                path: 'users/:id/courses',
                                element: <InstructorCourseList />,
                                loader: InstructorCourseListLoader
                            },
                            {
                                path: 'users/:id/courses/add',
                                element: <InstructorCourseAdd />,
                                loader: InstructorCourseAddLoader
                            },
                            {
                                path: 'courses/edit/:id',
                                element: <InstructorCourseEdit />,
                                loader: InstructorCourseEditLoader
                            }
                        ]
                    },
                    // CMS - STUDENT ROUTES
                    {
                        path: 'cms/student',
                        element: (
                            <RequireAuth role={[RoleEnum.Student]}>
                                <StudentLayout />
                            </RequireAuth>
                        ),
                        children: [
                            {
                                path: '',
                                element: <Navigate to='/cms/student/courses/list' />
                            },
                            {
                                path: 'courses',
                                element: <Navigate to='/cms/student/courses/list' />
                            },
                            {
                                path: 'courses/list',
                                element: <StudentCourseList />,
                                loader: StudentCourseListLoader
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        path: '*',
        element: <NotFound />
    }
]);
