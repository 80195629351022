import { Header } from "antd/es/layout/layout"
import { useDispatch, useSelector } from "react-redux"
import { MenuOutlined, SearchOutlined } from "@ant-design/icons";
import './cms-header.css';
import { Button, Input } from "antd";
import { setSearchTerm, toggleSiderCollapse } from "../../../redux/reducer/antd-layout.reducer";
import { RootState } from "../../../redux/store";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

export const CmsHeader = () => {

    const dispatch = useDispatch();
    const { pageTitle, siderCollapsed, searchTerm } = useSelector((state: RootState) => state.antdLayoutState);
    const location = useLocation();
    const [isSearchInputVisible, setIsSearchInputVisible] = useState(false);

    useEffect(() => {
        dispatch(setSearchTerm(''));
    }, [location.pathname, dispatch]);

    useEffect(() => {
        const pathsWhereVisibleWithRegExp = [
            '^/cms/admin/users/list$',
            '^/cms/admin/active-companies/list$',
            '^/cms/admin/deleted-companies/list$',
            '^/cms/admin/companies/edit/.+$',
            '^/cms/admin/users/.+/courses$',
            '^/cms/admin/modules/list$',

            '^/cms/company-admin/users/.+/courses$',
            '^/cms/company-admin/educations/list$',
            '^/cms/company-admin/users/list$',

            '^/cms/instructor/users/.+/courses$',
            '^/cms/instructor/educations/list$',
            '^/cms/instructor/users/list$',

            '^/cms/student/courses/list$'
        ];

        let _isSearchInputVisible = false;

        for (const pathWithRegExp of pathsWhereVisibleWithRegExp) {
            const regExp = new RegExp(pathWithRegExp);
            if(regExp.test(location.pathname)) {
                _isSearchInputVisible = true;
                break;
            }
        }

        setIsSearchInputVisible(_isSearchInputVisible);
    }, [location.pathname]);

    return (
        <Header className='cms-header'>

                <div>

                    <Button
                        type="text"
                        icon={<MenuOutlined />}
                        onClick={() => dispatch(toggleSiderCollapse())}
                        className='button toggle-menu'
                    />

                    <span className={'title ' + (siderCollapsed ? 'collapsed' : '')}>{pageTitle}</span>

                </div>

                {
                    isSearchInputVisible && (
                        <div style={{ width: '50%' }}>

                            <Input
                                value={searchTerm}
                                onChange={(e) => dispatch(setSearchTerm(e.target.value))}
                                placeholder="Keresés"
                                style={{ verticalAlign: 'middle', fontSize: '18px', backgroundColor: '#F3F7F9' }}
                                suffix={<SearchOutlined style={{ color: '#DC0000', fontSize: '18px' }} />}
                            />

                        </div>
                    )
                }

        </Header>
    )
}