import { LoaderFunctionArgs } from "react-router-dom";
import { networkCall } from "../../../../../util/network-call/network-call";

export const InstructorCourseEditLoader = async (args: LoaderFunctionArgs) => {

    const getEducationsResponse = await networkCall('/educations');
    const getCourseResponse = await networkCall(`/courses/${ args.params.id }`);

    return { getEducationsResponse, getCourseResponse, courseId: args.params.id };

};