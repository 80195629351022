import { LoaderFunctionArgs } from "react-router-dom";
import { networkCall } from "../../../../util/network-call/network-call";

export const ChangePasswordLoader = async (args: LoaderFunctionArgs) => {

    const response = await networkCall('/users/check-password-reset', {
        method: 'POST',
        body: {
            token: args.params.token
        }
    });

    return { token: args.params.token, response };

};