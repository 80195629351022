import { ConfigProvider, Layout } from "antd";
import { Content } from "antd/es/layout/layout";
import { Navigate, Outlet, useLoaderData } from "react-router-dom";
import { CmsHeader } from "../../../component/cms/header/cms-header";
import { CmsSider } from "../../../component/cms/sider/cms-sider";
import './cms.layout.css';
import { useDispatch } from "react-redux";
import { setMe } from "../../../redux/reducer/session.reducer";
import { clearAlertMessage, showAlertMessage } from "../../../redux/reducer/alert-message.reducer";
import hu_HU from 'antd/locale/hu_HU';


export const CmsLayout = () => {

    const cmsLoaderData: any = useLoaderData();
    const dispatch = useDispatch();

    if(cmsLoaderData.response && cmsLoaderData?.response?.status === 200) {
        dispatch(setMe(cmsLoaderData.response.data));
    } else {

        dispatch(clearAlertMessage());
        dispatch(showAlertMessage({
            alertMessageText: 'Hiba történt a felhasználó azonosítása közben, kérjük jelentkezzen be.',
            alertMessageType: 'error'
        }));
        return <Navigate to={'/cms/login'} />;

    }
    
    return (
        <ConfigProvider locale={hu_HU}>
            <Layout className='default-layout'>

                <CmsHeader/>

                <Layout>
                    
                    <CmsSider/>

                    <Content className='content'>
                        <Outlet/>
                    </Content>

                </Layout>

            </Layout>
        </ConfigProvider>
    )
}