import { Card } from 'antd';
import { useDispatch } from 'react-redux';
import { setOpenKeys, setSelectedMenuItemKeys } from '../../../../../redux/reducer/antd-layout.reducer';
import { useEffect } from 'react';
import { Form, Input, Button } from 'antd';
import { networkCall } from '../../../../../util/network-call/network-call';
import { showDefaultErrorMessages } from '../../../../../util/show-default-error-message/show-default-error-messages';
import { AlertMessageState, clearAlertMessage, showAlertMessage } from '../../../../../redux/reducer/alert-message.reducer';
import { tranformAPIValidationErrorToAntDValidationMessage } from '../../../../../util/validation/transform-api-validation-errors-to-antd-validation-messsages';
import { Link, useLoaderData } from 'react-router-dom';
import { DeleteOutlined } from '@ant-design/icons';


export const AdminDeviceEdit = () => {

    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const loaderData: any = useLoaderData();

    /*const onDeleteClick = async(e: any) => {

        e.preventDefault();
        e.stopPropagation();

        if(window.confirm('Biztosan törli az eszközt?')) {

            const response = await networkCall(`/devices/${ loaderData.id }`, {
                method: 'DELETE'
            });

            const messageShowed = showDefaultErrorMessages(response);
            if(messageShowed) return;

            if(response.status === 204) {
                dispatch(showAlertMessage({
                    alertMessageType: 'success',
                    alertMessageText: 'Törlés sikeres.'
                }));
                navigate(`/cms/admin/licenses/edit/${ loaderData.response.data.device.license.id }`);
                return;
            }

            dispatch(clearAlertMessage());
            const alertMessageState: AlertMessageState = {
                alertMessageType: 'error',
                alertMessageText: 'Ismeretlen hiba történt. Kérjük próbálja újra!'
            };

            if(response.status === 404) {
                alertMessageState.alertMessageText = 'Törlés sikertelen, a törlendő hozzárendelés nem létezik.';
            }

            dispatch(showAlertMessage(alertMessageState));

        }

    };*/

    const onFinish = async (values: any) => {
        const response = await networkCall(`/devices/${ loaderData.id }`, {
            method: 'PUT',
            body: {
                deviceId: values.deviceId
            }
        });

        const messageShowed = showDefaultErrorMessages(response);

        if(messageShowed) return;

        dispatch(clearAlertMessage());
        const alertMessageState: AlertMessageState = {
            alertMessageType: 'error',
            alertMessageText: 'Ismeretlen hiba történt. Kérjük próbálja újra!'
        };

        if (response.status === 200 && response.data.device !== undefined) {

            alertMessageState.alertMessageText = 'Mentés sikeres.';
            alertMessageState.alertMessageType = 'success';

        }else if(response.status === 400){

            switch (response.data.code) {

                case 2000:
                    alertMessageState.alertMessageText = 'Hibásan megadott beviteli mezők.';
                    const validationErrors = tranformAPIValidationErrorToAntDValidationMessage(response.data?.data);
                    form.setFields(validationErrors);
                    break;

            }

        }else if(response.status === 404){

            switch (response.data.code) {

                case 7000:
                    alertMessageState.alertMessageText = 'A módosítandó eszköz nem létezik.';
                    break;

            }

        } else {
            alertMessageState.alertMessageText = 'Ismeretlen hiba történt. Kérjük próbálja újra!';
        }

        dispatch(showAlertMessage(alertMessageState));

    }

    useEffect(() => {

        const messageShowed = showDefaultErrorMessages(loaderData.response);
        if(messageShowed) return;

        if(loaderData.response.status === 200 && loaderData.response.data && loaderData.response.data.device) {
            form.setFieldValue('name', loaderData.response.data.device.name);
            form.setFieldValue('deviceId', loaderData.response.data.device.deviceId);
            return;
        }

        dispatch(clearAlertMessage());
        const alertMessageState: AlertMessageState = {
            alertMessageType: 'error',
            alertMessageText: 'Ismeretlen hiba történt a pozíció lekérése során. Kérjük próbálja újra!'
        };

        dispatch(showAlertMessage(alertMessageState));

    }, []);

    useEffect(() => {
        dispatch(setSelectedMenuItemKeys(['active-companies']));
        dispatch(setOpenKeys(['companies']));
    }, []);

    return (
        <Card
            title='Eszköz módosítása'
        >
            
            <Form
                form={form}
                onFinish={onFinish}
                layout='vertical'
            >
                
                <Form.Item
                    label='Név'
                    name='name'
                >
                    <Input disabled style={{ fontWeight: 'bold' }}/>
                </Form.Item>
                <Form.Item
                    label='Aznosító'
                    name='deviceId'
                    rules={[{required: true, message: 'Azonosító megadása kötelező!'}]}
                >
                    <Input />
                </Form.Item>

                <Form.Item>
                    <Link to={`/cms/admin/licenses/edit/${ loaderData.response.data.device.license.id }`}>
                        <Button type="default" htmlType="button">
                            Vissza
                        </Button>
                    </Link>
                    <Button type='primary' htmlType='submit' style={{float: 'right'}}>
                        Mentés
                    </Button>
                </Form.Item>
                        
            </Form>

        </Card>
    )
}