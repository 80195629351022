import { LoaderFunctionArgs } from "react-router-dom";
import { networkCall } from "../../../../../util/network-call/network-call";

export const AdminLicenseEditLoader = async (args: LoaderFunctionArgs) => {
    
    const getLicenseResponse = await networkCall(`/licenses/${args.params.id}`);

    return {
        getLicenseResponse,
        id: args.params.id
    };

};