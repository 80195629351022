import { Button, Card, Input, Table } from 'antd';
import './admin-education-list.css';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useRevalidator } from 'react-router-dom';
import { showDefaultErrorMessages } from '../../../../../util/show-default-error-message/show-default-error-messages';
import { AlertMessageState, clearAlertMessage, showAlertMessage } from '../../../../../redux/reducer/alert-message.reducer';
import { SearchOutlined } from '@ant-design/icons';
import { networkCall } from '../../../../../util/network-call/network-call';
import { formatTimestampToDatetime } from '../../../../../util/date/format-timestamp-to-datetime';
import { FilterDropdownProps } from 'antd/es/table/interface';
import { Education } from '../../../../../constant/type/education.type';
import { RootState } from '../../../../../redux/store';
import { DeleteButton } from '../../../../button/delete-button/delete-button';

type TableDataType = {
    key: React.Key;
    name: string;
    created: number;
    moduleName?: string;
}

type AdminEducationListProps = {
    companyId: number;
    educations: Education[];
    reValidate: Function;
}

export const AdminEducationList = ({ companyId, educations, reValidate }: AdminEducationListProps) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const revalidator = useRevalidator();
    const { searchTerm } = useSelector((state: RootState) => state.antdLayoutState);

    const handleDeleteClick = async (e: React.MouseEvent, id: React.Key) => {
        e.preventDefault();
        e.stopPropagation();

        if(window.confirm('Biztosan törli az oktatást?')) {

            dispatch(clearAlertMessage());
            dispatch(showAlertMessage({
                alertMessageType: 'loading',
                alertMessageText: 'Kérés folyamatban...'
            }));

            const response = await networkCall(`/educations/${ id }`, {
                method: 'DELETE'
            });

            const messageShowed = showDefaultErrorMessages(response);
            if(messageShowed) return;

            if(response.status === 204) {
                revalidator.revalidate();
                dispatch(showAlertMessage({
                    alertMessageType: 'success',
                    alertMessageText: 'Törlés sikeres.'
                }));
                return;
            }

            dispatch(clearAlertMessage());
            const alertMessageState: AlertMessageState = {
                alertMessageType: 'error',
                alertMessageText: 'Ismeretlen hiba történt. Kérjük próbálja újra!'
            };

            if(response.status === 404) {
                alertMessageState.alertMessageText = 'Törlés sikertelen, a törlendő oktatás nem létezik.';
            }

            dispatch(showAlertMessage(alertMessageState));

        }

    }
    
    const columns = [
        {
            title: 'Oktatás neve',
            dataIndex: 'name',
            sorter: (a: TableDataType, b: TableDataType) => String(a.name).localeCompare(b.name),
            filterDropdown: ({ confirm, setSelectedKeys, selectedKeys }: FilterDropdownProps) => {
                return <Input
                    autoFocus
                    value={selectedKeys[0]}
                    placeholder='Keresés...'
                    onInput={(e: any) => {
                        setSelectedKeys( e.target.value ? [e.target.value] : [] );
                        confirm({ closeDropdown: false });
                    }}
                />;
            },
            filterIcon: () => {
                return <SearchOutlined />;
            },
            onFilter: (value: any, record: TableDataType) => {
                return record.name.toLowerCase().includes(value.toLowerCase());
            }
        },
        {
            title: 'Létrehozva',
            sorter: (a: TableDataType, b: TableDataType) => a.created - b.created,
            render: (record: TableDataType) => {
                return formatTimestampToDatetime(record.created);
            }
        },
        {
            title: 'Modul',
            dataIndex: 'moduleName',
            sorter: (a: TableDataType, b: TableDataType) => a.created - b.created
        },
        {
            title: 'Törlés',
            render: (record: TableDataType) => {
                return <DeleteButton onClick={(e) => handleDeleteClick(e, record.key)}/>;
            }
        }
    ];
    const [data, setData] = useState<TableDataType[]>([]);

    useEffect(() => {

        if(educations) {
            const tableData: TableDataType[] = [];
            for (const education of educations) {

                if(education.name.toLowerCase().includes(searchTerm.toLowerCase())) {
                    tableData.push({
                        key: education.id,
                        name: education.name,
                        created: education.created,
                        moduleName: education?.module?.name
                    });
                }
    
            }

            setData(tableData);
            return;
        }

    }, [educations, searchTerm]);

    return (
        <Card
            title='Oktatások'
            extra={<Link to={`/cms/admin/companies/${companyId}/educations/add`}><Button type='primary' className='fw-bold'>Új oktatás hozzáadása</Button></Link>}
        >
            <Table
                className='table-responsive data-table'
                columns={columns}
                dataSource={data}
                bordered
                rowClassName={(record, name) => 'admin-education-table-row'}
                onRow={(row, rowIndex) => {
                    return {
                        onClick: (e) => {
                            navigate(`/cms/admin/educations/edit/${row.key}`);
                        }
                    }
                }}
                pagination={{ position: ['bottomCenter'], pageSize: 10}}
            />
        </Card>
    )
}