import { Form, Input, Button } from 'antd';
import { networkCall } from '../../../util/network-call/network-call';
import { useDispatch } from 'react-redux';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { AlertMessageState, clearAlertMessage, showAlertMessage } from '../../../redux/reducer/alert-message.reducer';
import { tranformAPIValidationErrorToAntDValidationMessage } from '../../../util/validation/transform-api-validation-errors-to-antd-validation-messsages';
import { useEffect } from 'react';
import { showDefaultErrorMessages } from '../../../util/show-default-error-message/show-default-error-messages';


export const ChangePassword = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const loaderData: any = useLoaderData();

    useEffect(() => {

        const messageShowed = showDefaultErrorMessages(loaderData.response);

        if(messageShowed) return;

        dispatch(clearAlertMessage());
        const alertMessageState: AlertMessageState = {
            alertMessageType: 'error',
            alertMessageText: 'Ismeretlen hiba történt. Kérjük próbálja újra!'
        };

        if (loaderData.response.status === 200) return;

        if (loaderData.response.status === 404) {

            switch (loaderData.response.data.code) {

                case 4000:
                    alertMessageState.alertMessageText = 'A jelszó módosításának határideje lejárt vagy rosszul másolta be a böngésző címsorába a linket.';
                    break;
                case 3001:
                    alertMessageState.alertMessageText = 'A felhasználó nem létezik, amihez a jelszót akarja változtatni.';

            }
           
        }
        
        dispatch(showAlertMessage(alertMessageState));

        navigate('/cms/login');

    }, [dispatch, loaderData, navigate]);

    const onFinish = async (values: any) => {

        if(values.password !== values.passwordAgain){

            form.setFields([{
                    name: 'password',
                    errors: ['A jelszavak nem egyeznek meg.']
                },
                {
                    name: 'passwordAgain',
                    errors: ['A jelszavak nem egyeznek meg.']
                }
            ]);

            return;
        }

        dispatch(clearAlertMessage());
        dispatch(showAlertMessage({
            alertMessageType: 'loading',
            alertMessageText: 'Kérés folyamatban...'
        }));

        const response = await networkCall('/users/change-password', {
            method: 'POST',
            body: {
                token: loaderData.token,
                password: values.password
            }
        });

        const messageShowed = showDefaultErrorMessages(response);
        if(messageShowed) return;

        dispatch(clearAlertMessage());
        const alertMessageState: AlertMessageState = {
            alertMessageType: 'error',
            alertMessageText: 'Ismeretlen hiba történt. Kérjük próbálja újra!'
        };

        if (response.status === 201) {

            navigate('/cms/login');
            alertMessageState.alertMessageType = 'success';
            alertMessageState.alertMessageText = 'A jelszavát sikeresen megváltoztatta.';

        }else if(response.status === 400){

            switch (response.data.code) {

                case 2000:
                    alertMessageState.alertMessageText = 'Hibásan megadott beviteli mezők.';
                    const validationErrors = tranformAPIValidationErrorToAntDValidationMessage(response.data?.data);
                    form.setFields(validationErrors);
                    break;

            }

        }else if(response.status === 404){
            switch (loaderData.response.data.code) {

                case 4000:
                    alertMessageState.alertMessageText = 'A jelszó módosításának határideje lejárt vagy rosszul másolta be a böngésző címsorába a linket.';
                    break;
                case 3001:
                    alertMessageState.alertMessageText = 'A felhasználó nem létezik, amihez a jelszót akarja változtatni.';

            }
        }else{
            alertMessageState.alertMessageText = 'Ismeretlen hiba történt. Kérjük próbálja újra!';
        }

        dispatch(showAlertMessage(alertMessageState));

    }

    return (
        <div className="box">

            <div className='body'>
                
                <Form
                    form={form}
                    onFinish={onFinish}
                >
                        
                    <Form.Item
                        label='Új jelszó'
                        name='password'
                        rules={[
                            {required: true, message: 'A mező megadása kötelező.'}
                        ]}
                        
                    >
                        <Input.Password placeholder='jelszó'/>
                    </Form.Item>

                    <Form.Item
                        label='Új jelszó újra'
                        name='passwordAgain'
                        rules={[
                            {required: true, message: 'A mező megadása kötelező.'}
                        ]}
                        
                    >
                        <Input.Password placeholder='jelszó újra'/>
                    </Form.Item>

                    <Form.Item className='text-center'>
                        <Button type="primary" htmlType="submit">
                            Jelszó megváltoztatása
                        </Button>
                    </Form.Item>
                        
                </Form>

            </div>
            
        </div>
    )
}