import { Outlet } from "react-router-dom";
import './login.layout.css';

export const LoginLayout = () => {
    
    return (
        <div className="login-layout">

                <Outlet />

        </div>
    )
}