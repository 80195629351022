import { LoaderFunctionArgs } from "react-router-dom";
import { networkCall } from "../../../../../util/network-call/network-call";

export const AdminCourseEditLoader = async (args: LoaderFunctionArgs) => {

    const getCourseResponse = await networkCall(`/courses/${ args.params.id }`);
    let getEducationsResponse;
    if(getCourseResponse?.status === 200 && getCourseResponse?.data?.course?.user) {
        getEducationsResponse = await networkCall('/educations', { params: { userId: getCourseResponse.data.course.user.id } });
    }

    return { getCourseResponse, getEducationsResponse, courseId: args.params.id };

};