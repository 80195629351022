import { LoaderFunctionArgs } from "react-router-dom";
import { networkCall } from "../../../../../util/network-call/network-call";

export const AdminUserEditLoader = async (args: LoaderFunctionArgs) => {

    const getCompaniesResponse = await networkCall('/companies');
    const getUserResponse = await networkCall(`/users/${ args.params.id }`);

    return { getCompaniesResponse, getUserResponse, id: args.params.id };

};