import { /*useEffect, useState,*/ useSyncExternalStore } from "react";

export const useOnlineStatus = () => {
    const isOnline = useSyncExternalStore(callback => {
        window.addEventListener('offline', callback);
        window.addEventListener('online', callback);
        return () => {
            window.removeEventListener('offline', callback);
            window.removeEventListener('online', callback);
        };
    }, () => navigator.onLine);
    /*const [isOnline, setIsOnline] = useState(true);
    
    useEffect(() => {

        const updateIsOnline = () => {
            setIsOnline(navigator.onLine);
        };

        window.addEventListener('offline', updateIsOnline);
        window.addEventListener('online', updateIsOnline);

        return () => {
            window.removeEventListener('offline', updateIsOnline);
            window.removeEventListener('online', updateIsOnline);
        };

    }, []);*/

    return isOnline;
};