import { Card, Checkbox, Divider, Input, Select } from 'antd';
import { useDispatch } from 'react-redux';
import { setOpenKeys, setPageTitle, setSelectedMenuItemKeys } from '../../../../../redux/reducer/antd-layout.reducer';
import { useEffect, useState } from 'react';
import { Form, Button } from 'antd';
import { networkCall } from '../../../../../util/network-call/network-call';
import { showDefaultErrorMessages } from '../../../../../util/show-default-error-message/show-default-error-messages';
import { AlertMessageState, clearAlertMessage, showAlertMessage } from '../../../../../redux/reducer/alert-message.reducer';
import { tranformAPIValidationErrorToAntDValidationMessage } from '../../../../../util/validation/transform-api-validation-errors-to-antd-validation-messsages';
import { Link, useLoaderData, useNavigate } from 'react-router-dom';
import { ModulePart } from '../../../../../constant/type/module-part.type';
import { ModulePartProperty } from '../../../../../constant/type/module-part-property.type';
import './admin-education-edit.css';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { AvailableModulePart } from '../../../../../constant/type/available-module-part.type';
import { EducationModulePart } from '../../../../../constant/type/education-module-part.type';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { SelectOption } from '../../../../../constant/type/select-option.type';
import { License } from '../../../../../constant/type/license.type';
import { LicenseTypeEnum } from '../../../../../constant/enum/license-type.enum';
import { formatTimestampToDate } from '../../../../../util/date/format-timestamp-to-date';

type DisabledModulePartProperties = {
    modulePartid: number;
    modulePartProperties: {
        id: number;
        disabled: boolean;
    }[];
}

export const AdminEducationEdit = () => {

    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const loaderData: any = useLoaderData();
    const [moduleParts, setModuleParts] = useState<ModulePart[]>([]);
    const [modulePartProperties, setModulePartProperties] = useState<ModulePartProperty[]>([]);
    const [availableModuleParts, setAvailableModuleParts] = useState<AvailableModulePart[]>([]);
    const [educationModuleParts, setEducationModuleParts] = useState<EducationModulePart[]>([]);
    const [compareWithLicenses, setCompareWithLicenses] = useState(false);
    const [licenseoptions, setLicenseoptions] = useState<SelectOption[]>([]);
    const [licenses, setLicenses] = useState<License[]>([]);
    const [selectedLicenseId, setSelectedLicenseId] = useState<number | null>(null);
    const [disabledModulePartProperties, setDisabledModulePartProperties] = useState<DisabledModulePartProperties[]>([]);
    const [reRender, setReRender] = useState(0);

    let checkIfThereAnyEmptyLicenseWithEducationCombined = true;

    const isModulePartAvailable = (modulePartId: number): boolean => {
        return availableModuleParts.filter(availableModulePart => availableModulePart?.modulePart?.id === modulePartId).length !== 0;
    }

    const isModulePartAvailableInEducation = (modulePartId: number): boolean => {
        return educationModuleParts.find(educationModulePart => educationModulePart?.modulePart?.id === modulePartId) ? true : false;
    }

    const isCombinationOfModulePartAndModulePartPropertyAvailableInEducation = (modulePartId: number, modulePartPropertyId: number): boolean => {
        if(!isModulePartAvailableInEducation(modulePartId)) return false;

        for (const educationModulePart of educationModuleParts) {

            if(educationModulePart?.modulePart?.id === modulePartId) {
                if(educationModulePart?.educationModulePartModulePartProperties?.length === 0) return false;

                return educationModulePart.educationModulePartModulePartProperties?.find(educationModulePartModulePartProperty => educationModulePartModulePartProperty.modulePartProperty?.id === modulePartPropertyId) ? true : false;
            }
            
        }

        return false;
    }

    const createEducationModulePartModulePartPropertyTDs = (modulePartId: number) => {

        // bennt van-e a licenszhez tartozó module partok között a paraméterben megadott ID-jú module part
        const availableModulePart = availableModuleParts.find(availableModulePart => availableModulePart?.modulePart?.id === modulePartId);

        // ha nincs benne, vagyis nem elérhető ebben a licenszben a modul part, akkor "X"-et renderelünk
        if(availableModulePart === undefined) {

            return modulePartProperties.map(modulePartProperty => (
                <td key={`${modulePartId}.${modulePartProperty.id}`} className='text-center'>
                    <CloseOutlined style={{ color: 'red', fontSize: '20px' }} />
                </td>
            ));

        } else {
            const educationModulePartModulePartPropertyTDs: React.JSX.Element[] = [];

            for (const modulePartProperty of modulePartProperties) {
                // kivesszük a licenszhez tartozó modulePartProperty-t
                const availableModulePartModulePartProperty =
                    availableModulePart?.availableModulePartModulePartProperties
                    ?.find(availableModulePartModulePartProperty => availableModulePartModulePartProperty?.modulePartProperty?.id === modulePartProperty.id);

                if(availableModulePartModulePartProperty) {

                    educationModulePartModulePartPropertyTDs.push((
                        <td key={`${modulePartId}.${ modulePartProperty.id }`} className='text-center'>
                            <Form.Item
                                name={`educationModulePartModulePartProperty.${modulePartId}.${modulePartProperty.id}`}
                                valuePropName="checked"
                                initialValue={isCombinationOfModulePartAndModulePartPropertyAvailableInEducation(modulePartId, modulePartProperty.id)}
                            >
                                <Checkbox onChange={() => { if(compareWithLicenses && selectedLicenseId) setReRender(reRender + 1); }} disabled={isModulePartPropertyCheckboxDisabled(modulePartId, modulePartProperty.id)} />
                            </Form.Item>
                        </td>
                    ));

                } else {
                    educationModulePartModulePartPropertyTDs.push((
                        <td key={`${modulePartId}.${ modulePartProperty.id }`} className='text-center'>
                            <CloseOutlined style={{ color: 'red', fontSize: '20px', verticalAlign: '-webkit-baseline-middle' }} />
                        </td>
                    ));
                }

            }

            return educationModulePartModulePartPropertyTDs;
        }

    }

    const modulePartAvailabilityChange = (e: CheckboxChangeEvent, modulePartId: number) => {
        
        if(e.target.checked) {
    
            for (const modulePartProperty of modulePartProperties) {
                setDisabledModulePartPropertyCheckbox(modulePartId, modulePartProperty.id, false);
            }
            
        } else { // hamis esetén false-ra állítjuk az adott module part-hoz tartozó üsszes module part property chechkboxot

            for (const modulePartProperty of modulePartProperties) {
                form.setFieldValue(`educationModulePartModulePartProperty.${modulePartId}.${modulePartProperty.id}`, false);
                
                setDisabledModulePartPropertyCheckbox(modulePartId, modulePartProperty.id, true);
            }
    
        }

        if(compareWithLicenses && selectedLicenseId) setReRender(reRender + 1);
    }

    const isModulePartAvailableInSelectedLicenseAndInEducation = (modulePartId: number) => {
        const availableInEducation = form.getFieldValue(`modulePartAvailable.${modulePartId}`);

        if(selectedLicenseId && availableInEducation === true) {
            for (const license of licenses) {
                if(license.id === selectedLicenseId) {
                    for (const licenseModulePart of license.licenseModuleParts) {
                        if(licenseModulePart?.modulePart?.id === modulePartId) return true;
                    }
                    break;
                }
            }
        }

        return false;
    };

    const createEducationModulePartModulePartPropertyAndLicenseModulePartModulePartPropertyTDs = (modulePartId: number) => {

        const _isModulePartAvailableInSelectedLicenseAndInEducation = isModulePartAvailableInSelectedLicenseAndInEducation(modulePartId);
    
        // ha benne van a module part a licencben és az oktatásban is
        if(_isModulePartAvailableInSelectedLicenseAndInEducation) {

            const educationModulePartModulePartPropertyAndLicenseModulePartModulePartPropertyTDs: React.JSX.Element[] = [];

            for (const modulePartProperty of modulePartProperties) {

                const IsModulePartModulePartPropertyAvailableInEducation = form.getFieldValue(`educationModulePartModulePartProperty.${modulePartId}.${modulePartProperty.id}`);

                if(IsModulePartModulePartPropertyAvailableInEducation) {

                    const selectedLicenseModulePart = licenses.find(license => license.id === selectedLicenseId)?.licenseModuleParts.find(licenseModulePart => licenseModulePart?.modulePart?.id === modulePartId);

                    if(selectedLicenseModulePart) {

                        const _licenseModulePartModulePartProperty = selectedLicenseModulePart?.licenseModulePartModulePartProperties?.find(licenseModulePartModulePartProperty => licenseModulePartModulePartProperty.modulePartProperty?.id === modulePartProperty.id);

                        if(_licenseModulePartModulePartProperty) {

                            educationModulePartModulePartPropertyAndLicenseModulePartModulePartPropertyTDs.push((
                                <td key={`${selectedLicenseId === null ? '0' : selectedLicenseId}.${modulePartId}.${ modulePartProperty.id }`} className='text-center'>
                                    <CheckOutlined style={{ color: 'green', fontSize: '20px' }} />
                                </td>
                            ));
    
                        } else {

                            educationModulePartModulePartPropertyAndLicenseModulePartModulePartPropertyTDs.push((
                                <td key={`${selectedLicenseId === null ? '0' : selectedLicenseId}.${modulePartId}.${ modulePartProperty.id }`} className='text-center'>
                                    <CloseOutlined style={{ color: 'red', fontSize: '20px', verticalAlign: '-webkit-baseline-middle' }} />
                                </td>
                            ));

                        }

                    } else {

                        educationModulePartModulePartPropertyAndLicenseModulePartModulePartPropertyTDs.push((
                            <td key={`${selectedLicenseId === null ? '0' : selectedLicenseId}.${modulePartId}.${ modulePartProperty.id }`} className='text-center'>
                                <CloseOutlined style={{ color: 'red', fontSize: '20px', verticalAlign: '-webkit-baseline-middle' }} />
                            </td>
                        ));

                    }

                } else {

                    educationModulePartModulePartPropertyAndLicenseModulePartModulePartPropertyTDs.push((
                        <td key={`${selectedLicenseId === null ? '0' : selectedLicenseId}.${modulePartId}.${ modulePartProperty.id }`} className='text-center'>
                            <CloseOutlined style={{ color: 'red', fontSize: '20px', verticalAlign: '-webkit-baseline-middle' }} />
                        </td>
                    ));

                }

            }

            return educationModulePartModulePartPropertyAndLicenseModulePartModulePartPropertyTDs;

        } else {
            
            return modulePartProperties.map(modulePartProperty => (
                <td key={`${selectedLicenseId === null ? '0' : selectedLicenseId}.${modulePartId}.${modulePartProperty.id}`} className='text-center'>
                    <CloseOutlined style={{ color: 'red', fontSize: '20px' }} />
                </td>
            ));

        }

    }

    const isModulePartPropertyCheckboxDisabled = (modulePartId: number, modulePartPropertyId: number): boolean => {

        for (const disabledModulePartProperty of disabledModulePartProperties) {
            if(disabledModulePartProperty.modulePartid === modulePartId) {
                for (const modulePartProp of disabledModulePartProperty.modulePartProperties) {
                    if(modulePartProp.id === modulePartPropertyId) return modulePartProp.disabled;
                }
                break;
            }
        }

        return false;

    };

    const setDisabledModulePartPropertyCheckbox = (modulePartId: number, modulePartPropertyId: number, disabled = true) => {
        
        setDisabledModulePartProperties(_disabledModulePartProperties => {
            const _disabledModulePartProps: DisabledModulePartProperties[] = JSON.parse(JSON.stringify(_disabledModulePartProperties));
            for (const _disabledModulePartProp of _disabledModulePartProps) {
                if(_disabledModulePartProp.modulePartid === modulePartId) {
                    for (const modulePartProp of _disabledModulePartProp.modulePartProperties) {
                        if(modulePartProp.id === modulePartPropertyId) {
                            modulePartProp.disabled = disabled;
                            break;
                        }
                    }
                    break;
                }
            }

            return _disabledModulePartProps;
        });
        
    }

    const onFinish = async (values: any) => {

        const moduleParts: any[] = [];
        
        for (const key in values) {
            if(key.startsWith('modulePartAvailable.')) {
                const modulePartAvailable = form.getFieldValue(key);
                if(!modulePartAvailable) continue;

                const modulePartId = parseInt(key.split('.')[1], 10);
                const modulePartPropertyIds: number[] = [];

                for (const modulePartProperty of modulePartProperties) {
                    const checked = form.getFieldValue(`educationModulePartModulePartProperty.${modulePartId}.${modulePartProperty.id}`);
                    if(checked) modulePartPropertyIds.push(modulePartProperty.id);
                }

                moduleParts.push({
                    id: modulePartId,
                    modulePartPropertyIds
                });
            }
        }

        dispatch(clearAlertMessage());
        dispatch(showAlertMessage({
            alertMessageType: 'loading',
            alertMessageText: 'Kérés folyamatban...'
        }));

        const response = await networkCall(`/educations/${ loaderData.id }`, {
            method: 'PUT',
            body: {
                name: values.name,
                checkIfThereAnyEmptyLicenseWithEducationCombined,
                moduleParts
            }
        });

        const messageShowed = showDefaultErrorMessages(response);

        if(messageShowed) return;

        dispatch(clearAlertMessage());
        const alertMessageState: AlertMessageState = {
            alertMessageType: 'error',
            alertMessageText: 'Ismeretlen hiba történt. Kérjük próbálja újra!'
        };

        if (response.status === 200 && response.data.education !== undefined) {

            alertMessageState.alertMessageText = 'Mentés sikeres.';
            alertMessageState.alertMessageType = 'success';
            checkIfThereAnyEmptyLicenseWithEducationCombined = true;

        }else if(response.status === 400){

            switch (response.data?.code) {

                case 2000:
                    alertMessageState.alertMessageText = 'Hibásan megadott beviteli mezők.';
                    const validationErrors = tranformAPIValidationErrorToAntDValidationMessage(response.data?.data);
                    form.setFields(validationErrors);
                    break;

            }

        } else if(response.status === 404){

            switch (response.data?.code) {

                case 10000:
                case 11000:
                    alertMessageState.alertMessageText = 'A hozzáadandó modul elem nem található.';
                    break;
                case 12000:
                    alertMessageState.alertMessageText = 'A kért oktatás nem található.';
                    break;
                case 10002:
                    alertMessageState.alertMessageText = `A hozzáadandó modul elem nincs a licencekben hozzáadva: ${response?.data?.data?.id} - ${response?.data?.data?.name}`;
                    break;
                case 11002:
                    alertMessageState.alertMessageText = `A hozzáadandó modul elem nincs a licencekben hozzáadva: ${response?.data?.data?.id} - ${response?.data?.data?.name}`;
                    break;
                case 12001:
                    if(window.confirm('Található olyan licenc, amelyre nézve az oktatás annyira le van szűkítve, hogy nem marad modul elem az adott licencre nézve. ' +
                        'Tehát lesz olyan eszköz, ami nem jelenít meg semmit az oktatás elvégzésekor. Ennek ellenére menti a változatásokat?')) {

                            checkIfThereAnyEmptyLicenseWithEducationCombined = false;
                            form.submit();
                            return;

                    }

                    return;

            }

        }else{
            alertMessageState.alertMessageText = 'Ismeretlen hiba történt. Kérjük próbálja újra!';
        }

        dispatch(showAlertMessage(alertMessageState));

    }

    useEffect(() => {

        const messageShowed = showDefaultErrorMessages(loaderData.getEducationResponse);
        if(messageShowed) {
            navigate('/cms/admin/active-companies/list');
            return;
        }

        const alertMessageState: AlertMessageState = {
            alertMessageType: 'error',
            alertMessageText: 'Ismeretlen hiba történt a cég lekérése során. Kérjük próbálja újra!'
        };

        if(loaderData.getEducationResponse?.status === 200 && loaderData.getEducationResponse?.data?.education) {
            
            form.setFieldValue('name', loaderData.getEducationResponse.data.education.name);
            
            if(loaderData.getEducationResponse.data?.education?.module?.moduleParts) {
                setModuleParts(loaderData.getEducationResponse.data.education.module.moduleParts);
            }
            
            if(loaderData.getEducationResponse.data?.education?.module?.modulePartProperties) {
                setModulePartProperties(loaderData.getEducationResponse.data.education.module.modulePartProperties);
            }

            if(loaderData.getEducationResponse.data?.availableModuleParts) {
                setAvailableModuleParts(loaderData.getEducationResponse.data.availableModuleParts);
            }

            if(loaderData.getEducationResponse.data?.education?.educationModuleParts) {
                setEducationModuleParts(loaderData.getEducationResponse.data.education.educationModuleParts);
            }

            if(loaderData.getEducationResponse?.data?.education?.module.moduleParts &&
                loaderData.getEducationResponse.data?.education?.module?.modulePartProperties &&
                loaderData.getEducationResponse.data?.education?.educationModuleParts) {

                    const _disabledModulePartProperties: DisabledModulePartProperties[] = [];
                    for (const modulePart of loaderData.getEducationResponse.data.education.module.moduleParts as ModulePart[]) {
                        
                        const _modulePartProperties: { id: number, disabled: boolean }[] = [];
                        if(loaderData.getEducationResponse.data.education.educationModuleParts.filter((item: any) => item?.modulePart?.id === modulePart.id).length === 0) {
                            
                            for (const modulePartProperty of loaderData.getEducationResponse.data.education.module.modulePartProperties as ModulePartProperty[]) {
                                _modulePartProperties.push({
                                    id: modulePartProperty.id,
                                    disabled: true
                                });
                            }

                        } else {

                            for (const modulePartProperty of loaderData.getEducationResponse.data.education.module.modulePartProperties as ModulePartProperty[]) {
                                _modulePartProperties.push({
                                    id: modulePartProperty.id,
                                    disabled: false
                                });
                            }

                        }

                        _disabledModulePartProperties.push({
                            modulePartid: modulePart.id,
                            modulePartProperties: _modulePartProperties
                        });
                    }

                    setDisabledModulePartProperties(_disabledModulePartProperties);

            }

            if(loaderData.getEducationResponse.data?.licenses) {
                setLicenses(loaderData.getEducationResponse.data?.licenses);
                const selectOptions: SelectOption[] = []; 
                for (const license of loaderData.getEducationResponse.data?.licenses as License[]) {

                    let type = '';
                    switch(license.type) {
                        case LicenseTypeEnum.Headset: type = 'Tabletes'; break;
                        case LicenseTypeEnum.Tablet: type = 'Headsetes'; break;
                        case LicenseTypeEnum.Combined: type = 'Kombinált (Headset + Tablet)';
                    }

                    let validityDate = '';
                    if(license.licenseStartDate && license.licenseEndDate) {
                        const startDate = new Date(license.licenseStartDate * 1000);
                        const endDate = new Date(license.licenseEndDate * 1000);

                        startDate.setHours(0, 0, 0, 0);
                        endDate.setHours(0, 0, 0, 0);

                        validityDate = `${formatTimestampToDate(startDate.getTime() / 1000)} - ${formatTimestampToDate(endDate.getTime() / 1000)}`;
                        
                    }
                
                    selectOptions.push({
                        label: `${loaderData.getEducationResponse.data?.education?.module?.name} (${type}, ${validityDate})`,
                        value: license.id
                    });
                }
                setLicenseoptions(selectOptions);
            }

            return;

        } else if(loaderData.getEducationResponse?.status === 404){

            switch (loaderData.getEducationResponse?.data?.code) {

                case 12000:
                    alertMessageState.alertMessageText = 'A kért oktatás nem található.';

            }

        }

        navigate('/cms/admin/active-companies/list');
        dispatch(clearAlertMessage());
        dispatch(showAlertMessage(alertMessageState));

    }, [navigate, dispatch, form, loaderData.getEducationResponse]);

    useEffect(() => {
        dispatch(setSelectedMenuItemKeys(['active-companies']));
        dispatch(setOpenKeys(['companies']));
        dispatch(setPageTitle('Oktatás módosítása'));
    }, [dispatch]);

    return (
        <>
            <Card>
                
                <Form
                    form={form}
                    onFinish={onFinish}
                    layout='vertical'
                >

                    <Form.Item
                        label='Név'
                        name='name'
                        rules={[{required: true, message: 'Név megadása kötelező!'}]}
                    >
                        <Input />
                    </Form.Item>

                    <Divider></Divider>

                    <div className='table-responsive'>
                        <table className='admin-education-table'>
                            <thead>
                                <tr>
                                    {
                                        (modulePartProperties.length && moduleParts.length) ?
                                            (<><td className='table-header'>Megnevezés</td><td className='table-header text-center'>Elérhetőség</td></>) :
                                            ''
                                    }

                                    {modulePartProperties.map((modulePartProperty) => (
                                        <td key={modulePartProperty.id} className='table-header text-center'>
                                            {modulePartProperty.name}
                                        </td>
                                    ))}

                                </tr>
                            </thead>

                            <tbody>
                                {moduleParts.map((modulePart) => (
                                    <tr key={modulePart.id}>
                                        <td>
                                            {
                                                modulePart.icon ?
                                                    (
                                                        <>
                                                            <img style={{ maxWidth: "22px", maxHeight: "22px" }} src={modulePart.icon} alt="icon" />
                                                            &nbsp;
                                                            &nbsp;
                                                        </>
                                                    )
                                                    : ''
                                            }
                                            {modulePart.name}
                                        </td>
                                        <td className='text-center'>
                                            {
                                                isModulePartAvailable(modulePart.id) ?             
                                                    <Form.Item
                                                        name={`modulePartAvailable.${modulePart.id}`}
                                                        valuePropName="checked"
                                                        initialValue={isModulePartAvailableInEducation(modulePart.id)}
                                                    >
                                                        <Checkbox onChange={(e) => modulePartAvailabilityChange(e, modulePart.id)}/>
                                                    </Form.Item> :
                                                    <CloseOutlined style={{ color: 'red', fontSize: '20px' }} />
                                            }
                                        </td>

                                        {createEducationModulePartModulePartPropertyTDs(modulePart.id)}
                                        
                                    </tr>
                                ))}
                            </tbody>

                        </table>
                    </div>

                    <Button
                        type="default"
                        htmlType="button"
                        style={{ marginBottom: '20px' }}
                        onClick={() => setCompareWithLicenses(!compareWithLicenses)}
                    >
                        { compareWithLicenses ? 'Összehasonlítás elrejtése' : 'Oktatás összehasonlítása az egyes licencekkel' }
                    </Button>

                    {
                        compareWithLicenses &&
                        (
                            <>

                                <Form.Item
                                    label='Licenc kiválasztása'
                                    name='licenseId'
                                >
                                    <Select
                                        placeholder="Válasszon licenct!"
                                        options={licenseoptions}
                                        onChange={value => setSelectedLicenseId(value)}
                                    />
                                </Form.Item>

                                <div style={{ marginBottom: '15px' }}>A kiválasztott licenc és az aktuális oktatás közös elemei:</div>

                                <div className='table-responsive'>
                                    <table className='admin-education-table'>
                                        <thead>
                                            <tr>
                                                {
                                                    (modulePartProperties.length && moduleParts.length) ?
                                                        (<><td className='table-header'>Megnevezés</td><td className='table-header text-center'>Elérhetőség</td></>) :
                                                        ''
                                                }

                                                {modulePartProperties.map((modulePartProperty) => (
                                                    <td key={modulePartProperty.id} className='table-header text-center'>
                                                        {modulePartProperty.name}
                                                    </td>
                                                ))}

                                            </tr>
                                        </thead>

                                        <tbody>
                                            {moduleParts.map((modulePart) => (
                                                <tr key={modulePart.id}>
                                                    <td>
                                                        {
                                                            modulePart.icon ?
                                                                (
                                                                    <>
                                                                        <img style={{ maxWidth: "22px", maxHeight: "22px" }} src={modulePart.icon} alt="icon" />
                                                                        &nbsp;
                                                                        &nbsp;
                                                                    </>
                                                                )
                                                                : ''
                                                        }
                                                        {modulePart.name}
                                                    </td>
                                                    <td className='text-center'>
                                                        {
                                                            isModulePartAvailableInSelectedLicenseAndInEducation(modulePart.id) ?             
                                                                <CheckOutlined style={{ color: 'green', fontSize: '20px' }} /> :
                                                                <CloseOutlined style={{ color: 'red', fontSize: '20px' }} />
                                                        }
                                                    </td>

                                                    {createEducationModulePartModulePartPropertyAndLicenseModulePartModulePartPropertyTDs(modulePart.id)}
                                                    
                                                </tr>
                                            ))}
                                        </tbody>

                                    </table>
                                </div>

                            </>
                        )
                    }

                    <Form.Item>

                        <Link to={loaderData.getEducationResponse?.data?.education?.company ? `/cms/admin/companies/edit/${ loaderData.getEducationResponse?.data?.education?.company.id }` : '/cms/admin/active-companies/list'}>
                            <Button type="default" htmlType="button">
                                Vissza
                            </Button>
                        </Link>
                        <Button type='primary' htmlType='submit' style={{float: 'right'}}>
                            Mentés
                        </Button>
        
                    </Form.Item>
                            
                </Form>

            </Card>

        </>
    )
}